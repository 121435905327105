import { Component, OnInit } from '@angular/core';
import { Conta } from 'src/app/models';
import { TipoFormaPagamento } from 'src/app/models/finance/tipo-forma-pagamento';
import { InvoicePaid } from 'src/app/models/invoice-paid';
import { BilletService } from 'src/app/services/billet.service';
import { FinancesService } from 'src/app/services/finances.service';
import { InvoicePaidService } from 'src/app/services/invoice-paid.service';
import { MarinaOrderService } from 'src/app/services/marina-order.service';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { ToastService } from 'src/app/services/toast.service';
import { MessageUtil } from 'src/app/utils/message.util';
import Swal from 'sweetalert2';
import moment from 'moment';
import { StorageUtil } from 'src/app/utils/storage.util';
import { InvoiceInterestDetailsComponent } from '../invoice-interest-details/invoice-interest-details.component';
import { ReceivingAccountsDialogComponent } from 'src/app/pages/billing/receiving-accounts/receiving-accounts-dialog/receiving-accounts-dialog.component';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { InvoiceService } from 'src/app/services/invoice.service';
import { RoleService } from 'src/app/services/role.service';
import { ChooseBilletComponent } from './choose-billet/choose-billet.component';
import { ChangeDateDialogComponent } from '../invoice/change-date-dialog/change-date-dialog.component';

@Component({
  selector: 'app-invoice-payment',
  templateUrl: './invoice-payment.component.html',
  styleUrls: ['./invoice-payment.component.scss']
})
export class InvoicePaymentComponent implements OnInit {

  invoice: any;
  total: number;
  whitheldIssTax: number;
  accountList: Conta[] = [];
  accountListOptions: any[];
  paymentMethods: TipoFormaPagamento[];
  paymentMethodsOptions: any[];
  private ordersPrint: any[] = new Array();
  credentialShow = false;
  typeFieldPay = 'paidValue';
  discount = '00';
  paidValue = '00';
  slingConfig: any;
  bilingPortifolioNumber: number;
  taxaMulta: number;
  taxaMora: number;
  toleranceDays: number;
  interestSuggested: number;
  dueDateDiscount: number;
  onlyReceivingAccounts = true;
  accountListPaymentMethods: any[];
  aditionalCompanyList: any;
  selectedCompany: any;
  billetToCancelled: any;
  maxAllowedDate: Date;

  pt = {
    firstDayOfWeek: 0,
    dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
      'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar'
  };

  constructor(
    private invoicePaidService: InvoicePaidService,
    private invoiceService: InvoiceService,
    public dialogRef: DynamicDialogRef,
    private messageUtil: MessageUtil,
    private dialog: DialogService,
    private financesService: FinancesService,
    private marinaOrderService: MarinaOrderService,
    private toasty: ToastService,
    private slingConfigService: SlingConfigService,
    private billetService: BilletService,
    private config: DynamicDialogConfig,
    private spinner: NgxSpinnerService,
    private roleService: RoleService
  ) {
    this.interestSuggested = 0;
    this.dueDateDiscount = 0;
    const currentDate = new Date();
    this.maxAllowedDate = currentDate;
  }

  async ngOnInit(): Promise<void> {
    this.invoice = this.config.data.invoice;
    await this.getValorIssRetidoFromInvoice(this.invoice.id);

    this.credentialShow = this.config.data.credentialShow;
    this.aditionalCompanyList = await this.financesService.findCompaniesWithDatabase();
    await this.valitadeAditionalCompanies(this.aditionalCompanyList);
    this.findAllAccountPaymentMethods();
    this.createObject();
    this.getPaymentMethods();
    await this.loadSlingConfig();
    if (this.hasBankingBillet()) {
      await this.loadBilingPortifolio();
    } else {
      await this.loadAllBillingPortifolio();
    }

    if (this.getActiveBillets(this.invoice).length > 0) {
      this.dialog.open(ChooseBilletComponent, {
        data: {
          billets: this.getActiveBillets(this.invoice),
          invoice: this.invoice,
        },
        header: 'Boletos da fatura ' + this.invoice.invoiceNumber
      }).onClose.subscribe((data) => {
        if (data === undefined) {
          this.dialogRef.close();
        }
        if (data) {
          this.billetToCancelled = data.id;
        }
      });


    }
  }

  async valitadeAditionalCompanies(aditionalCompanyList): Promise<void> {
    return new Promise<void>(
      async (resolve) => {
        if (this.invoice.marinaCompany && aditionalCompanyList.length > 0) {
          this.selectedCompany = aditionalCompanyList.find((company) => company.id === this.invoice.marinaCompany.id);
        }
        resolve();
      }
    );
  }

  async loadSlingConfig(): Promise<void> {
    return await new Promise<void>(
      async (resolve) => {
        await this.slingConfigService.getSlingConfig().subscribe(
          async (data) => {
            this.slingConfig = data[0];
            resolve();
          }
        );
      }
    );
  }

  async getValorIssRetidoFromInvoice(id: number): Promise<void> {
    return await new Promise<void>(
      async (resolve) => {
        await this.invoiceService.getValorIssRetidoFromInvoice(id).subscribe(
          async (data) => {
            this.whitheldIssTax = data.whitheldIssTax;
            resolve();
          }
        );
      }
    );
  }

  getTotalValue(): number {
    let issTax = 0;
    if (this.whitheldIssTax && this.invoice.totalIssFromPaid == 0) {
      issTax = this.whitheldIssTax;
    }
    return this.invoice.pendingValue + this.invoice.interest - this.invoice.discount - issTax;
  }

  createObject(): void {
    this.invoice.discount = 0;
    this.invoice.interest = 0;
    this.invoice.paymentDate = undefined;
    this.invoice.processingDate = new Date();
    this.invoice.account = undefined;
    this.invoice.payment = undefined;

    if (this.invoice.paidValue === undefined) {
      this.invoice.paidValue = 0;
    }
  }

  validations(credentials: Boolean): boolean {
    if (Number(this.invoice.discount.toFixed(2)) < 0 ||
      Number(this.invoice.interest.toFixed(2)) < 0) {
      this.messageUtil.generateMessage(MessageUtil.WARNING, 'Alerta', 'Valor não podem ser negativos');
      return false;
    }
    if (Number(this.invoice.paidValue.toFixed(2)) >
      Number((this.invoice.pendingValue + this.invoice.interest).toFixed(2))) {
      this.messageUtil.generateMessage(MessageUtil.WARNING, 'Alerta', 'Valor maior que o total a ser pago');
      return false;
    }
    if (!Number(this.invoice.paidValue.toFixed(2)) ||
      Number(this.invoice.paidValue.toFixed(2)) === 0 ||
      Number(this.invoice.paidValue.toFixed(2)) < 0) {
      this.messageUtil.generateMessage(MessageUtil.WARNING, 'Alerta', 'Favor informar valor da fatura');
      return false;
    }

    if (!this.invoice.paymentMethod) {
      this.messageUtil.generateMessage(MessageUtil.WARNING, 'Alerta', 'Favor informar forma de pagamento');
      return false;
    }
    if (this.accountList.length !== 0 && !this.invoice.account) {
      this.messageUtil.generateMessage(MessageUtil.WARNING, 'Alerta', 'Favor informar conta');
      return false;
    }

    if (this.invoice.discount !== 0 && credentials == false) {
      this.messageUtil.generateMessage(MessageUtil.ERROR, "Erro", "Você não possui permissão para executar a ação de desconto");
      return false;
    }

    return true;
  }

  async checkAndInvoicePay() {
    const credentialsAdm = await this.isCurrentUserAdministrator();
    if (this.validations(credentialsAdm)) {
      let employeeUser = StorageUtil.getUser();
      const invoicePaid: InvoicePaid = new InvoicePaid();
      invoicePaid.datepaid = this.invoice.paymentDate;
      invoicePaid.dateProcessing = this.invoice.processingDate;
      invoicePaid.discount = this.invoice.discount;
      invoicePaid.interest = this.invoice.interest;
      invoicePaid.totalPaid = (this.invoice.paidValue);
      invoicePaid.value = this.invoice.value;
      invoicePaid.username = this.invoice.customer.name;
      invoicePaid.employeeUser = employeeUser.firstName + " " + employeeUser.lastName;
      invoicePaid.invoiceId = this.invoice.id;
      invoicePaid.accountId = this.invoice.account.idConta;
      invoicePaid.accountName = this.invoice.account.nome;
      invoicePaid.paymentMethodId = this.invoice.paymentMethod.idTipoFormaPagamento;
      invoicePaid.paymentMethodName = this.invoice.paymentMethod.descricao;

      invoicePaid.billetToCanceled = this.billetToCancelled;
      let mensagem = 'Confirmar operação?';
      if (this.billetToCancelled) {
        mensagem = "Confirma a operação? <br> <span style='color: red; font-style: normal; font-size: large; '> Ao informar o pagamento dessa parcela, o boleto será cancelado no sistema, essa operação é irreversível. </span>";
      }
      let confirmText = '';
      let changeDate = false;
      if ((this.invoice.paidValue) < this.getTotalValue()) {
        // confirmText = 'Valor PAGO é menor que o TOTAL a pagar! A data de vencimento deverá ser alterada e será criado um novo Pix.'
        // changeDate = true;
      } else
        if ((this.invoice.paidValue) > this.getTotalValue()) {
          confirmText = 'Valor PAGO é maior que o TOTAL a pagar!'
        }
      Swal.fire({
        icon: 'warning',
        title: mensagem,
        showCancelButton: true,
        text: confirmText,
        confirmButtonText: 'Confirmar',
        showLoaderOnConfirm: true,
        reverseButtons: true,
        cancelButtonColor: '#d33',
        allowOutsideClick: false,
        preConfirm: () => {
          this.invoicePay(invoicePaid, changeDate);
        }
      });
    }
  }

  invoicePay(invoicePaid: InvoicePaid, changeDate: boolean): void {
    if (changeDate) {
      this.updateInvoiceDates(invoicePaid);
      return;
    }
    this.spinner.show();
    invoicePaid.dropBillet = true;
    this.invoicePaidService.payInvoice(invoicePaid).subscribe(() => {
      this.toasty.success('Fatura paga com sucesso.');
      this.getMarinaOrdersOfInvoice();
      this.spinner.hide();
      this.dialogRef.close();
    }, (error) => {
      const exception = error.error.data.exception;
      this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
    });
  }


  updateInvoiceDates(invoicePaid: InvoicePaid): void {
    const invoice = this.invoice;
    const today = new Date(invoice.dueDate);
    this.dialog.open(ChangeDateDialogComponent, {
      header: 'Atualizar data',
      height: '650px',
      width: '350px',
      data: {
        invoice,
        today: today,
        canChangeDueDate: true,
        changeToPayment: true
      }
    }).onClose.subscribe((duedate: Date) => {
      if (duedate) {
        if (duedate instanceof Date)
          invoice.dueDate = duedate;
        invoicePaid.newDueDate = duedate;
        this.invoicePay(invoicePaid, false);
      }
    });
  }

  getAccounts(): void {
    this.financesService.findContasAtivas().subscribe(
      (data) => {
        this.accountList = (data);
        this.loadAcountList(this.accountList);
      });
  }

  async loadAcountList(accountList: Conta[]): Promise<void> {
    this.invoice.account = undefined;
    this.accountListOptions = [];
    if (this.invoice.paymentMethod && this.onlyReceivingAccounts) {
      this.accountListOptions = await this.loadReceivingAcounts();
      this.accountListOptions.sort((a, b) => (a.label).localeCompare(b.label));
    } else {
      this.accountListOptions = accountList.map((a) => ({ label: a.nome, value: a }));
      this.accountListOptions.sort((a, b) => (a.label).localeCompare(b.label));
    }
    if (this.accountListOptions.length === 1) {
      this.invoice.account = this.accountListOptions[0].value;
    }
  }

  async resetOptions(event?: boolean): Promise<void> {
    this.invoice.paymentMethod = undefined;
    this.accountListOptions = undefined;
    this.getPaymentMethods(event);
  }

  getPaymentMethods(firstTime?: boolean): void {
    this.financesService.findTipoFormasPagamento(this.selectedCompany ? this.selectedCompany : '').subscribe(
      (data) => {
        this.paymentMethods = (data.filter((method) => method.contas.length !== 0 && method.tipo.id !== 1));
        this.paymentMethodsOptions = this.paymentMethods.map((p) => ({ label: p.descricao, value: p }));
        this.paymentMethodsOptions.sort((a, b) => (a.label).localeCompare(b.label));
        if (this.accountListPaymentMethods && this.onlyReceivingAccounts) {
          this.paymentMethodsOptions = this.filterPaymentmethods(this.paymentMethodsOptions);
        }
        if (!this.accountListPaymentMethods) {
          this.onlyReceivingAccounts = false;
          if (firstTime) {
            this.toasty.warning('Não existem contas somente recebimento configuradas');
          }
        }
      },
      (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      }, () => {
        if (this.invoice.paymentMethodId) {
          this.invoice.paymentMethod = this.paymentMethods.find((pm) => pm.idTipoFormaPagamento === this.invoice.paymentMethodId);
          if (this.invoice.paymentMethod) {
            this.accountList = this.invoice.paymentMethod.contas;
            this.loadAcountList(this.accountList);
            this.invoice.account = this.accountList.find((account) => account.idConta === this.invoice.accountId);
            if (!this.invoice.account) {
              this.invoice.account = this.accountList.find((account) => account.idConta === this.slingConfig.accountId);
            }
          }
        }
      });
  }

  getMarinaOrdersOfInvoice(): void {
    this.marinaOrderService.getByIdInvoice(this.invoice.id).subscribe(
      (data) => {
        this.ordersPrint = data;
        this.ordersPrint.forEach((order) => {
          if (order.customers.length > 1) {
            order.customerQuota = order.customers.find((customer) =>
              customer.federalId === this.invoice.customer.federalId).quota;
          }
        });
      },
      (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      }
    );
  }

  changeInterestToInterestSuggested(): void {
    this.cleanPaidValue();
    this.invoice.interest = this.interestSuggested;
  }
  changeDiscountToDiscountSuggested() {
    this.cleanPaidValue();
    this.invoice.discount = this.dueDateDiscount;
  }
  getInterestAndDiscountSuggested(): void {
    this.interestSuggested = this.calcInterest();
    this.dueDateDiscount = this.calcDueDateDiscount();
    this.resetDiscount(this.dueDateDiscount);
  }
  private calcDueDateDiscount(): number {
    if (this.isDueDateDiscount() && this.invoice.dueDateDiscount > 0) {
      return this.invoice.dueDateDiscount;
    }
    return 0;
  }

  private isDueDateDiscount(): boolean {
    const dueDate = moment(this.invoice.dueDate);
    const paymentDate = moment(this.invoice.paymentDate);

    if (paymentDate <= dueDate) {
      return true;
    }

    return false;
  }
  private resetDiscount(dueDateDiscount: number) {
    if (dueDateDiscount === 0) {
      this.invoice.discount = 0;
    }
  }

  private calcInterest(): number {
    const daysPastDue = this.daysPastDueDate();
    let allInterest = 0;

    if (daysPastDue <= 0) {
      return allInterest;
    }

    allInterest += this.calcMulta();

    if (daysPastDue > this.toleranceDays) {
      const daysMora = daysPastDue - this.toleranceDays;
      allInterest += this.calcMora(daysMora);
    }

    return allInterest;
  }

  private calcMulta(): number {
    return parseFloat(((parseFloat(this.invoice.pendingValue) * this.taxaMulta) / 100.0).toFixed(2));
  }

  private calcMora(daysMora: number): number {
    const taxaDay = parseFloat((this.taxaMora / 30).toFixed(4));
    const valueToDay = Math.ceil((parseFloat(this.invoice.pendingValue) * taxaDay / 100) * 100) / 100;
   return parseFloat((valueToDay * daysMora).toFixed(2));
  }

  private daysPastDueDate(): number {
    const dueDate = moment(this.invoice.dueDate);
    const paymentDate = moment(this.invoice.paymentDate);
    const days = moment.duration(paymentDate.diff(dueDate)).asDays();

    if (days < 0) {
      return 0;
    }

    return days;
  }

  setPaidValue(): void {
    this.invoice.paidValue = this.getTotalValue();
  }

  hasBankingBillet(): boolean {
    return this.getActiveBillets(this.invoice).length > 0;
  }

  getActiveBillets(invoice): any[] {
    if (invoice.invoiceBankingBillets) {
      return invoice.invoiceBankingBillets.filter((b) => b.active && !b.paid);
    } else if (invoice.bankingBilletList) {
      return invoice.bankingBilletList.filter((b) => b.active);
    } else {
      return [];
    }
  }

  getIdBillet(): number {
    return this.getActiveBillets(this.invoice)[0].id;
  }

  async findAllAccountPaymentMethods(): Promise<void> {
    let id: number;
    if (this.selectedCompany) {
      id = this.selectedCompany.id;
    } else {
      id = 0;
    }
    this.financesService.findAllAccountPaymentMethodsByCompanyId(id).subscribe(
      (data) => {
        this.accountListPaymentMethods = data.list;
      }
    );
  }

  async loadReceivingAcounts(): Promise<any[]> {
    return new Promise<any[]>(
      async (resolve) => {
        let listOptions: any = [];
        const accountListOptions: any = [];
        listOptions = (this.accountListPaymentMethods.filter((method) =>
          method.formaPagamentoId === this.invoice.paymentMethod.idTipoFormaPagamento));

        if (listOptions[0]) {
          listOptions[0].contaId.forEach(element => {
            const accountExist = this.invoice.paymentMethod.contas.find((d) => d.idConta === element);
            if (accountExist) {
              accountListOptions.push(accountExist);
            }
          });
        }
        resolve(accountListOptions.map((a) => ({ label: a.nome, value: a })));
      }
    );
  }

  loadAllBillingPortifolio(): Promise<any[]> {
    return new Promise<any[]>(async (resolve) => {
      await this.financesService.getAllBilingPortifolio(this.invoice.marinaCompany).subscribe(
        (data) => {
          let companyFederalId;
          if (this.invoice.marinaCompany) {
            companyFederalId = this.invoice.marinaCompany.companyFederalId;
          } else if (this.invoice.marina && this.invoice.marina.companyFederalId) {
            companyFederalId = this.invoice.marina.companyFederalId;
          } else {
            companyFederalId = StorageUtil
              .getMarina().companyFederalId
              .replace('.', '')
              .replace('.', '')
              .replace('/', '')
              .replace('-', '');
          }
          let conta = data.find((d) => d.conta.idConta === this.invoice.accountId);
          if (!conta) {
            conta = data.find((d) => d.conta.idConta === this.slingConfig.accountId);
          }
          this.taxaMulta = conta.taxaMulta;
          this.taxaMora = conta.taxaMora;
          this.toleranceDays = conta.diasMulta;
          resolve(data);
        },
        (error) => {
          console.log(error);
        }
      );
    });
  }

  loadBilingPortifolio(): Promise<any> {
    return new Promise<any>(async (resolve) => {
      const bankingBilletId = this.getIdBillet();
      await this.billetService.getBilingPortifolio(bankingBilletId).subscribe({
        next: (data) => {
          this.taxaMulta = data.taxaMulta;
          this.taxaMora = data.taxaMora;
          this.toleranceDays = data.diasMulta;
          resolve(data);
        },
        error: (error) => {
          console.log(error);
        }
      });
    });
  }

  openConfigDialog(): void {
    this.dialog.open(ReceivingAccountsDialogComponent, {
      width: '800px',
      height: '50%',
      dismissableMask: true,
      header: 'Configuração de Contas de Recebimento',
      data: {
        isModal: true,
        selectedCompany: this.selectedCompany
      }
    }).onClose.subscribe(() => {
      this.findAllAccountPaymentMethods();
      this.resetOptions();
    });

  }

  openInvoiceInterestDialog(): void {
    this.dialog.open(InvoiceInterestDetailsComponent, {
      width: '60%',
      data: {
        taxaMora: this.taxaMora,
        taxaMulta: this.taxaMulta,
        toleranceDays: this.toleranceDays
      }
    });
  }

  filterPaymentmethods(paymentMethodsOptions): any[] {
    const listOptions: any = new Array();
    const ids: any[] = new Array();
    if (this.accountListPaymentMethods) {

      this.accountListPaymentMethods.forEach(element => {
        ids.push(element.formaPagamentoId);
      });
      ids.forEach(element => {
        const paymentExist = paymentMethodsOptions.find((d) => d.value.idTipoFormaPagamento === element);
        if (paymentExist) {
          listOptions.push(paymentExist);
        }
      });
    } else {
      this.onlyReceivingAccounts = false;
    }
    return listOptions;
  }

  validValue(invoice): boolean {
    return invoice.paidValue >= invoice.interest;
  }

  cleanPaidValue() {
    this.invoice.paidValue = 0;
  }

  async isCurrentUserAdministrator(): Promise<Boolean> {
    const currentUserID = StorageUtil.getUserId();
    return new Promise<Boolean>(async (resolve) => {
      this.roleService.currentUserIsAdministrator(currentUserID).subscribe({
        next: (administratorRole: Boolean) => resolve(administratorRole),
        error: (e) => console.log(e),
      });
    });
  }
}
