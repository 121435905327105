<div class="dialog-form">
  <form (submit)="validateSave()" [formGroup]="productForm">
    <div
      style="
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        margin-bottom: 20px;
      "
    >
      <button
        class="easymarine primary"
        *ngIf="product?.id"
        type="button"
        (click)="copy()"
      >
        {{ "CREATE-COPY" | translate }}
      </button>
    </div>
    <p-fieldset>
      <p-header class="article-title">
        <div class="article-title">{{ "GENERAL-DATA" | translate }}</div>
      </p-header>
      <div class="form-group">
        <div
          class="form-control"
          *ngIf="
            productForm?.get('productCategory') &&
            productForm?.get('productCategory').value?.id !== 3
          "
        >
          <label for="barCode">{{ "BAR-CODE" | translate }}</label>
          <input
            pInputText
            name="barCode"
            formControlName="barCode"
            (blur)="loadImageProduct()"
          />
        </div>
        <div class="form-control">
          <label for="name">{{ "NAME" | translate }}*</label>
          <input pInputText name="name" formControlName="name" />
        </div>
        <div class="form-control">
          <label for="productType">{{ "TYPE" | translate }}*</label>
          <p-dropdown
            placeholder="Selecione..."
            [options]="productTypeOptions"
            formControlName="productType"
            appendTo="body"
            (onChange)="changeCategory()"
            [readonly]="isEditionPeriodicalService"
          >
          </p-dropdown>
        </div>
      </div>
      <br />
      <div class="form-group">
        <div class="form-control">
          <label for="productCategory">{{ "CATEGORY" | translate }}*</label>
          <p-dropdown
            placeholder="Selecione..."
            [options]="productCategories"
            formControlName="productCategory"
            appendTo="body"
            (onChange)="changeCategory()"
            [styleClass]="getClass()"
            [readonly]="
              productForm?.get('productType') &&
              (productForm?.get('productType').value === 'PeriodicalService' ||
                productForm?.get('productType').value ===
                  'PeriodicalServiceCustomer')
            "
          >
          </p-dropdown>
        </div>
        <div
          class="form-control"
          *ngIf="
            productForm?.get('productCategory') &&
            productForm?.get('productCategory').value?.id === 3
          "
        >
          <label for="fuel">{{ "FUEL" | translate }}*</label>
          <p-dropdown
            placeholder="Selecione..."
            [options]="fuels"
            formControlName="fuel"
            appendTo="body"
          >
          </p-dropdown>
        </div>
        <div class="form-control">
          <label for="measurement">{{ "MEASUREMENT" | translate }}*</label>
          <p-dropdown
            placeholder="Selecione..."
            [options]="measurementOptions"
            formControlName="measurement"
            [styleClass]="getClass()"
            [readonly]="
              productForm?.get('productType') &&
              (productForm?.get('productType').value === 'PeriodicalService' ||
                productForm?.get('productType').value ===
                  'PeriodicalServiceCustomer')
            "
            appendTo="body"
          ></p-dropdown>
        </div>
        <div
          class="form-control"
          *ngIf="productForm?.get('productType').value === 'Product'"
        >
          <label for="orderControl">{{ "Controle" | translate }}*</label>
          <p-dropdown
            placeholder="Selecione..."
            [options]="orderControlOptions"
            formControlName="orderControl"
            appendTo="body"
          ></p-dropdown>
        </div>
      </div>
      <br />
      <div class="form-group">
        <div
          class="form-control"
          *ngIf="enableFinancialMultipleCompaniesByProduct()"
        >
          <label for="billingCompanyId">{{
            "Empresa Faturamento" | translate
          }}</label>
          <p-dropdown
            [options]="getBillingCompanyOption()"
            name="billingCompanyOptionId"
            appendTo="body"
            formControlName="billingCompany"
          >
          </p-dropdown>
        </div>
        <div class="form-control">
          <label for="chartAccountId"
            >{{ "CHART-ACCOUNTS" | translate }}*</label
          >
          <p-dropdown
            placeholder="Selecione..."
            [options]="chartAccountsFlatList"
            filter="true"
            formControlName="chartAccountId"
            appendTo="body"
          >
          </p-dropdown>
        </div>

        <div class="form-control" *ngIf="slingConfig?.costCenterInStock">
          <label for="costCenterId">{{ "COST-CENTER" | translate }}</label>
          <p-dropdown
            placeholder="Selecione..."
            [options]="costCenters"
            filter="true"
            formControlName="costCenterId"
            appendTo="body"
          >
          </p-dropdown>
        </div>

        <div
          class="form-control"
          *ngIf="productForm?.get('productType').value === 'Product'"
        >
          <label
            for="ncm"
            *ngIf="
              productForm?.get('productType').value === 'Product' &&
              !productForm.get('issueNotaFiscal').value
            "
            >{{ "Nomenclatura Comum do Mercosul" | translate }}
          </label>
          <label
            for="ncm"
            *ngIf="
              productForm?.get('productType').value === 'Product' &&
              productForm.get('issueNotaFiscal').value
            "
          >
            {{ "Nomenclatura Comum do Mercosul" | translate }} *</label
          >
          <p-inputMask
            mask="99999999"
            name="ncmCod"
            formControlName="ncmCod"
            [useGrouping]="false"
            (onBlur)="findNcmDescription()"
          ></p-inputMask>
          {{ productForm.get("ncmDescription")?.value }}
        </div>
        <div class="form-control"      *ngIf="
        productForm?.get('productCategory') &&
        productForm?.get('productCategory').value?.id === 3
      ">
          <label for="codiAnp">{{ "Cod. ANP" | translate }}</label>
          <input pInputText name="codAnp" formControlName="codAnp" />
        </div>
        <div class="form-control"      *ngIf="
            productForm?.get('productCategory') &&
            productForm?.get('productCategory').value?.id === 3
          ">
          <label for="combustivelPercentualBiodiesel">{{ "Percentual de Mistura" | translate }}</label>
          <p-inputNumber
              name="combustivelPercentualBiodiesel"
              formControlName="combustivelPercentualBiodiesel"
              mode="decimal"
              [minFractionDigits]="3"
              [maxFractionDigits]="3"
              suffix="%"
              min="0.00"
            ></p-inputNumber>

        </div>
      </div>
      <br />
      <div class="form-group">
        <div
          class="form-control switch"
          *ngIf="
            productForm?.get('showApp') && productForm?.get('showApp').enabled
          "
        >
          <label for="showApp">{{ "Mostrar no App" | translate }}</label>
          <p-inputSwitch formControlName="showApp" name="showApp">
          </p-inputSwitch>
        </div>
        <div class="form-control switch" *ngIf="hasInvoiceReceipt()">
          <label for="showApp">Emite recibo</label>
          <p-inputSwitch formControlName="issueReceipt" name="issueReceipt">
          </p-inputSwitch>
        </div>
        <div class="form-control switch">
          <label for="showApp">Emite nota fiscal</label>
          <p-inputSwitch
            formControlName="issueNotaFiscal"
            name="issueNotaFiscal"
          >
          </p-inputSwitch>
        </div>
        <!--        <div class="form-control switch">-->
        <!--          <label for="issueNotaFiscalConsumidor">Emite nota fiscal de Consumidor</label>-->
        <!--          <p-inputSwitch-->
        <!--            formControlName="issueNotaFiscalConsumidor"-->
        <!--            name="issueNotaFiscalConsumidor"-->
        <!--          >-->
        <!--          </p-inputSwitch>-->
        <!--        </div>-->
        <div
          class="form-control switch"
          *ngIf="
            product.productType === 'Service' ||
            product.productType === 'PeriodicalService' ||
            product.productType === 'PeriodicalServiceCustomer'
          "
        >
          <label for="showApp">Incluir Referência</label>
          <p-inputSwitch
            formControlName="enableReferenceDate"
            name="enableReferenceDate"
          ></p-inputSwitch>
        </div>
      </div>
    </p-fieldset>
    <br />
    <div class="form-group">
      <p-fieldset
        *ngIf="
          productForm?.get('productType').value === 'Product' &&
          productForm.get('issueNotaFiscal').value
        "
      >
        <p-header class="article-title">
          <div class="article-title">{{ "Tributação" | translate }}</div>
        </p-header>
        <div class="form-group">
          <div class="form-control">
            <label for="icms">{{ "Situação Tributária" | translate }}*</label>
            <p-dropdown
              placeholder="Selecione..."
              [options]="situationTributariaOptions"
              formControlName="icmsSituacaoTributaria"
              appendTo="body"
            ></p-dropdown>
          </div>

          <div class="form-control">
            <label for="cfop"
              >{{ "Código Operação fiscal" | translate }}*</label
            >
            <p-dropdown
              placeholder="Selecione..."
              [options]="cfopOptions"
              formControlName="cfop"
              appendTo="body"
            ></p-dropdown>
          </div>
        </div>
        <br />
        <div class="form-group">
          <div class="form-control">
            <label for="icmsOrigem">{{ "Icms Origem" | translate }}*</label>
            <p-dropdown
              placeholder="Selecione..."
              [options]="icmsOrigemOptions"
              formControlName="icmsOrigem"
              appendTo="body"
            ></p-dropdown>
          </div>

          <div class="form-control">
            <label for="pisOrigem">{{ "Pis Origem" | translate }}**</label>
            <p-dropdown
              placeholder="Selecione..."
              [options]="pisOriginOptions"
              formControlName="pisSituacaoTributaria"
              appendTo="body"
            ></p-dropdown>
          </div>

          <div class="form-control">
            <label for="cofinsSituacaoTributaria"
              >{{ "Cofins Origem" | translate }}**</label
            >
            <p-dropdown
              placeholder="Selecione..."
              [options]="cofinsOrigenOptions"
              formControlName="cofinsSituacaoTributaria"
              appendTo="body"
            ></p-dropdown>
          </div>

          <div class="form-control">
            <label for="ipiSituacaoTributaria"
              >{{ "IPI Origem " | translate }}**</label
            >
            <p-dropdown
              placeholder="Selecione..."
              [options]="ipiOriginOptions"
              formControlName="ipiSituacaoTributaria"
              appendTo="body"
            ></p-dropdown>
          </div>
        </div>
        <div class="form-group">
          <div class="form-control" style="min-width: 0px">
            <div class="form-control">
              <label for="cest">{{ "Cest" | translate }}*</label>
              <input
                pInputText
                name="cest"
                formControlName="cest"
                pattern="[0-9]*"
              />
            </div>
          </div>
          <div class="form-control" style="min-width: 0px"></div>
          <div class="form-control" style="min-width: 0px"></div>
          <div class="form-control" style="min-width: 0px">
            <label for="ipiEnquadramento"
              >{{ "IPI Enquadramento" | translate }}**</label
            >
            <p-dropdown
              placeholder="Selecione..."
              [options]="ipiCodigoEnquadramentoOptions"
              formControlName="ipiCodigoEnquadramento"
              appendTo="body"
            ></p-dropdown>
          </div>
        </div>

        <div class="form-group">
          <div class="form-control" style="min-width: 0px">
            <label for="icms">{{ "Icms" | translate }}*</label>
            <p-inputNumber
              name="icms"
              formControlName="icms"
              mode="decimal"
              [minFractionDigits]="2"
              [maxFractionDigits]="2"
              suffix="%"
              min="0.00"
              (onBlur)="calcSuggestPrice()"
            ></p-inputNumber>
          </div>
          <div class="form-control" style="min-width: 0px">
            <label for="pis">{{ "Pis" | translate }}</label>
            <p-inputNumber
              name="pis"
              formControlName="pis"
              mode="decimal"
              [minFractionDigits]="2"
              [maxFractionDigits]="2"
              suffix="%"
              min="0.00"
              (onBlur)="calcSuggestPrice()"
              [disabled]="true"
            ></p-inputNumber>
          </div>

          <div class="form-control" style="min-width: 0px">
            <label for="cofins">{{ "Cofins" | translate }}</label>
            <p-inputNumber
              name="cofins"
              formControlName="cofins"
              mode="decimal"
              [minFractionDigits]="2"
              [maxFractionDigits]="2"
              (onBlur)="calcSuggestPrice()"
              suffix="%"
              min="0.00"
              [disabled]="true"
            ></p-inputNumber>
          </div>

          <div class="form-control" style="min-width: 0px">
            <label for="ipi">{{ "IPI" | translate }}</label>
            <p-inputNumber
              name="ipi"
              formControlName="ipi"
              mode="decimal"
              [minFractionDigits]="2"
              [maxFractionDigits]="2"
              (onBlur)="calcSuggestPrice()"
              suffix="%"
              min="0.00"
              [disabled]="true"
            ></p-inputNumber>
          </div>
        </div>
        <div class="w-100" style="right: 1">
          <br />
          ** Usado para Notas do tipo Normal
        </div>
      </p-fieldset>
    </div>
    <br />
    <div class="form-group">
      <p-fieldset
        *ngIf="
          productForm?.get('productType') &&
          productForm?.get('productType').value === 'Product'
        "
      >
        <p-header class="article-title">
          <div class="article-title">{{ "Precificação" | translate }}</div>
        </p-header>

        <div class="form-group">
          <div
            class="form-control"
            style="max-width: none; flex-direction: inherit"
          >
            <div
              class="form-control"
              style="min-width: unset; max-width: 160px"
              *ngIf="
                productForm?.get('discount') &&
                productForm?.get('discount').enabled
              "
            >
              <label for="discount">{{
                "Conceder Descontos" | translate
              }}</label>
              <p-inputSwitch formControlName="discount" name="discount">
              </p-inputSwitch>
            </div>
            <div
              class="form-control"
              *ngIf="productForm.get('issueNotaFiscal').value === false"
            >
              <ng-container
                formGroupName="price"
                *ngIf="productForm.get('issueNotaFiscal').value === false"
              >
                <label for="priceFree">{{ "Preço livre" | translate }}</label>

                <p-inputSwitch
                  formControlName="priceFree"
                  name="priceFree"
                  (onChange)="priceFreeChange()"
                >
                </p-inputSwitch>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="form-group" style="margin-top: 12px">
          <div
            class="form-control"
            style="min-width: 0px; max-width: none"
            *ngIf="!productForm.get('price.priceFree').value"
          >
            <label for="priceCust">{{ "Preço de custo" | translate }}*</label>
            <p-inputNumber
              name="priceCust"
              formControlName="priceCust"
              mode="decimal"
              [minFractionDigits]="2"
              [maxFractionDigits]="2"
              (onBlur)="calcSuggestPrice()"
            ></p-inputNumber>
          </div>
          <div
            class="form-control"
            style="min-width: 0px; max-width: none"
            *ngIf="!productForm.get('price.priceFree').value"
          >
            <span>Último Preço Custo: </span>
            <span style="margin-top: 12px">{{
              product.lastCustPrice | currency : "BRL"
            }}</span>
          </div>
          <div
            class="form-control"
            style="min-width: 0px; max-width: none"
            *ngIf="!productForm.get('price.priceFree').value"
          >
            <span>Preço Custo Médio: </span>
            <span style="margin-top: 12px">{{
              product.averageCustPrice | currency : "BRL"
            }}</span>
          </div>

          <div
            class="form-control"
            *ngIf="!productForm.get('price.priceFree').value"
          >
            <label for="markup">{{ "Margem desejada" | translate }}*</label>
            <p-inputNumber
              name="markup"
              formControlName="markup"
              mode="decimal"
              suffix="%"
              min="0.00"
              [minFractionDigits]="2"
              [maxFractionDigits]="2"
              (onBlur)="calcSuggestPrice()"
            ></p-inputNumber>
          </div>
        </div>
      </p-fieldset>
    </div>
    <br />
    <div class="form-group">
      <p-fieldset
        *ngIf="
          productForm?.get('productType') &&
          productForm?.get('productType').value === 'Product'
        "
      >
        <p-header class="article-title">
          <div class="article-title">
            {{ "Precificação Tributada" | translate }}
          </div>
        </p-header>
        <ng-container formGroupName="price">
          <div class="form-group" style="margin-top: 12px">
            <div class="form-control" style="min-width: 0px; max-width: none">
              <span>Margem: </span>
              <span style="margin-top: 12px">{{
                vlLucro | currency : "BRL"
              }}</span>
            </div>

            <div class="form-control" style="min-width: 0px; max-width: none">
              <span>Custo + Margem: </span>
              <span style="margin-top: 12px">{{
                product.priceCust + vlLucro | currency : "BRL"
              }}</span>
            </div>

            <div class="form-control" style="min-width: 0px; max-width: none">
              <span>ICMS: </span>
              <span style="margin-top: 12px">{{
                vlIcms | currency : "BRL"
              }}</span>
            </div>

            <div class="form-control" style="min-width: 0px; max-width: none">
              <span>PIS: </span>
              <span style="margin-top: 12px">{{
                vlPis | currency : "BRL"
              }}</span>
            </div>

            <div class="form-control" style="min-width: 0px; max-width: none">
              <span>COFINS: </span>
              <span style="margin-top: 12px">{{
                vlCofins | currency : "BRL"
              }}</span>
            </div>

            <div class="form-control" style="min-width: 0px; max-width: none">
              <span>IPI: </span>
              <span style="margin-top: 12px">{{
                vlIpi | currency : "BRL"
              }}</span>
            </div>

            <div
              class="form-control"
              *ngIf="!productForm.get('price.priceFree').value"
            >
              <span>Preço Sugerido</span>
              <span style="margin-top: 12px">{{
                suggestedPrice | currency : "BRL"
              }}</span>
            </div>
            <div
              class="form-control"
              *ngIf="!productForm.get('price.priceFree').value"
            >
              <label for="value">{{ "Preço praticado" | translate }}*</label>

              <p-inputNumber
                name="value"
                formControlName="value"
                mode="decimal"
                [minFractionDigits]="2"
                [maxFractionDigits]="2"
              ></p-inputNumber>
            </div>
          </div>
        </ng-container>
      </p-fieldset>

      <p-fieldset
        *ngIf="
          productForm?.get('productType') &&
          productForm?.get('productType').value !== 'Product'
        "
      >
        <p-header class="article-title">
          <div class="article-title">{{ "PRICE" | translate }}</div>
        </p-header>
        <div class="form-group">
          <div
            class="form-control"
            *ngIf="
              productForm?.get('productType') &&
              productForm?.get('productType').value !== 'Product'
            "
          >
            <label for="pricingType">{{ "PRICE-TYPE" | translate }}*</label>
            <p-dropdown
              placeholder="Selecione..."
              [options]="pricingTypeOptions"
              formControlName="pricingType"
              appendTo="body"
              (onChange)="changePriceType()"
              [readonly]="validateServiceHasServiceBoats()"
            ></p-dropdown>
          </div>

          <div
            class="form-control switch half"
            *ngIf="
              productForm?.get('discount') &&
              productForm?.get('discount').enabled
            "
          >
            <label for="discount">{{ "Conceder Descontos" | translate }}</label>
            <p-inputSwitch formControlName="discount" name="discount">
            </p-inputSwitch>
          </div>
          <ng-container formGroupName="price">
            <ng-container
              *ngIf="
                productForm?.get('pricingType') &&
                productForm.get('pricingType').value &&
                productForm.get('pricingType').value === 'SimplePrice'
              "
            >
              <div class="form-control switch half">
                <label for="priceFree">{{ "Preço livre" | translate }}</label>
                <p-inputSwitch
                  formControlName="priceFree"
                  name="priceFree"
                  (onChange)="priceFreeChange()"
                >
                </p-inputSwitch>
              </div>
              <div
                class="form-control quarter"
                *ngIf="!productForm.get('price.priceFree').value"
              >
                <label for="value">{{ "PRICE" | translate }}*</label>
                <!-- <input *ngIf="!isFixedPrice" pInputText name="value" formControlName="value"> -->
                <p-inputNumber
                  name="value"
                  formControlName="value"
                  mode="decimal"
                  [minFractionDigits]="2"
                  [maxFractionDigits]="2"
                ></p-inputNumber>
              </div>
            </ng-container>
            <br />
            <div class="form-group">
              <div
                class="table"
                formArrayName="priceRanges"
                *ngIf="
                  productForm?.get('pricingType') &&
                  productForm.get('pricingType').value &&
                  productForm.get('pricingType').value !== 'SimplePrice'
                "
              >
                <p-table
                  [value]="productForm?.get('price.priceRanges').controls"
                  [paginator]="true"
                  [rows]="5"
                  [alwaysShowPaginator]="false"
                >
                  <ng-template pTemplate="header">
                    <tr>
                      <th class="text-center">
                        {{ "START-LENGTH-RANGE" | translate }}
                      </th>
                      <th class="text-center">
                        {{ "END-LENGTH-RANGE" | translate }}
                      </th>
                      <th class="text-center">
                        {{
                          (productForm.get("pricingType").value ===
                          "PerRangeLength"
                            ? "PRICE-FEET"
                            : "PRICE-FIXED"
                          ) | translate
                        }}
                      </th>
                      <th class="header-action">
                        <button
                          class="fab-button primary"
                          type="button"
                          [readonly]="validateServiceHasServiceBoats()"
                          (click)="addNewRangeValue()"
                          pTooltip="Adicionar range de preço"
                          tooltipPosition="bottom"
                        >
                          <i class="pi pi-plus"></i>
                        </button>
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template
                    pTemplate="body"
                    let-priceRange
                    let-rowIndex="rowIndex"
                  >
                    <tr [formGroupName]="rowIndex">
                      <td class="text-center">
                        <p-inputNumber
                          name="startLengthRange"
                          formControlName="startLengthRange"
                          mode="decimal"
                          [minFractionDigits]="2"
                          [maxFractionDigits]="2"
                          [readonly]="true"
                        >
                        </p-inputNumber>
                      </td>
                      <td class="text-center">
                        <p-inputNumber
                          name="endLengthRange"
                          formControlName="endLengthRange"
                          mode="decimal"
                          [minFractionDigits]="2"
                          [maxFractionDigits]="2"
                          [readonly]="validateServiceHasServiceBoats()"
                          (onBlur)="updateStartLengthRange()"
                        >
                        </p-inputNumber>
                      </td>
                      <td class="text-center">
                        <p-inputNumber
                          name="value"
                          formControlName="value"
                          mode="decimal"
                          [minFractionDigits]="2"
                          [readonly]="validateServiceHasServiceBoats()"
                          [maxFractionDigits]="2"
                        >
                        </p-inputNumber>
                      </td>
                      <td>
                        <div
                          *ngIf="rowIndex > 0"
                          class="table-action"
                          type="button"
                          (click)="
                            validateServiceHasServiceBoats()
                              ? null
                              : deletePriceRange(priceRange.value, rowIndex)
                          "
                        >
                          <i class="pi pi-trash"></i>
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </ng-container>
        </div>
      </p-fieldset>
    </div>
    <br />
    <app-readjustments-product-table
      *ngIf="product?.readjustmentProduct?.length > 0"
      (eventLog)="goToLogIn($event)"
      [productId]="product.id"
      [readjustements]="product.readjustmentProduct"
    >
    </app-readjustments-product-table>
    <div class="form-group">
      <p-fieldset *ngIf="productForm?.get('productType').value === 'Product'">
        <p-header class="article-title">
          <div class="article-title">{{ "STOCK" | translate }}</div>
        </p-header>
        <div class="form-group">
          <div
            class="form-control quarter"
            *ngIf="
              productForm?.get('stock') && productForm?.get('stock').enabled
            "
          >
            <label for="stock">{{ "STOCK" | translate }}*</label>
            <span *ngIf="!isNew" name="stock" class="estoque">{{
              isMeasurement() == 0
                ? (productForm?.get("stock").value | number : "1.0-0")
                : (productForm?.get("stock").value | number : "1.3-3")
            }}</span>

            <p-inputNumber
              *ngIf="isNew"
              name="stock"
              [minFractionDigits]="isMeasurement()"
              [maxFractionDigits]="isMeasurement()"
              formControlName="stock"
            ></p-inputNumber>
          </div>
          <div
            class="form-control quarter"
            *ngIf="
              productForm?.get('minimalStock') &&
              productForm?.get('minimalStock').enabled
            "
          >
            <label for="minimalStock">{{ "MINIMAL-STOCK" | translate }}*</label>
            <p-inputNumber
              name="minimalStock"
              [minFractionDigits]="isMeasurement()"
              [maxFractionDigits]="isMeasurement()"
              formControlName="minimalStock"
            ></p-inputNumber>
          </div>
          <div
            class="form-control quarter"
            *ngIf="
              productForm?.get('appMinimalStock') &&
              productForm?.get('appMinimalStock').enabled
            "
          >
            <label for="appMinimalStock">{{
              "APP-MINIMAL-STOCK" | translate
            }}</label>
            <p-inputNumber
              name="appMinimalStock"
              [minFractionDigits]="isMeasurement()"
              [maxFractionDigits]="isMeasurement()"
              formControlName="appMinimalStock"
            ></p-inputNumber>
          </div>
          <div
            class="form-control quarter"
            *ngIf="
              productForm?.get('appMinimalStock') &&
              productForm?.get('appMinimalStock').enabled
            "
          >
            <label for="appMaxStock">{{ "MAX-STOCK" | translate }}</label>
            <p-inputNumber
              name="appMaxStock"
              [minFractionDigits]="isMeasurement()"
              [maxFractionDigits]="isMeasurement()"
              formControlName="maxStock"
            ></p-inputNumber>
          </div>
        </div>
      </p-fieldset>
      <p-fieldset class="half">
        <p-header class="article-title">
          <div class="article-title">{{ "IMAGE" | translate }}</div>
        </p-header>
        <div class="form-group">
          <div class="form-control">
            <label for="file">{{ "FILE" | translate }}</label>
            <p-fileUpload
              name="attachment"
              mode="basic"
              [auto]="true"
              maxFileSize="100000000"
              chooseLabel="Anexar"
              accept=".png,.jpg,.jpeg"
              [hidden]="uploadFileInProgress"
              (uploadHandler)="uploadFile($event.files)"
              customUpload="true"
              #upload
            >
            </p-fileUpload>
            <p-progressSpinner [hidden]="!uploadFileInProgress">
            </p-progressSpinner>
          </div>
          <div
            class="form-control"
            *ngIf="productForm?.get('file') && productForm.get('file').value"
          >
            <img [src]="productForm.get('file').value" width="96" />
          </div>
        </div>
      </p-fieldset>

      <p-fieldset>
        <p-header class="article-title">
          <div class="article-title">{{ "Códigos" | translate }}</div>
        </p-header>
        <div class="table">
          <p-table
            #tt
            [value]="productCodes"
            styleClass="easy-table"
            autoLayout="true"
          >
            <ng-template pTemplate="header">
              <tr>
                <th>Id no Fornecedor</th>
                <th>Fornecedor</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-product>
              <tr>
                <td>{{ product.productIdSupplier }}</td>
                <td>{{ product.supplierId }}</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </p-fieldset>
    </div>
    <div class="form-group align-center">
      <div class="form-control">
        {{ "REQUIRED-FIELDS" | translate }}
      </div>
      <div class="buttons">
        <button
          type="button"
          (click)="dialogRef.close()"
          class="easymarine danger"
        >
          {{ "CANCEL" | translate }}
        </button>
        <button class="easymarine primary" type="submit">
          {{ "SAVE" | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
