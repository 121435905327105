<div class="dialog-form">
  <form
    (submit)="viewReadjustment()"
    [formGroup]="adjustmentForm"
    style="display: flex; justify-content: space-between; width: 100%"
  >
    <div class="form-control align-content-center">
      <label>{{ "SERVICE" | translate }}</label>
      <p-dropdown
        [filter]="true"
        placeholder="Selecione..."
        [options]="filteredServicesInUse"
        formControlName="service"
        appendTo="body"
        name="service"
        (onChange)="changeService()"
        [disabled]="serviceBoatReadjustment || serviceCustomerReadjustment"
      >
      </p-dropdown>
    </div>
    <div class="form-readjustment">
      <div>
        <label for="boatName">{{
          "Reajuste em porcentagem" | translate
        }}</label>
        <input
          pInputText
          type="number"
          formControlName="percentage"
          min="0"
          name="percentage"
        />
      </div>
      <div>
        <label for="boatName">{{ "Aplicação" | translate }}</label>
        <div style="display: flex; flex-direction: row; height: 30px">
          <p-radioButton
            name="readjustmentType"
            formControlName="readjustmentType"
            *ngFor="let type of readjustmentTypes"
            [value]="type.value"
            [label]="
              this.messageUtil.translateKey('READJUSTMENT.' + type.label)
            "
            [inputId]="type.value"
            [disabled]="serviceBoatReadjustment || serviceCustomerReadjustment"
          >
          </p-radioButton>
        </div>
      </div>
      <button
        class="primary easymarine"
        style="justify-self: flex-end"
        [disabled]="
          adjustmentForm.invalid ||
          serviceBoatReadjustment ||
          serviceCustomerReadjustment
        "
      >
        Reajuste
      </button>
    </div>
  </form>
</div>

<app-config-price-ranges
  [service]="service"
  [enableReadjustment]="enableReadjustmentPercentages"
  (change)="cleanAdjustments($event)"
  (isValidChange)="changeIsValidPercentages($event)"
  *ngIf="enableReadjustmentPriceRanges()"
>
</app-config-price-ranges>

<app-config-price-simples
  [service]="service"
  [enableReadjustment]="enableReadjustmentPercentages"
  (change)="cleanAdjustments($event)"
  (isValidChange)="changeIsValidPercentages($event)"
  *ngIf="enableReadjustmentPriceSimple()"
>
</app-config-price-simples>

<div class="buttons-readjustment">
  <div class="buttons">
    <div class="form-button">
      <button
        (click)="cleanAdjustments()"
        [disabled]="!serviceBoatReadjustment"
        class="danger easymarine"
        type="button"
      >
        {{ "CLEAR" | translate }}
      </button>
    </div>
    <div class="form-button">
      <button
        class="primary easymarine"
        type="button"
        [disabled]="!enableApplyReadjustmentInServicesList()"
        (click)="applyReadjustmentInServicesList()"
      >
        {{ "Visualizar" | translate }}
      </button>
    </div>
  </div>
</div>

<app-table-service-readjustment
  (exportList)="exportListMethod($event)"
  *ngIf="serviceBoatReadjustment || serviceCustomerReadjustment"
  [serviceBoatReadjustment]="serviceBoatReadjustment"
  [serviceCustomerReadjustment]="serviceCustomerReadjustment"
  (eventApply)="applyReadjustment($event)"
></app-table-service-readjustment>
