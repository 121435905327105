<div [formGroup]="sailorForm">
  <p-fieldset>
    <p-header class="article-title">
      <div class="article-title">Informações</div>
    </p-header>
    <div class="form-group" style="border-bottom: 4em; align-items: flex-start">
      <app-sailor-upload
      [path]="imgPreviewPhoto"
      title="Foto"
      (finishEvent)="receiveUploadUrlPhoto($event)"
      >
      </app-sailor-upload>
      &nbsp;&nbsp;&nbsp;
      <app-sailor-upload
      [path]="imgPreviewDoc"
      title="Documento"
      (finishEvent)="receiveUploadUrlDoc($event)"
      >
      </app-sailor-upload>
      &nbsp;&nbsp;&nbsp;
      <app-sailor-upload
      [path]="imgPreviewDocVerso"
      title="Documento verso"
      (finishEvent)="receiveUploadUrlDocVerso($event)"
      >
      </app-sailor-upload>
    </div>
    <br>
    <div class="form-group">
      <div class="form-control radio max-w-1rem">
        <p-radioButton
          name="federalIdType"
          formControlName="federalIdType"
          value="Physical"
          label="{{ 'FEDERAL-ID.PHYSICAL' | translate }}"
          inputId="federalIdPhysical"
          [(ngModel)]="federalIdType"
          (onClick)="validateFederalIdType()"
        >
        </p-radioButton>
        <p-radioButton
          name="federalIdType"
          formControlName="federalIdType"
          value="Juridical"
          label="{{ 'FEDERAL-ID.JURIDICAL' | translate }}"
          inputId="federalIdJuridical"
          [(ngModel)]="federalIdType"
          (onClick)="validateFederalIdType()"
        >
        </p-radioButton>
      </div>
      <div class="form-control field max-w-1rem">
        <label for="accessCategory">Categoria de Acesso *</label>
        <p-dropdown [options]="accessCategoriesOptions" name="dueDate" formControlName="accessCategory" optionLabel="name"
                    appendTo="body" tooltipPosition="bottom">
        </p-dropdown>
      </div>
    </div>
    <div class="form-group">
      <div
        class="form-control field"
        *ngIf="isFederalTypePhysical; else isFederalIdJuridical"
        style="width: 33%"
      >
        <label for="name">{{ "FEDERAL-ID.PHYSICAL" | translate }} *</label>
        <p-inputMask
          type="text"
          mask="999.999.999-99"
          name="federalId"
          formControlName="federalId"
          (onBlur)="federalIdInUse(true)"
        >
        </p-inputMask>
      </div>
      <ng-template #isFederalIdJuridical>
        <div class="form-control" style="width: 33%">
          <label for="federalId">{{
            "FEDERAL-ID.JURIDICAL" | translate
          }}</label>
          <p-inputMask
            type="text"
            mask="99.999.999/9999-99"
            name="federalId"
            #federalId
            formControlName="federalId"
            (onBlur)="federalIdInUse(false)"
          >
          </p-inputMask>
        </div>
      </ng-template>

      <div class="form-control field" style="width: 33%">
        <label for="name" tyle="margin-bottom: 0px; padding-bottom:0px ; "
          >{{ nameLabel | translate }} *</label
        >
        <input pInputText name="name" formControlName="name" maxlength="50" />
      </div>

      <div class="form-control field" style="width: 33%">
        <label for="phone">{{ "CELLPHONE" | translate }}*</label>
        <p-inputMask
          type="text"
          mask="+99 (99) 99999-9999"
          name="phone"
          formControlName="phone"
        ></p-inputMask>
      </div>
    </div>
    <div class="form-group" *ngIf="readonlyForm">
      <span style="color: red;">
      Cadastro em modo de consulta. Somente vinculação de embarcações disponível. Outras alterações não serão salvas.
      </span>
    </div>
    <div class="form-group">
      <div class="form-control" style="width: 33%">
        <label for="typesSailorGroup">Tipo de Marinheiro *</label>
        <p-multiSelect
          formControlName="typesSailor"
          [defaultLabel]="'Tipo de Marinheiro'"
          appendTo="body"
          selectedItemsLabel="{0} itens selecionados"
          name="typesSailorGroup"
          [options]="permissionsOptions"
        ></p-multiSelect>
      </div>
      <div class="form-control" style="width: 33%">
        <label for="birthday">{{ dateLabel | translate }} *</label>
        <p-calendar
          dateFormat="dd/mm/yy"
          name="birthday"
          [monthNavigator]="true"
          [yearNavigator]="true"
          [yearRange]="'1950:2055'"
          rPCalendarMask
          appendTo="body"
          formControlName="birthday"
          [showIcon]="true"
          i18n
        >
        </p-calendar>
      </div>

      <div class="form-control field" style="width: 33%">
        <label for="email">E-mail pessoal (Login app) *</label>
        <input
          pInputText
          name="email"
          formControlName="email"
          type="email"
          maxlength="50"
          id="email"
        />
      </div>
    </div>
    <div class="form-group" *ngIf="!isFederalTypePhysical">
      <div class="form-control" style="width: 33%">
        <label for="countryRegistration">Inscrição municipal</label>
        <input
          pInputText
          name="countryRegistration"
          formControlName="countryRegistration"
          type="text"
          maxlength="50"
          id="countryRegistration"
        />
      </div>
    </div>
  </p-fieldset>
  <br />
  <p-fieldset>
    <p-header class="article-title">
      <div class="article-title">{{ "ADDRESS" | translate }}</div>
    </p-header>
    <app-address
      [address]="sailor.residentialAddress"
      (finishEvent)="receiveAddress($event)"
    >
    </app-address>
  </p-fieldset>
  <br />
  <p-fieldset>
    <p-header class="article-title">
      <div class="article-title">{{ "EMERGENCY-CONTACT" | translate }}</div>
    </p-header>
    <div class="form-group">
      <div class="form-control">
        <label for="emergencyContact">{{
          "EMERGENCY-CONTACT" | translate
        }}</label>
        <input
          pInputText
          name="emergencyContact"
          formControlName="emergencyContact"
          maxlength="50"
        />
      </div>
      <div class="form-control">
        <label for="emergencyContact">{{
          "EMERGENCY-PHONE" | translate
        }}</label>
        <p-inputMask
          type="text"
          mask="(99) 99999-9999"
          name="emergencyPhone"
          formControlName="emergencyPhone"
        >
        </p-inputMask>
      </div>
    </div>
    <div class="form-group">
      <div class="form-control" style="width: 99%">
        <label for="observation">Observações</label>
        <textarea
          pInputText
          maxlength="300"
          name="observation"
          rows="5"
          cols="90"
          formControlName="observation"
        ></textarea>
      </div>
    </div>
  </p-fieldset>
</div>
