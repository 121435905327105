import { Component, OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { CustomReportExportComponent } from 'src/app/components/extract-custom-report/custom-report-export.component';
import { SlingConfig } from 'src/app/models';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { MessageUtil } from 'src/app/utils/message.util';

@Component({
  selector: 'app-service-readjustment',
  templateUrl: './service-readjustment.component.html',
  styleUrls: ['./service-readjustment.component.scss'],
})
export class ServiceReadjustmentComponent implements OnInit {
  selectTab = 0;

  slingConfig: SlingConfig;

  serviceList = [];

  constructor(
    private messageUtil: MessageUtil,
    public dialog: DialogService,
    private slingConfigService: SlingConfigService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadSlingConfig();
  }

  onChangeSelectTab($event) {
    this.selectTab = $event.index;
  }

  exportList(exportList: any[]) {
    this.serviceList = exportList.map((item) => {
      return {
        boatName: item.boatName,
        shipType: this.getTranslatedName(item.shipType),
        commercialLength: item.commercialLength,
        serviceName: item.serviceName,
        serviceObservation: item.observation,
        serviceStartDate: item.contractStartDate,
        serviceEndDate: item.contractEndDate,
        lastReadjustmentDate: item.lastReadjustment,
        value: item.value,
        discount: item.discount,
        dueDateDiscount: item.dueDateDiscount,
        total: item.value - item.discount,
        newValue: item.readjustmentValue,
        newDiscount: item.readjustmentDiscount,
        newDiscountDueDate: item.readjustmentDueDateDiscount,
        newTotal: item.readjustmentValue - item.readjustmentDiscount,
        readjustmentDifference: item.readjustmentValue - item.value,
        newServiceEndDate: item.newEndService,
      };
    });
  }

  getTranslatedName(name: string): string {
    switch (name) {
      case 'Motorboat':
        return this.messageUtil.translateKey('MOTORBOAT');
      case 'Sailboat':
        return this.messageUtil.translateKey('SAILBOAT');
      case 'JetSki':
        return this.messageUtil.translateKey('JETSKI');
      case 'InflatableBoat':
        return this.messageUtil.translateKey('INFLATABLE-BOAT');
      case 'Yatch':
        return this.messageUtil.translateKey('YATCH');
      case 'Catamaran':
        return this.messageUtil.translateKey('CATAMARAN');
      case 'Schooner':
        return this.messageUtil.translateKey('SCHOONER');
      case 'Trawler':
        return this.messageUtil.translateKey('TRAWLER');
      case 'Kayak':
        return this.messageUtil.translateKey('KAYAK');
      case 'Canoe':
        return this.messageUtil.translateKey('CANOE');
    }
  }

  async loadSlingConfig(): Promise<void> {
    return new Promise<void>(async (res) => {
      this.slingConfigService.getSlingConfig().subscribe(
        async (data) => {
          this.slingConfig = data[0];
          res();
        },
        async () => res()
      );
    });
  }

  async exportTable(): Promise<void> {
    const data = { table: this.serviceList, type: 'READJUSTMENT_REPORT' };
    this.dialog.open(CustomReportExportComponent, {
      width: '70%',
      height: '90vh',
      dismissableMask: false,
      data,
      header: this.messageUtil.translateKey('EXPORT-COSTUMER-TABLE'),
    });
  }
}
