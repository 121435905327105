import { NgxSpinnerService } from 'ngx-spinner';
import { ProcessControl } from './../../models/processcontrol/processControl';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProcessControlService } from 'src/app/services/process-control.service';
import { WebSocketService } from 'src/app/services/web-socket.service';

@Component({
  selector: 'app-process-control-form',
  templateUrl: './process-control-form.component.html',
  styleUrls: ['./process-control-form.component.scss'],
})
export class ProcessControlFormComponent implements OnInit {
  listaProcessos: ProcessControl[] = [];

  private processSubscription: Subscription;

  constructor(
    private processControlService: ProcessControlService,
    private spinner: NgxSpinnerService,
    private webSocketService: WebSocketService
  ) {}

  ngOnInit() {
    this.getProcessos();
    this.processSubscription = this.webSocketService
      .getProcessControl()
      .subscribe(async (message) => {
        this.spinner.show();
        this.getProcessos();
        this.spinner.hide();
      });
  }

  getProcessos() {
    this.processControlService.getAll().subscribe({
      next: (resp) => {
        this.listaProcessos = resp;
      },
      error: (error) => {
        // Swal.fire('Atenção', 'Erro ao Recuperar imformações', 'warning');
      },
    });
  }

  download(file: string) {
    window.open(`${file}`, '_blank').focus();
  }
}
