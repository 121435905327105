<div class="dialog-form">

    <div class="dialog-form p-0" #cadPoint id="cadPoint" stop-click-propagation>
      <form (submit)="save()" [formGroup]="accessPointGroup">
        <p-fieldset class="operation-date">
          <p-header style="width: auto !important">
            <div>{{ "GENERAL-DATA" | translate }}</div>
          </p-header>
          <div class="form-group general">
            <div
              class="form-control col-12 lg:col-5 xl:col-5"
              pTooltip="Nome do Ponto de acesso"
              tooltipPosition="bottom"
            >
              <label>Nome *</label>
              <input
                pInputText
                type="text"
                name="nome"
                formControlName="name"
              />
            </div>
            <div
              class="form-control col-8 lg:col-5 xl:col-5"
              pTooltip="Local do Ponto de acesso"
              tooltipPosition="bottom"
            >
              <label>Local</label>
              <input
                pInputText
                type="text"
                name="location"
                formControlName="location"
              />
            </div>

            <div class="form-control col-4 lg:col-2 xl:col-2">
              <label>Ativo</label>
              <p-inputSwitch
                formControlName="active"
                name="active"
                pTooltip="O ponto de acesso esta ativo."
                tooltipPosition="bottom"
              ></p-inputSwitch>
            </div>
            <div class="card flex justify-content-center form-control col-4 lg:col-2 xl:col-2">
              <label>Direção</label>
              <p-dropdown
                [options]="directions"
                formControlName="direction"
                optionLabel="name"
                optionValue="code"
                placeholder="Selecione a direção"
                (onChange)="changeDirection($event)"
              ></p-dropdown>
             </div>
          </div>
        </p-fieldset>
        <br />
        <p-fieldset class="operation-date">
          <p-header style="width: auto !important">
            <div>{{ "Configuração de rede" | translate }}</div>
          </p-header>

          <div class="form-group general">
            <div
              class="form-control col-6 lg:col-2 xl:col-2"
              pTooltip="Endereço ip do ponto de acesso."
              tooltipPosition="bottom"
            >
              <label>End. Ip</label>
              <input pInputText type="text" name="ip" formControlName="ip" />
            </div>
            <div
              class="form-control col-6 lg:col-2 xl:col-2"
              pTooltip="Usuário de acesso ao dispositivo."
              tooltipPosition="bottom"
            >
              <label>Nome Usuário</label>
              <input
                pInputText
                type="text"
                name="username"
                formControlName="username"
              />
            </div>
            <div
              class="form-control col-6 lg:col-2 xl:col-2"
              pTooltip="Senha de acesso com dispositivo."
              tooltipPosition="bottom"
            >
              <label>Senha</label>
              <input
                pInputText
                type="password"
                name="password"
                formControlName="password"
              />
            </div>
          </div>
          <div class="form-group general">
            <div
              class="form-control col-6 lg:col-2 xl:col-2"
              pTooltip="Define a url que o dispositivo testa para ver se esta conectado."
              tooltipPosition="bottom"
            >
              <label>Url validação</label>
              <input
                pInputText
                type="text"
                name="urlLive"
                formControlName="urlLive"
              />
            </div>
            <div
              class="form-control col-6 lg:col-2 xl:col-2"
              pTooltip="Define o endereço da api chamada para validar a entrada."
              tooltipPosition="bottom"
            >
              <label>Api de Validação</label>
              <input
                pInputText
                type="text"
                name="urlValidation"
                formControlName="urlValidation"
              />
            </div>
          </div>
        </p-fieldset>
        <br />
        <p-fieldset class="operation-date pt-2">
          <p-header style="width: auto !important">
            <div>{{ "Configuração de leitor" | translate }}</div>
          </p-header>
          <div class="form-group general">
            <div class="form-control col-4 lg:col-2 xl:col-2">
              <label>Leitor de cartão</label>
              <p-inputSwitch
                formControlName="readCard"
                name="readCard"
                pTooltip="Leitor de cartão."
                tooltipPosition="left"
              ></p-inputSwitch>
            </div>
            <div class="form-control col-4 lg:col-2 xl:col-2">
              <label>Leitor digital</label>
              <p-inputSwitch
                formControlName="printFinger"
                name="printFinger"
                pTooltip="Possui leitor de digital."
                tooltipPosition="left"
              ></p-inputSwitch>
            </div>
            <div class="form-control col-4 lg:col-2 xl:col-2">
              <label>QRCode</label>
              <p-inputSwitch
                formControlName="qrCode"
                name="qrCode"
                pTooltip="Possui leitor de QRCode."
                tooltipPosition="left"
              ></p-inputSwitch>
            </div>
            <div class="form-control col-4 lg:col-2 xl:col-2">
              <label>Leitor facial</label>
              <p-inputSwitch
                formControlName="face"
                name="face"
                pTooltip="Possui leitor facial."
                tooltipPosition="left"
              ></p-inputSwitch>
            </div>
          </div>
        </p-fieldset>
        <div class="form-group align-center">
          <div class="form-control mt-4">
            {{ "REQUIRED-FIELDS" | translate }}
          </div>
          <div class="buttons">
            <button
              type="button"
              (click)="returnPage()"
              class="easymarine danger"
            >
              {{ "CANCEL" | translate }}
            </button>
            <button class="easymarine primary" type="submit">
              {{ "SAVE" | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
</div>
