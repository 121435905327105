<p-tabView>
    <p-tabPanel [cache]="false" header="Embarcação">
        <ng-template pTemplate="content">
            <p-fieldset>
                <p-header class="article-title">
                    <span class="article-title">{{'TITLE-BOAT-DETAIL-SERVICES' | translate}}</span>
                    <div class="header-buttons">
                        <button class="fab-button primary" (click)="exportTable()" label="Exportar Excel"
                          pTooltip="A exportação será de acordo com os filtros selecionados" tooltipPosition="left">
                                <i class="pi pi-file"></i>
                            </button> 
                        <button class="fab-button primary " appFiltersButton pTooltip="Exibir/Ocultar Filtros"
                            tooltipPosition="left">
                            <i class="pi pi-filter"></i>
                        </button>
                    </div>
                </p-header>
            
                <form class="filters active" (submit)="findBoatsService()" (keydown.enter)="$event.preventDefault()" (keyup.enter)="findBoatsService()" appFilters>
                    <div class="filter-row">
                        <div class="form-control">
                            <label for="boatName">{{'BOAT' | translate}}</label>
                            <input pInputText type="text" [(ngModel)]="boatFilter.boatName" name="boatName">
                        </div>
                        <div class="form-control">
                            <label for="shipType">{{'TYPE' | translate}}</label>
                            <p-dropdown [options]="shipTypeOptions" [(ngModel)]="boatFilter.shipType" name="shipType"
                                appendTo="body">
                            </p-dropdown>
                        </div>
                        <div class="form-control">
                            <label for="shipyardName">{{'SHIPYARD' | translate}}</label>
                            <input pInputText type="text" [(ngModel)]="boatFilter.shipyardName" name="shipyardName">
                        </div>
                        <div class="form-control">
                            <label for="shipyardModelName">{{'MODEL' | translate}}</label>
                            <input pInputText type="text" [(ngModel)]="boatFilter.shipyardModelName" name="shipyardModelName">
                        </div>
                        <div class="form-control">
                            <label for="tieRegister">{{'REGISTER' | translate}}</label>
                            <input pInputText type="text" [(ngModel)]="boatFilter.tieRegister" name="tieRegister">
                        </div>
            
                        <div class="form-control">
                            <label for="rangeDateStart">{{'SERVICE-START' | translate}}</label>
                            <p-calendar
                            dateFormat="dd/mm/yy"
                            name="rangeDateStart"
                            [monthNavigator]="true"
                            [yearNavigator]="true"
                            appendTo="body"
                            [yearRange]="'2000:2055'"
                            rPCalendarMask
                            selectionMode="range"
                            [(ngModel)]="this.rangeDatesStart"
                            [showIcon]="true"
                            i18n
                          >
                          </p-calendar>
                        </div>
                        <div class="form-control">
                            <label for="rangeDateEnd">{{'SERVICE-END' | translate}}</label>
                            <p-calendar
                            dateFormat="dd/mm/yy"
                            name="rangeDateEnd"
                            [monthNavigator]="true"
                            [yearNavigator]="true"
                            appendTo="body"
                            [yearRange]="'2000:2055'"
                            rPCalendarMask
                            selectionMode="range"
                            [(ngModel)]="this.rangeDatesEnd"
                            [showIcon]="true"
                            i18n
                          >
                          </p-calendar>
                        </div>
            
                        <div class="form-control">
                            <label for="active">{{'BOAT-STATUS' | translate}}</label>
                            <p-dropdown [(ngModel)]="boatFilter.active" name="active" appendTo="body"
                                [options]="[{label: 'Ambos', value: ''}, {label: 'Ativo', value: true}, {label: 'Inativo', value: false}]">
                            </p-dropdown>
                        </div>
            
                        <div class="form-control">
                            <label for="active">{{'SERVICE-STATUS' | translate}}</label>
                            <p-dropdown [(ngModel)]="boatFilter.serviceStatus" name="active" appendTo="body"
                                [options]="[{label: 'Ambos', value: ''}, {label: 'Ativo', value: true}, {label: 'Inativo', value: false}]">
                            </p-dropdown>
                        </div>
            
                        <div class="form-control">
                            <label for="vacancy">{{'Vagas' | translate}}</label>
                            <p-multiSelect [defaultLabel]="'Vagas'" [options]="vacancies" [(ngModel)]="boatFilter.vacanciesList"
                                appendTo="body" selectedItemsLabel="{0} itens selecionados" name="vacancy">
                            </p-multiSelect>
                        </div>
            
                        <div class="form-control">
                            <label for="grouped">{{'GROUP-BY-BOATS' | translate}}</label>
                            <p-checkbox (onChange)="groupByBoats()"
                                [binary]="true">
                            </p-checkbox>
                        </div>
                        
            
                        <div class="form-control">
                            <label for="grouped">{{'Serviços Vigentes' | translate}}</label>
                            <p-checkbox [ngModelOptions]="{standalone: true}" [binary]="true" [(ngModel)]="this.boatFilter.serviceInEffect" inputId="binary">
                            </p-checkbox>
                        </div>
            
                        <div class="buttons">
                            <!-- <div class="form-button">
                                <button class="easymarine btn-csv-xls" (click)="exportTable()">
                                        <span>Exportar tabela</span>
                                    </button>
                            </div> -->
                            <div class="form-button">
                                <button (click)="clear()" class="easymarine danger" type="button">{{ 'CLEAR' | translate }}</button>
                            </div>
                            <div class="form-button">
                                <button class="easymarine primary" type="submit">{{ 'FIND' | translate }}</button>
                            </div>
                        </div>
                    </div>
                </form>
            
                <div class="table">
                    <p-table #tt [value]="boats" [autoLayout]="true" [columns]="cols">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="th-border-left">
                                    <span>Totais projetados</span>
                                </th>
                                <th class="text-center th-border-left">Serviços</th>
                                <th class="text-center th-border-left">%</th>
                                <th class="text-center th-border-left">Descontos</th>
                                <th class="text-center th-border-left">%</th>
                                <th class="text-center th-border-left">Descontos Vencimento</th>
                                <th class="text-center th-border-left">%</th>
                                <th class="text-center th-border-left">Serviços com Descontos</th>
                                <th class="text-center th-border-left">%</th>
                                <th colspan="6" class="th-border-left"> </th>
                                <th colspan="1" class="text-center th-border-left">Total pés</th>
                                <th colspan="1" class="text-center th-border-left">Média pés</th>
                                <th colspan="1" class="text-center th-border-left">Total embarcações</th>
                            </tr>
                            <tr>
                                <th class="th-border-left">Anual</th>
                                <th class="text-right th-border-left">{{(totalValue * 12) | currency : 'BRL'}}</th>
                                <th rowspan="2" class="text-center th-border-left">{{1 | percent: '1.0-2'}}</th>
                                <th class="text-center th-border-left">{{(totalDesc * 12) | currency : 'BRL'}}</th>
                                <th rowspan="2" class="text-center th-border-left">{{((totalDesc) / totalValue) | percent: '1.0-2'}}</th>
                                <th class="text-center th-border-left">{{(totalDescVenc * 12) | currency : 'BRL'}}</th>
                                <th rowspan="2" class="text-center th-border-left">{{((totalDescVenc) / totalValue) | percent: '1.0-2'}}</th>
                                <th class="text-right th-border-left">{{(total * 12) | currency : 'BRL'}}</th>
                                <th rowspan="2" class="text-center th-border-left">{{((total) / totalValue) | percent: '1.0-2'}}</th>
                                <th colspan="6"> </th>
                                <td colspan="1" class="text-center th-border-left" >{{ totalCommercialLength}}</td>
                                <td colspan="1" class="text-center th-border-left" >{{ averageCommercialLength }}</td>
                                <td colspan="1" class="text-center th-border-left">{{ boats?.length }}</td>
                            </tr>
                            <tr>
                                <th class="th-border-left">Mensal</th>
                                <th class="text-right th-border-left">{{totalValue | currency : 'BRL'}}</th>
                                <th class="text-center th-border-left">{{totalDesc | currency : 'BRL'}}</th>
                                <th class="text-center th-border-left">{{totalDescVenc | currency : 'BRL'}}</th>
                                <th class="text-right th-border-left">{{total | currency : 'BRL'}}</th>
                                <th colspan="9"> </th>
                            </tr>
                            <tr>
                                <th colspan="18"></th>
                            </tr>
                            <tr>
                                <th>Status</th>
                                <th>Embarcação</th>
                                <th>Marinheiro</th>
                                <th>Vaga</th>
                                <th>Tipo</th>
                                <th>Cliente</th>
                                <th>Rateio</th>
                                <th>Início Serviço</th>
                                <th>Fim Serviço</th>
                                <th>Status Serviço</th>
                                <th>Serviço</th>
                                <th>TC.</th>
                                <th>Vl pé</th>
                                <th>Valor</th>
                                <th>Desc. Venc.</th>
                                <th>Desc.</th>
                                <th>%Desc.</th>
                                <th>Total</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-boat>
                            <tr>
                                <td class="text-center">
                                    <span [pTooltip]="boat.active ? 'Embarcação Ativa' : 'Embarcação Inativa'" class="badge badge-pill size-badge"
                                     [ngClass]="{'success':boat.active, 'danger': !boat.active}" tooltipPosition="top">
                                    </span>
                                </td>
                                <td>{{boat.name}}</td>
                                <td>
                                    <ul *ngIf="!groupedByBoats && boat.boatSailors" class="ul-list">
                                        <li *ngFor="let boatSailors of boat.boatSailors">
                                            {{boatSailors.sailor.name}}
                                        </li>
                                    </ul>
                                    <ul *ngIf="!groupedByBoats && boat.boatSailors.length == 0" class="ul-list">
                                        <li >
                                            ----
                                        </li>
                                    </ul>
            
                                    <ul *ngIf="groupedByBoats" class="ul-list">
                                        <li>
                                            {{getSailorNames(boat)}}
                                        </li>
                                    </ul>
            
                                </td>
                                <td><span *ngIf="boat.vacancy">{{boat.vacancy.code}}</span></td>
                                <td>{{boat.shipyardModel.shipType.toUpperCase() | translate}}</td>
                                <td>
                                    <ul *ngIf="!groupedByBoats && boat.boatCustomers" class="ul-list">
                                        <li *ngFor="let boatCustomer of boat.boatCustomers">
                                            {{boatCustomer.customer.name}}
                                        </li>
                                    </ul>
                                    <ul *ngIf="!groupedByBoats && boat.boatCustomers.length == 0" class="ul-list">
                                        <li >
                                            ----
                                        </li>
                                    </ul>
            
                                    <ul *ngIf="groupedByBoats" class="ul-list">
                                        <li>
                                            {{getCustomerNames(boat)}}
                                        </li>
                                    </ul>
            
                                </td>
            
                                <td>
                                    <ul *ngIf="!groupedByBoats" class="ul-list">
                                        <li *ngFor="let boatCustomer of boat.boatCustomers">
                                            {{boatCustomer.quota / 100 | percent: '1.0-2'}}
                                        </li>
                                    </ul>
                                    <ul *ngIf="groupedByBoats" class="ul-list">
                                        <li>
                                            100%
                                        </li>
                                    </ul>
                                </td>
            
                                <td class="text-center">
                                    <ul *ngIf="!groupedByBoats" class="ul-list">
                                        <li *ngFor="let boatService of boat.boatServices">
                                            {{boatService.contractStartDate | date:'dd/MM/yy'}}
                                        </li>
                                    </ul>
                                    <ul *ngIf="groupedByBoats" class="ul-list">
                                        <li>
                                            {{getEarliestStartDate(boat.boatServices) | date:'dd/MM/yy'}}
                                        </li>
                                    </ul>
            
                                </td>
            
                                <td class="text-center">
                                    <ul *ngIf="!groupedByBoats" class="ul-list">
                                        <li *ngFor="let boatService of boat.boatServices">
                                            {{boatService?.contractEndDate != null ? (boatService.contractEndDate | date:'dd/MM/yy') : '----'}}
                                        </li>
                                    </ul>
                                    <ul *ngIf="groupedByBoats" class="ul-list">
                                        <li>
                                            {{getLatestEndDate(boat.boatServices) | date:'dd/MM/yy'}}
                                        </li>
                                    </ul>
                                </td>
            
            
                                <td class="text-center">
                                    <ul *ngIf="!groupedByBoats" class="ul-list">
                                        <li *ngFor="let boatService of boat.boatServices">
                                            <span *ngIf="boatService" [pTooltip]="boatService.active ? 'Serviço Ativo' : 'Serviço Inativo'" class="badge badge-pill size-badge"
                                             [ngClass]="{'success':boatService.active, 'danger': !boatService.active}" tooltipPosition="top">
                                            </span>
                                            <span *ngIf="!boatService">----</span>
                                        </li>
                                    </ul>
                                    <ul *ngIf="groupedByBoats" class="ul-list">
                                        <li>
                                            <span *ngFor="let boatService of boat.boatServices" [pTooltip]="boatService.active ? 'Serviço Ativo' : 'Serviço Inativo'" class="badge badge-pill size-badge"
                                             [ngClass]="{'success':boatService.active, 'danger': !boatService.active}" tooltipPosition="top">
                                            </span>
                                        </li>
                                    </ul>
            
                                </td>
            
                                <td>
                                    <ul *ngIf="!groupedByBoats" class="ul-list">
                                        <li *ngFor="let boatService of boat.boatServices">
                                            {{boatService.service != null ? boatService.service.name : '----'}}
                                        </li>
                                    </ul>
                                    <ul *ngIf="groupedByBoats" class="ul-list">
                                        <li>
                                            {{getServicesNames(boat)}}
                                        </li>
                                    </ul>
                                </td>
                                <td>{{boat.commercialLength | number: '1.0-1':'pt'}}</td>
                                <td>
                                    <ul *ngIf="!groupedByBoats" class="ul-list ul-list-right">
                                        <li *ngFor="let boatService of boat.boatServices">
                                            {{getRangeValue(boat.commercialLength, boatService) | currency : 'BRL'}}
                                        </li>
                                    </ul>
                                    <ul *ngIf="groupedByBoats" class="ul-list ul-list-right">
                                        <li>
                                            {{getGroupedRangeValue(boat, "rangeValue") | currency : 'BRL'}}
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <ul *ngIf="!groupedByBoats" class="ul-list ul-list-right">
                                        <li *ngFor="let boatService of boat.boatServices" >
                                            {{boatService.value | currency : 'BRL'}}
                                        </li>
                                    </ul>
                                    <ul *ngIf="groupedByBoats" class="ul-list ul-list-right">
                                        <li>
                                            {{getGroupedRangeValue(boat, "value") | currency : 'BRL'}}
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <ul *ngIf="!groupedByBoats" class="ul-list ul-list-right">
                                        <li *ngFor="let boatService of boat.boatServices">
                                            {{boatService.dueDateDiscount | currency : 'BRL'}}
                                        </li>
                                    </ul>
                                    <ul *ngIf="groupedByBoats" class="ul-list ul-list-right">
                                        <li>
                                            {{getGroupedRangeValue(boat, "dueDateDiscount") | currency : 'BRL'}}
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <ul *ngIf="!groupedByBoats" class="ul-list ul-list-right">
                                        <li *ngFor="let boatService of boat.boatServices">
                                            {{boatService.discount | currency : 'BRL'}}
                                        </li>
                                    </ul>
                                    <ul *ngIf="groupedByBoats" class="ul-list ul-list-right">
                                        <li>
                                            {{getGroupedRangeValue(boat, "discount") | currency : 'BRL'}}
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <ul *ngIf="!groupedByBoats" class="ul-list ul-list-right">
                                        <li *ngFor="let boatService of boat.boatServices">
                                            {{getPercentDiscountOfTotalService(boatService) | percent: '1.0-2'}}
                                        </li>
                                    </ul>
                                    <ul *ngIf="groupedByBoats" class="ul-list ul-list-right">
                                        <li>
                                            {{getGroupedRangeValue(boat, "percentDiscount") | percent: '1.0-2'}}
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <ul *ngIf="!groupedByBoats" class="ul-list ul-list-right">
                                        <li *ngFor="let boatService of boat.boatServices">
                                            {{getTotalService(boatService) | currency : 'BRL'}}
                                        </li>
                                    </ul>
                                    <ul *ngIf="groupedByBoats" class="ul-list ul-list-right">
                                        <li>
                                            {{getGroupedRangeValue(boat, "totalService") | currency : 'BRL'}}
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="11">
                                    Nenhuma embarcação encontrada
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            
            </p-fieldset>   
        </ng-template>  
    </p-tabPanel>

    <p-tabPanel [cache]="false" header="Cliente" *ngIf="isCustomerServiceAvaliable" >
        <ng-template pTemplate="content">
            <!-- <app-customer-tuitions [groups]="groups" [groupsList]="groupsList" [loggedMarina]="loggedMarina"
                [marinaCompaniesList]="marinaCompaniesList"></app-customer-tuitions> -->
            <app-customer-services-details></app-customer-services-details>
        </ng-template>
    </p-tabPanel>
</p-tabView>