import {Component, Input, OnInit} from '@angular/core';
import {Marina} from '../../../../models';
import moment from 'moment/moment';
import {FormatUtil} from '../../../../utils/format.util';
import Swal from 'sweetalert2';
import {TuitionSummaryComponent} from '../tuition-summary/tuition-summary.component';
import {BoatFormComponent} from '../../../forms/boat/boat-form/boat-form.component';
import {TuitionRangeComponent} from '../tuition-range/tuition-range.component';
import {ProductType} from '../../../../models/enums';
import {NgxSpinnerService} from 'ngx-spinner';
import {DialogService} from 'primeng/dynamicdialog';
import {MessageUtil} from '../../../../utils/message.util';
import {TuitionService} from '../../../../services/tuition.service';
import {ToastService} from '../../../../services/toast.service';
import {ProductService} from '../../../../services/product.service';
import {Router} from '@angular/router';
import {MovementGroupService} from '../../../../services/movements/movement-group.service';
import {SelectItem} from 'primeng/api';
import {ContractEndDateStatus, monthReference} from '../tuitions.component';
import {debounceTime, Subject} from "rxjs";
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { StorageUtil } from 'src/app/utils/storage.util';

@Component({
  selector: 'app-boat-tuitions',
  templateUrl: './boat-tuitions.component.html',
  styleUrls: ['./boat-tuitions.component.scss']
})
export class BoatTuitionsComponent implements OnInit {
  @Input() groups: Array<{ label: string; value: any }>;
  @Input() groupsList: any[];
  @Input() loggedMarina: Marina;
  @Input() marinaCompaniesList: Array<{ label: string; value: any }>;

  tuitions = [];
  tuitionsTable = [];
  referenceMonth: number;
  protected readonly monthsReference: any[] = monthReference;
  referenceYear: number;
  referenceSituation: string;
  totalValue: number;
  totalDiscount: number;
  total: number;
  selectedTuitions: any[] = [];
  globalFilter = '';
  movementGroupOptions: { label: string; value: any }[];
  movementGroupFormOptions: Array<SelectItem> = [];

  contractList: any[] = [
    {label: 'Sem vencimento', value: ContractEndDateStatus.NORMAL},
    {label: 'Vencem mês que vem', value: ContractEndDateStatus.EXPIRES_NEXT_MONTH},
    {label: 'Vencem esse mês', value: ContractEndDateStatus.EXPIRES_THIS_MONTH},
    {label: 'Vencidos', value: ContractEndDateStatus.EXPIRED},
  ];

  company: any;
  groupIds: number[];
  periodicalServiceProductList: Array<SelectItem> = [];
  periodicalServiceProductListIds: Array<number> = [];

  contractListvalues: Array<ContractEndDateStatus> = [];

  numberOfRows = 10;

  buttonClicked = new Subject<void>();
  constructor(
    private spinner: NgxSpinnerService,
    private dialog: DialogService,
    private messageService: MessageUtil,
    private tuitionService: TuitionService,
    private toasty: ToastService,
    private productService: ProductService,
    private router: Router,
    private movementGroupService: MovementGroupService,
    private slingConfigService: SlingConfigService,
  ) {
  }

  ngOnInit() {
    this.loadSlingConfig();
    this.getMovementGroups();
    this.referenceMonth = moment().month();
    this.referenceYear = moment().year();
    this.findPeriodicalServiceProductList();
    if (this.router.url === '/app/billing/tuition/overdue') {
      this.getOverdueList(this.tuitions);
    }
    const buttonClickedDebounce = this.buttonClicked.pipe(debounceTime(200));
    buttonClickedDebounce.subscribe((_) => this.generateTuition())
  }

  private loadSlingConfig(): void {
    this.slingConfigService.getSlingConfig().subscribe(
      (data) => {
        const slingConfig = data[0];
        this.numberOfRows = slingConfig != null ? slingConfig.numberOfRecordsPerPage : 10;
      },
      () => null
    );
  }

  private startViewLaunch() {
    this.company = null;
    this.referenceSituation = 'allSituations';
    this.viewLaunch();
  }

  filterGlobal(): void {
    let tuitionTableAux = this.tuitions.filter(
      (t) =>
        FormatUtil.getNotAccents(t.boatName)
          .toUpperCase()
          .includes(
            FormatUtil.getNotAccents(this.globalFilter).toUpperCase()
          ) ||
        t.boatName.toUpperCase().includes(this.globalFilter.toUpperCase()) ||
        t.tuitionCustomers.find(
          (c) =>
            FormatUtil.getNotAccents(c.name)
              .toUpperCase()
              .includes(
                FormatUtil.getNotAccents(this.globalFilter).toUpperCase()
              ) ||
            c.name.toUpperCase().includes(this.globalFilter.toUpperCase()) ||
            FormatUtil.getNotAccents(c.groupName)
              .toUpperCase()
              .includes(
                FormatUtil.getNotAccents(this.globalFilter).toUpperCase()
              ) ||
            c.groupName.toUpperCase().includes(this.globalFilter.toUpperCase())
        )
    );
    if (this.referenceSituation !== 'allSituations') {
      if (this.referenceSituation === 'generated') {
        tuitionTableAux = tuitionTableAux.filter((t) => t.generate);
      } else {
        tuitionTableAux = tuitionTableAux.filter((t) => !t.generate);
      }
    }

    if (this.company) {
      if (this.company.marinaCompanies) {
        tuitionTableAux = tuitionTableAux.filter(
          (t) => t.tuitionCustomers.filter((c) => !c.companyId).length > 0
        );
      } else {
        tuitionTableAux = tuitionTableAux.filter(
          (t) =>
            t.tuitionCustomers.filter(
              (c) => c.companyId && c.companyId === this.company.id
            ).length > 0
        );
      }
    }

    if (this.groupIds && this.groupIds.length) {
      tuitionTableAux = tuitionTableAux.filter((t) => t.tuitionCustomers.filter((c) => this.groupIds.indexOf(c.groupId) >= 0).length > 0);
    }

    if (this.periodicalServiceProductListIds.length === 0) {
      const productListAux = this.periodicalServiceProductList.map((item) => item.value);
      tuitionTableAux = tuitionTableAux.filter((t) => productListAux.includes(t.productId));
    } else {
      tuitionTableAux = tuitionTableAux.filter((t) => this.periodicalServiceProductListIds.includes(t.productId));
    }
    if (this.contractListvalues.length > 0) {
      tuitionTableAux = tuitionTableAux.filter((t) => this.contractListvalues.includes(t?.hasContractEndDate));
    }
    this.tuitionsTable = tuitionTableAux;
  }

  cleanTuitions(): void {
    this.tuitions = [];
    this.tuitionsTable = [];
  }
  getLabelMonth(option: number): void {
    if (option === 0) {
      if (this.referenceMonth === 11) {
        return this.monthsReference[0].label;
      }
      return this.monthsReference.find(
        (mr) => mr.value === this.referenceMonth + 1
      ).label;
    } else if (option === 1) {
      return this.monthsReference.find((mr) => mr.value === this.referenceMonth)
        .label;
    }
  }
  viewLaunch(): void {
    this.selectedTuitions = new Array();
    const referenceDate = new Date(this.referenceYear, this.referenceMonth, 1, 0, 0, 0, 0);
    this.spinner.show();
    this.tuitionService.viewLaunchByBoat(referenceDate.valueOf()).subscribe(
      (data) => {
        this.tuitions = data;

        this.tuitions.forEach((t) => {
          t.isPartial = this.isPartial(t);
          t.getPriceTypeName = this.getPriceTypeName(t.pricingType);
          t.isContractEndDateStatusExpired = this.isContractEndDateStatusExpired(t);
          t.isInvalidBoat = this.isInvalidBoat(t);
          t.hasContractEndDate = this.hasContractEndDate(t);
        });

        this.tuitionsTable = this.tuitions;
        this.spinner.hide();
        this.filterGlobal();
        this.setTotal();
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageService.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      }
    );
  }

  dialogGenerateTuition(): void {
    if (
      this.selectedTuitions.filter((t) => this.isEnableSelect(t)).length > 0
    ) {
      Swal.fire({
        text: 'Deseja gerar os pedidos de mensalidades baseadas nos valores apresentados?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
      }).then((isConfirm) => {
        if (isConfirm.value) {
          this.buttonClicked.next();
        }
      });
    } else {
      this.messageService.generateMessage(
        'warning',
        'Atenção',
        'Não há itens na seleção.'
      );
    }
  }

  dialogMonthlySummary(): void {
    this.dialog.open(TuitionSummaryComponent, {
      width: '50%',
      height: '90vh',
      header: 'Resumo de mensalidades',
      data: {
        monthlysSummary: this.getMonthlysSummary(),
        monthlysSummaryByGroup: this.getMonthlysSummaryByGroup(),
      },
    });
  }

  getFontColor(tuition): string {
    if (this.isInvalidBoat(tuition)) {
      // tslint:disable-next-line: triple-equals
      if (tuition?.hasContractEndDate == ContractEndDateStatus.EXPIRED) {
        return '#b32a2ad9';
      } else {
        return 'red';
      }
    }
    if (tuition.total === 0) {
      return 'green';
    }
    return '#2196F3';
  }

  isInvalidBoat(tuition): boolean {
    return (
      !tuition.pricingType ||
      tuition.tuitionCustomers.length === 0 ||
      !tuition.contractStartDate
    );
  }

  isContractEndDateStatusExpired(tuition) {
    return tuition?.hasContractEndDate == ContractEndDateStatus.EXPIRED;
  }

  isEnableSelect(tuition): boolean {
    return (
      !tuition.generate &&
      tuition.tuitionCustomers.length > 0 &&
      tuition.pricingType &&
      !this.isContractEndDateStatusExpired(tuition) &&
      this.hasContractStartDate(tuition)
    );
  }

  isMonthlyPayment(): boolean {
    return (
      this.tuitionsTable.find(
        (t) =>
          !t.generate &&
          t.tuitionCustomers.length > 0 &&
          t.pricingType &&
          t?.hasContractEndDate !== ContractEndDateStatus.EXPIRED
      ) !== undefined
    );
  }

  hasContractEndDate(tuition: any): ContractEndDateStatus {
    if (tuition.contractEndDate) {
      const referenceDate = new Date(this.referenceYear, this.referenceMonth, 1, 0, 0, 0, 0);
      const contractEndDate = new Date(tuition.contractEndDate);
      if (
        referenceDate.getMonth() === contractEndDate.getMonth() &&
        referenceDate.getFullYear() === contractEndDate.getFullYear()
      ) {
        return ContractEndDateStatus.EXPIRES_THIS_MONTH;
      } else if (
        (referenceDate.getMonth() === 1 && contractEndDate.getMonth() === 0 && referenceDate.getFullYear() === contractEndDate.getFullYear() + 1) ||
        (referenceDate.getMonth() + 1 === contractEndDate.getMonth() && referenceDate.getFullYear() === contractEndDate.getFullYear())
      ) {
        return ContractEndDateStatus.EXPIRES_NEXT_MONTH;
      } else if (referenceDate.getTime() > contractEndDate.getTime()) {
        return ContractEndDateStatus.EXPIRED;
      }
    } else {
      return ContractEndDateStatus.NORMAL;
    }
    return ContractEndDateStatus.NORMAL;
  }

  getBackgroundTuition(tuition): string {
    const statusService: ContractEndDateStatus =
     tuition?.hasContractEndDate;
    return this.getBackgroundContractEndDateStatus(statusService);
  }

  getBackgroundContractEndDateStatus(
    statusService: ContractEndDateStatus
  ): string {
    let color;
    switch (statusService) {
      case ContractEndDateStatus.NORMAL:
        color = '';
        break;
      case ContractEndDateStatus.EXPIRES_NEXT_MONTH:
        color = '#7cf54a';
        break;
      case ContractEndDateStatus.EXPIRES_THIS_MONTH:
        color = '#fddd33';
        break;
      case ContractEndDateStatus.EXPIRED:
        color = 'rgb(255 154 154)';
        break;
      default:
        color = '';
        break;
    }

    return color;
  }

  hasContractStartDate(tuition: any): boolean {
    if (tuition.contractStartDate) {
      const referenceDate = new Date(
        this.referenceYear,
        this.referenceMonth + 1,
        1,
        0,
        0,
        0,
        0
      );
      const contractStartDate = new Date(tuition.contractStartDate);
      if (contractStartDate.getTime() < referenceDate.getTime()) {
        return true; // amarelo
      }
    }
    return false;
  }

  openDialogBoatForm(id?: number): void {

    this.dialog
      .open(BoatFormComponent, {
        height: '85%',
        width: '80%',
        dismissableMask: false,
        data: {
          id,
          movementGroupOptions: this.movementGroupOptions,
        },
        header: this.messageService.translateKey('BOAT-REGISTRATION'),
      })
      .onClose.subscribe(() => {
      this.viewLaunch();
    });
  }

  getMovementGroups(): Promise<void> {
    return new Promise(resolve => {
      this.movementGroupService.getAll().subscribe({
          next: data => {
            this.movementGroupOptions = data.filter(group => group.active).map(group => ({label: group.name, value: group}));
            resolve();
          },
          error: error => {
            resolve();
          },
          complete: () => this.movementGroupFormOptions = this.movementGroupOptions.map(group => ({
            label: group.label,
            value: group.value.id
          }))
        }
      );
    });
  }


  getPriceTypeName(priceType: string): string {
    switch (priceType) {
      case 'PerRangeLength':
        return 'Range';
      case 'FixedPerRange':
        return 'Fixo';
      case 'SimplePrice':
        return 'Simples';
      default:
        return '';
    }
  }

  private generateTuition(): void {
    this.spinner.show().then(_ => {
      const referDate = new Date(
        Number(this.referenceYear),
        Number(this.referenceMonth),
        1,
        0,
        0,
        0,
        0
      );
      const boatsTution = this.selectedTuitions
        .filter((t) => this.isEnableSelect(t))
        .map((t) => ({
          idServiceBoat: t.serviceBoatId,
          referenceDate: referDate,
        }));

        if (StorageUtil.getMarinaId()===2)
     { Swal.fire('Geração de Mensalidade', 'Sua Solicitação foi enviada para a fila de processo.', 'success');}

      this.tuitionService.generateByBoat(boatsTution).subscribe(
        () => {
          this.toasty.success('Mensalidades geradas com sucesso.');
          this.tuitions = [];
          this.tuitionsTable = [];
          this.selectedTuitions = [];
          this.viewLaunch();
          this.spinner.hide();
        },
        (error) => {
          const exception = error.error.data.exception;
          this.messageService.generateMessage(
            exception.type,
            exception.title,
            exception.message
          );
          this.spinner.hide();
        }
      );
    });

  }

  private getMonthlysSummary(): any[] {
    const monthlysSummary = [];
    let customers = [];
    this.tuitions.forEach(
      (t) => (customers = customers.concat(t.tuitionCustomers))
    );
    const monthlySummary = FormatUtil.groupBy(customers, 'dueDate');
    // tslint:disable-next-line:forin
    for (const key in monthlySummary) {
      const totalMonthly = monthlySummary[key].reduce(
        (total, customer) => total + customer.value,
        0
      );
      monthlysSummary.push({
        dueDate: monthlySummary[key][0].dueDate,
        customerQty: monthlySummary[key].length,
        total: totalMonthly,
      });
    }
    return monthlysSummary.sort((a, b) => (a.dueDate > b.dueDate && 1) || -1);
  }

  private getMonthlysSummaryByGroup(): any[] {
    const monthlysSummaryByGroup = new Array();
    let customers = new Array();
    this.tuitions.forEach(
      (t) => (customers = customers.concat(t.tuitionCustomers))
    );
    customers
      .filter((t) => !t.groupName)
      .forEach((t) => (t.groupName = 'Sem grupo'));
    const monthlySummaryByGroup: any[] = FormatUtil.groupBy(
      customers,
      'groupName'
    );
    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < monthlySummaryByGroup.length; index++) {
      const totalMonthly = monthlySummaryByGroup[index].reduce(
        (total, customer) => total + customer.value,
        0
      );
      monthlysSummaryByGroup.push({
        groupName: monthlySummaryByGroup[index][0].groupName,
        customerQty: monthlySummaryByGroup[index].length,
        total: totalMonthly,
      });
    }
    return monthlysSummaryByGroup.sort((a, b) => {
      if (a.groupName > b.groupName) {
        return 1;
      } else if (a.groupName < b.groupName) {
        return -1;
      }
      return 0;
    });
  }

  private setTotal(): void {
    this.totalValue = this.tuitionsTable.reduce(
      (total, t) => total + t.value,
      0
    );
    this.totalDiscount = this.tuitionsTable.reduce(
      (total, t) => total + t.discount,
      0
    );
    this.total = this.tuitionsTable.reduce((total, t) => total + t.total, 0);
  }

  generateMonthRange(tuition): void {
    this.dialog
      .open(TuitionRangeComponent, {
        height: '85%',
        width: '80%',
        dismissableMask: false,
        data: {tuition},
        header:
          'Gerar mensalidades por período - Embarcação: ' + tuition.boatName,
      })
      .onClose.subscribe(() => {
      this.viewLaunch();
    });
  }

  findPeriodicalServiceProductList(): void {
    const periodicalService: ProductType = ProductType.PeriodicalService;
    this.periodicalServiceProductList = [];
    this.productService
      .getByProductType(periodicalService)
      .subscribe((products) => {
        this.periodicalServiceProductList = [{label: 'Sem serviço', value: null}]
          .concat(products.filter(i => i.active)
            .map((item) => ({label: item.name, value: item.id,}))
          );
        this.periodicalServiceProductListIds = this.periodicalServiceProductList.map(item => item.value);
        this.startViewLaunch();
      });
  }

  getOverdueList(tuitions: any) {
    this.contractListvalues = [ContractEndDateStatus.EXPIRED];
    this.tuitions = tuitions.filter((t) => t?.hasContractEndDate === ContractEndDateStatus.EXPIRED);
  }

  filterCompanies(): void {
    let filter;
    // Se selecionou a marina
    if (this.company && this.company.marinaCompanies) {
      filter = this.groupsList
        .filter((g) => !g.marinaCompany)
        .map((g) => ({label: g.name, value: g.id}));
      // Se selecionou uma empresa adicional
    } else if (this.company && !this.company.marinaCompanies) {
      filter = this.groupsList
        .filter(
          (g) => g.marinaCompany && g.marinaCompany.id === this.company.id
        )
        .map((g) => ({label: g.name, value: g.id}));
    } else if (!this.company) {
      this.groups = [{label: 'Sem grupo', value: null}].concat(
        this.groupsList.map((g) => ({label: g.name, value: g.id}))
      );
      this.groupIds = [];
    }
    if (filter) {
      this.groupIds = [null].concat(filter.map((g) => g.value));
      this.groups = [{label: 'Sem grupo', value: null}].concat([...filter]);
    }
  }

  getCompany(tuition): string {
    if (!tuition.tuitionCustomers || tuition.tuitionCustomers.length === 0) {
      return '';
    }
    if (tuition.tuitionCustomers.length === 1) {
      if (tuition.tuitionCustomers[0].companyId) {
        return this.getCompanyIndex(
          tuition.tuitionCustomers[0].companyId
        ).toString();
      }
      return '1';
    }
    const companies = [];
    let index = 1;
    tuition.tuitionCustomers.forEach((customer) => {
      if (!customer.companyId && !companies.find((x) => x === 1)) {
        companies.push(1);
      } else if (customer.companyId) {
        index = this.getCompanyIndex(customer.companyId);
        if (!companies.find((x) => x === index)) {
          companies.push(index);
        }
      }
    });
    return companies
      .sort((a, b) => a - b)
      .toString()
      .replace(',', '/');
  }

  getCompanyIndex(companyId): number {
    if (companyId) {
      const m = this.loggedMarina.marinaCompanies.find(
        (c) => c.id === companyId
      );
      return this.loggedMarina.marinaCompanies.indexOf(m) + 2;
    }
    return 1;
  }

  getTotalSelected(company: any): number {
    if (!this.selectedTuitions || this.selectedTuitions.length === 0) {
      return 0;
    }
    if (company.marinaCompanies) {
      return this.selectedTuitions.reduce(
        (total, tuition) =>
          total +
          (tuition.tuitionCustomers.length > 0
            ? tuition.tuitionCustomers
              .filter((tuit) => !tuit.companyId)
              .reduce((tot, customer) => tot + customer.value, 0)
            : 0),
        0
      );
    } else {
      return this.selectedTuitions.reduce(
        (total, tuition) =>
          total +
          (tuition.tuitionCustomers.length > 0
            ? tuition.tuitionCustomers
              .filter((tuit) => tuit.companyId === company.id)
              .reduce((tot, customer) => tot + customer.value, 0)
            : 0),
        0
      );
    }
  }

  getTotalCompany(company: any): number {
    if (company.marinaCompanies) {
      return this.tuitionsTable.reduce(
        (total, tuition) =>
          total +
          (tuition.tuitionCustomers.length > 0
            ? (tuition.value *
              tuition.tuitionCustomers
                .filter((tuit) => !tuit.companyId)
                .reduce((tot, customer) => tot + customer.quota, 0)) /
            100
            : tuition.value),
        0
      );
    } else {
      return this.tuitionsTable.reduce(
        (total, tuition) =>
          total +
          (tuition.tuitionCustomers.length > 0
            ? (tuition.value *
              tuition.tuitionCustomers
                .filter((tuit) => tuit.companyId === company.id)
                .reduce((tot, customer) => tot + customer.quota, 0)) /
            100
            : tuition.value),
        0
      );
    }
  }

  getDiscountCompany(company: any): number {
    if (company.marinaCompanies) {
      return this.tuitionsTable.reduce(
        (total, tuition) =>
          total +
          (tuition.tuitionCustomers.length === 0
            ? tuition.dicount
            : tuition.tuitionCustomers.length > 0 &&
            tuition.tuitionCustomers.filter((tuit) => !tuit.companyId)
              .length > 0
              ? (tuition.discount *
                tuition.tuitionCustomers
                  .filter((tuit) => !tuit.companyId)
                  .reduce((tot, c) => tot + c.quota, 0)) /
              100
              : 0),
        0
      );
    } else {
      return this.tuitionsTable.reduce(
        (total, tuition) =>
          total +
          (tuition.tuitionCustomers.length === 0
            ? tuition.dicount
            : tuition.tuitionCustomers.length > 0 &&
            tuition.tuitionCustomers.filter(
              (tuit) => tuit.companyId === company.id
            ).length > 0
              ? (tuition.discount *
                tuition.tuitionCustomers
                  .filter((tuit) => tuit.companyId === company.id)
                  .reduce((tot, c) => tot + c.quota, 0)) /
              100
              : 0),
        0
      );
    }
  }

  getValueCompany(company: any): number {
    if (company.marinaCompanies) {
      return this.tuitionsTable.reduce(
        (total, tuition) =>
          total +
          (tuition.tuitionCustomers.length > 0
            ? tuition.tuitionCustomers
              .filter((tuit) => !tuit.companyId)
              .reduce((tot, customer) => tot + customer.value, 0)
            : tuition.total),
        0
      );
    } else {
      return this.tuitionsTable.reduce(
        (total, tuition) =>
          total +
          (tuition.tuitionCustomers.length > 0
            ? tuition.tuitionCustomers
              .filter((tuit) => tuit.companyId === company.id)
              .reduce((tot, customer) => tot + customer.value, 0)
            : tuition.total),
        0
      );
    }
  }

  isPartial(tuition) {
    return (
      FormatUtil.roundTwoDecimal(tuition.total) + tuition.discount !=
      FormatUtil.roundTwoDecimal(tuition.value) &&
      this.hasContractStartDate(tuition) &&
      !this.isContractEndDateStatusExpired(tuition)
    );
  }

}
