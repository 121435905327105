import { Component } from '@angular/core';
import { ServiceBoatToWinServicesDTO } from 'src/app/models/dtos/serviceBoatToWinServicesDTO';
import { Tuition } from 'src/app/models/tuition';
import { BoatService } from 'src/app/services/boat.service';

@Component({
  selector: 'app-due-expire-tuitions',
  templateUrl: './due-expire-tuitions.component.html',
  styleUrls: ['./due-expire-tuitions.component.scss']
})



export class DueExpireTuitionsComponent {
  tuitions!: ServiceBoatToWinServicesDTO[];

  constructor(private boatService: BoatService) {}

  ngOnInit() {
      this.boatService.getExpiringServices().subscribe((tuition) => (this.tuitions = tuition));
      console.log(this.tuitions);
      
  }
}
