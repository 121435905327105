<p-fieldset class="pt-0">
    <p-header class="article-title">
        <span class="article-title">
            {{ "RELATÓRIO DE EMBARCAÇÕES" | translate }}
        </span>
        <div class="new-header-buttons">
          <button class="fab-button primary" (click)="exportTable()" label="Exportar valores"
          pTooltip="Exportar Valores" tooltipPosition="left">
          <i class="pi pi-file"></i>
           </button>

            <button
            class="fab-button primary"
            appFiltersButton
            pTooltip="Exibir/Ocultar Filtros"
            tooltipPosition="left"
            >
            <i class="pi pi-filter"></i>
        </button>
      </div>
    </p-header>

    <form class="new-filters" appFilters (submit)="getReport()">
        <div class="new-filter-row">
          <div class="new-form-control-sm">
            <label>{{ "BOAT" | translate }}</label>
            <p-multiSelect
                [(ngModel)]="selectedBoats" [filter]="true" [options]="boats"
                appendTo="body" name="selectedboatId" defaultLabel="Selecione..." selectedItemsLabel="{0} itens selecionados">
            </p-multiSelect>
          </div>

          <div class="new-form-control-sm">
            <label>{{ "CUSTOMER" | translate }}</label>
            <p-multiSelect
                [(ngModel)]="selectedCustomers" [filter]="true" [options]="customers"
                appendTo="body" name="selectedCustomerId" defaultLabel="Selecione..." selectedItemsLabel="{0} itens selecionados">
            </p-multiSelect>
          </div>

          <div class="new-form-control-sm">
            <label for="startYearPicker">{{ "Data Inicial" | translate }}</label>
             <p-calendar
                view="year"
                [(ngModel)]="startYear"
                dateFormat="yy"
                name="startYearPicker"
                appendTo="body"
                [yearRange]="'2000:2055'"
                [showIcon]="true"
                readonlyInput="true"
                inputStyle="background-color: white"
                 icon="pi pi-calendar enlarged-icon">
            </p-calendar>
          </div>
          <div class="new-form-control-sm">
            <label for="endYearPicker">{{ "Data Final" | translate }}</label>
            <p-calendar
                view="year"
                [(ngModel)]="endYear"
                dateFormat="yy"
                name="endYearPicker"
                appendTo="body"
                [yearRange]="'2000:2055'"
                [showIcon]="true"
                readonlyInput="true"
                inputStyle="background-color: white"
                icon="pi pi-calendar enlarged-icon">
            </p-calendar>
          </div>
        </div>
        <div class="new-filter-row">
          <div class="subtitles">
            <!-- <fieldset class="side-by-side">
              <legend>Legenda (Cores de fundo)</legend>
              <div class="red">
                <p-checkbox
                  value="red"
                  class="clickable"
                  type="checkbox"
                  class="checkbox"
                ></p-checkbox>
                Dados Incompletos
              </div>
            </fieldset> -->
            <div class="new-form-button">
              <button class="new-easymarine primary" type="submit">
                {{ "FIND" | translate }}
                &nbsp;
                <i class="pi pi-search"></i>
              </button>
            </div>
            <div class="new-form-button">

              <button class="new-easymarine danger" type="button">
                {{ "CLEAR" | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    <div>
        <table class="easy-table" cellspacing="0" cellpadding="10">
            <tr class="header">
                <th></th>
                <th>Jan</th>
                <th>Fev</th>
                <th>Mar</th>
                <th>Abril</th>
                <th>Mai</th>
                <th>Jun</th>
                <th>Jul</th>
                <th>Ago</th>
                <th>Set</th>
                <th>Out</th>
                <th>Nov</th>
                <th>Dez</th>
            </tr>
            <tr class="body text-center">
                <td class="header">Qtd de Embarcações Faturadas</td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[0].tuitionBoats}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[1].tuitionBoats}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[2].tuitionBoats}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[3].tuitionBoats}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[4].tuitionBoats}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[5].tuitionBoats}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[6].tuitionBoats}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[7].tuitionBoats}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[8].tuitionBoats}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[9].tuitionBoats}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[10].tuitionBoats}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[11].tuitionBoats}}
                </td>
            </tr>
            <tr class="text-center">
                <td class="header">Entradas</td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[0].qtdIn}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[1].qtdIn}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[2].qtdIn}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[3].qtdIn}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[4].qtdIn}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[5].qtdIn}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[6].qtdIn}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[7].qtdIn}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[8].qtdIn}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[9].qtdIn}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[10].qtdIn}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[11].qtdIn}}
                </td>
            </tr>
            <tr class="text-center">
                <td class="header">Saidas</td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[0].qtdOut}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[1].qtdOut}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[2].qtdOut}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[3].qtdOut}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[4].qtdOut}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[5].qtdOut}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[6].qtdOut}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[7].qtdOut}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[8].qtdOut}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[9].qtdOut}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[10].qtdOut}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[11].qtdOut}}
                </td>
            </tr>
            <tr  class="text-center">
                <td class="header">Variações</td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[0].qtdIn - this.boatReport.boatInactivationReports[0].qtdOut}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[1].qtdIn - this.boatReport.boatInactivationReports[1].qtdOut}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[2].qtdIn - this.boatReport.boatInactivationReports[2].qtdOut}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[3].qtdIn - this.boatReport.boatInactivationReports[3].qtdOut}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[4].qtdIn - this.boatReport.boatInactivationReports[4].qtdOut}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[5].qtdIn - this.boatReport.boatInactivationReports[5].qtdOut}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[6].qtdIn - this.boatReport.boatInactivationReports[6].qtdOut}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[7].qtdIn - this.boatReport.boatInactivationReports[7].qtdOut}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[8].qtdIn - this.boatReport.boatInactivationReports[8].qtdOut}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[9].qtdIn - this.boatReport.boatInactivationReports[9].qtdOut}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[10].qtdIn - this.boatReport.boatInactivationReports[10].qtdOut}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[11].qtdIn - this.boatReport.boatInactivationReports[11].qtdOut}}
                </td>
            </tr>
            <tr  class="text-center">
                <td class="header">Qtd de Emb sem serviços ativos</td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[0].notTuitionBoats}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[1].notTuitionBoats}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[2].notTuitionBoats}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[3].notTuitionBoats}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[4].notTuitionBoats}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[5].notTuitionBoats}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[6].notTuitionBoats}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[7].notTuitionBoats}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[8].notTuitionBoats}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[9].notTuitionBoats}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[10].notTuitionBoats}}
                </td>
                <td>
                    {{this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[11].notTuitionBoats}}
                </td>
            </tr>

        </table>
    </div>

    <div>
        <div class="active-header">
            <h1>Ativo</h1>
        </div>
        <p-table
            [value]="boatReport?.activeBoatTuitionsReports | orderBy: 'boatName, logDate'"
            [scrollable]="true"
            scrollHeight="400px"
            styleClass="easy-table"
            sortMode="multiple"
        >
            <ng-template pTemplate="header">
                <tr>

                    <th pSortableColumn="boatName">Embarcação <p-sortIcon field="boatName"></p-sortIcon></th>
                    <th pSortableColumn="logDate">Data Entrada <p-sortIcon field="logDate"></p-sortIcon></th>
                    <th>Jan</th>
                    <th>Fev</th>
                    <th>Mar</th>
                    <th>Abr</th>
                    <th>Mai</th>
                    <th>Jun</th>
                    <th>Jul</th>
                    <th>Ago</th>
                    <th>Set</th>
                    <th>Out</th>
                    <th>Nov</th>
                    <th>Dez</th>
                </tr>
                <tr>
                    <th></th>
                    <th></th>
                    <th>{{this.activeTuitionSum[0] | number:'1.2-2'}}</th>
                    <th>{{this.activeTuitionSum[1] | number:'1.2-2'}}</th>
                    <th>{{this.activeTuitionSum[2] | number:'1.2-2'}}</th>
                    <th>{{this.activeTuitionSum[3] | number:'1.2-2'}}</th>
                    <th>{{this.activeTuitionSum[4] | number:'1.2-2'}}</th>
                    <th>{{this.activeTuitionSum[5] | number:'1.2-2'}}</th>
                    <th>{{this.activeTuitionSum[6] | number:'1.2-2'}}</th>
                    <th>{{this.activeTuitionSum[7] | number:'1.2-2'}}</th>
                    <th>{{this.activeTuitionSum[8] | number:'1.2-2'}}</th>
                    <th>{{this.activeTuitionSum[9] | number:'1.2-2'}}</th>
                    <th>{{this.activeTuitionSum[10] | number:'1.2-2'}}</th>
                    <th>{{this.activeTuitionSum[11] | number:'1.2-2'}}</th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-boat>
                <tr>
                    <td>{{boat.boatName}}</td>
                    <td>{{boat?.logDate  | date:'dd/MM/yyyy'}}</td>
                    <td>{{boat?.tuitions[0]?.value | number:'1.2-2'}}</td>
                    <td>{{boat?.tuitions[1]?.value | number:'1.2-2'}}</td>
                    <td>{{boat?.tuitions[2]?.value | number:'1.2-2'}}</td>
                    <td>{{boat?.tuitions[3]?.value | number:'1.2-2'}}</td>
                    <td>{{boat?.tuitions[4]?.value | number:'1.2-2'}}</td>
                    <td>{{boat?.tuitions[5]?.value | number:'1.2-2'}}</td>
                    <td>{{boat?.tuitions[6]?.value | number:'1.2-2'}}</td>
                    <td>{{boat?.tuitions[7]?.value | number:'1.2-2'}}</td>
                    <td>{{boat?.tuitions[8]?.value | number:'1.2-2'}}</td>
                    <td>{{boat?.tuitions[9]?.value | number:'1.2-2'}}</td>
                    <td>{{boat?.tuitions[10]?.value | number:'1.2-2'}}</td>
                    <td>{{boat?.tuitions[11]?.value | number:'1.2-2'}}</td>
                </tr>

            </ng-template>
        </p-table>
    </div>

    <div>
        <div class="inactive-header">
            <h1>Inativo</h1>
        </div>
        <p-table
        [value]="this.boatReport?.inactiveBoatTuitionsReports"
        [scrollable]="true"
        scrollHeight="400px"
        styleClass="easy-table"
        sortMode="multiple"
        >
            <ng-template pTemplate="header">
                <tr>

                    <th pSortableColumn="boatName">Embarcação <p-sortIcon field="boatName"></p-sortIcon></th>
                    <th pSortableColumn="logDate">Data Saída <p-sortIcon field="logDate"></p-sortIcon></th>
                    <th>Jan</th>
                    <th>Fev</th>
                    <th>Mar</th>
                    <th>Abr</th>
                    <th>Mai</th>
                    <th>Jun</th>
                    <th>Jul</th>
                    <th>Ago</th>
                    <th>Set</th>
                    <th>Out</th>
                    <th>Nov</th>
                    <th>Dez</th>
                </tr>
                <tr>
                    <th></th>
                    <th></th>
                    <th>{{this.inactiveTuitionSum[0] | number:'1.2-2'}}</th>
                    <th>{{this.inactiveTuitionSum[1] | number:'1.2-2'}}</th>
                    <th>{{this.inactiveTuitionSum[2] | number:'1.2-2'}}</th>
                    <th>{{this.inactiveTuitionSum[3] | number:'1.2-2'}}</th>
                    <th>{{this.inactiveTuitionSum[4] | number:'1.2-2'}}</th>
                    <th>{{this.inactiveTuitionSum[5] | number:'1.2-2'}}</th>
                    <th>{{this.inactiveTuitionSum[6] | number:'1.2-2'}}</th>
                    <th>{{this.inactiveTuitionSum[7] | number:'1.2-2'}}</th>
                    <th>{{this.inactiveTuitionSum[8] | number:'1.2-2'}}</th>
                    <th>{{this.inactiveTuitionSum[9] | number:'1.2-2'}}</th>
                    <th>{{this.inactiveTuitionSum[10] | number:'1.2-2'}}</th>
                    <th>{{this.inactiveTuitionSum[11] | number:'1.2-2'}}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-boat>
                <tr>
                    <td>{{boat.boatName}}</td>
                    <td>{{boat?.logDate | date:'dd/MM/yyyy'}}</td>
                    <td>{{boat?.tuitions[0]?.value | number:'1.2-2'}}</td>
                    <td>{{boat?.tuitions[1]?.value | number:'1.2-2'}}</td>
                    <td>{{boat?.tuitions[2]?.value | number:'1.2-2'}}</td>
                    <td>{{boat?.tuitions[3]?.value | number:'1.2-2'}}</td>
                    <td>{{boat?.tuitions[4]?.value | number:'1.2-2'}}</td>
                    <td>{{boat?.tuitions[5]?.value | number:'1.2-2'}}</td>
                    <td>{{boat?.tuitions[6]?.value | number:'1.2-2'}}</td>
                    <td>{{boat?.tuitions[7]?.value | number:'1.2-2'}}</td>
                    <td>{{boat?.tuitions[8]?.value | number:'1.2-2'}}</td>
                    <td>{{boat?.tuitions[9]?.value | number:'1.2-2'}}</td>
                    <td>{{boat?.tuitions[10]?.value | number:'1.2-2'}}</td>
                    <td>{{boat?.tuitions[11]?.value | number:'1.2-2'}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-fieldset>
