import { DeviceLogUse } from './../../../models/dtos/access-controller/deviceLogUse';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { CustomerService } from 'src/app/services/customer.service';
import Swal from 'sweetalert2';
import { AccessReportService } from 'src/app/services/access-controler/access-report.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { DialogService } from 'primeng/dynamicdialog';
import { CustomReportExportComponent } from 'src/app/components/extract-custom-report/custom-report-export.component';



@Component({
  selector: 'app-report-access-controler',
  templateUrl: './report-access-controler.component.html',
  styleUrls: ['./report-access-controler.component.scss']
})
export class ReportAccessControlerComponent implements OnInit {

  filteredCustomers: Array<SelectItem> = [];
  filter:any = {colectId: null, startDate:new Date().getDate() - 3, endDate: new Date(), direction: null, accessGroupId: null};
  report: DeviceLogUse[] = [];
  onlylocal:Boolean=false;
  group : SelectItem[] = [{ label: 'Todos', value: null }, { label: 'Proprietário/Dependente', value: '11111' },
    { label: 'Visitante/Prestador', value: '11112' }, { label: 'Marinheiro"', value: '11113' }, { label: 'Colaborador"', value: '11114' }];
  direction: SelectItem[] = [{ label: 'Entrada', value: 'IN' }, { label: 'Saída', value: 'OUT' }, { label: 'Todos', value: null }];

  constructor(
    private router: Router,
    private customerService: CustomerService,
    private accessReportService: AccessReportService,
    private messageUtil: MessageUtil,
    private dialog: DialogService,
    private messageService: MessageUtil,

  ) { }

  ngOnInit() {
    this.getCustomers();
    let startDate = new Date();
    startDate.setDate(startDate.getDate() - 7);
    this.filter.startDate = startDate;
  }

  find(){
    if (this.onlylocal){
      this.accessReportService.findLastDeviceUse().subscribe((data) => {
        this.report = data;
      }
      );
      return

    }
    if (this.filter.colectId == null) {
      this.filter.colectId = null;
    }
    if (this.filter.startDate == null) {
      Swal.fire('Atenção', 'Data inicial é obrigatória', 'warning');
      return;
    }
    if (this.filter.endDate == null) {
      Swal.fire('Atenção', 'Data final é obrigatória', 'warning');
      return;
    }

    if (this.filter.startDate > this.filter.endDate) {
      Swal.fire('Atenção', 'Data inicial não pode ser maior que a data final', 'warning');
      return;
    }

    this.accessReportService.accessReport(this.filter).subscribe((data) => {
      this.report = data;
    }
    );
  }

  clear(){}


  getCustomers(): void {
    this.customerService.getCustomerFilterList().subscribe((data) => {
      this.filteredCustomers = [{ label: 'Todos', value: null }].concat(data.map((c) => ({ label: c.name, value: c.colectId })));
    });
  }

  exportTable(): void {


        const data = { table: this.report, type: 'REPORT_ACCESS_CONTROLER' };
        this.dialog.open(CustomReportExportComponent, {
          width: '70%',
          height: '90vh',
          dismissableMask: false,
          data,
          header: this.messageUtil.translateKey('EXPORT-TAX-PRODUCT-TABLE'),
        });


  }
}
