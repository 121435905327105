import { SlingConfig } from './../../../../models/sling-config';
import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { ToastService } from 'src/app/services/toast.service';
import { MessageUtil } from 'src/app/utils/message.util';

@Component({
  selector: 'app-confirm-competency-dialog',
  templateUrl: './confirm-competency-dialog.component.html',
  styleUrls: ['./confirm-competency-dialog.component.scss'],
})
export class ConfirmCompetencyDialogComponent implements OnInit {
  slingConfig: SlingConfig;
  confirma: string = '';
  competencyDropdown: any[] = [
    { value: 0, label: 'Selecione Data de competência' },
    { value: 1, label: 'Data de emissâo da fatura' },
    { value: 2, label: 'Data de vencimento da fatura' },
    { value: 3, label: 'Data de competência da fatura' },
    { value: 4, label: 'Data de emissão da nota fiscal' },
  ];

  constructor(
    private config: DynamicDialogConfig,
    private slingConfigService: SlingConfigService,
    private messageUtil: MessageUtil,
    private spinner: NgxSpinnerService,
    private toasty: ToastService,
    public dialogRef: DynamicDialogRef
  ) {}

  // <option value=0></option>
  // <option value=1>Data de Emissão da Fatura</option>
  // <option value=2>Data de Competência da Fatura</option>
  // <option value=3>Data de Vencimento da Fatura</option>
  // <option value=4>Data de Emissão da Nota</option>

  ngOnInit(): void {
    this.slingConfig = this.config.data.conf;
  }

  save() {
    if (
      this.confirma.toUpperCase() == 'CONFIRMA' &&
      this.slingConfig.competency > 0
    ) {
      this.spinner.show();
      this.slingConfigService.save(this.slingConfig).subscribe(
        (data) => {
          this.toasty.success('Configurações salva com sucesso.');
          this.spinner.hide();
          this.dialogRef.close(this.slingConfig);
        },
        (error) => {
          this.spinner.hide();
          const exception = error.error.data.exception;
          this.messageUtil.generateMessage(
            exception.type,
            exception.title,
            exception.message
          );
        }
      );
    } else {
      this.toasty.error('Por favor, confirme a data de competência.');
    }
  }
}
