<div class="card">
    <p-table
        [value]="tuitions"
        [paginator]="true"
        [rows]="5"
        [tableStyle]="{ 'min-width': '50rem' }"
        [rowsPerPageOptions]="[5, 10, 20]"
    >
        <ng-template pTemplate="header">
            <tr>
                <th style="width:25%">Cliente</th>
                <th style="width:25%">Embarcação</th>
                <th style="width:25%">Serviço</th>
                <th style="width:25%">Vencimento</th>
                <th style="width:25%">Valor</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-tuitions>
            <tr>
                <td>{{ tuitions.client }}</td>
                <td>{{ tuitions.boat }}</td>
                <td>{{ tuitions.service }}</td>
                <td>{{ tuitions.expirateDate | date:'dd/MM/yyyy' }}</td>
                <td>{{ tuitions.value | currency:'BRL':'symbol':'1.2-2' }}</td>
            </tr>
        </ng-template>
    </p-table>
</div>