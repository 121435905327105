import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReadjustmentProductRegisterDTO } from 'src/app/models/dtos/readjustment-product-registerDTO';
import { ServiceReadjustmentSimulationDTO } from 'src/app/models/dtos/service-readjustment-simulationDTO';
import { ServiceBoatReadjustmentDTO } from 'src/app/models/dtos/service-boat-readjustmentDTO';
import { ReadjustmentType } from 'src/app/models/enums/readjustment-type';
import { ProductService } from 'src/app/services/product.service';
import { ServiceBoatService } from 'src/app/services/service-boat.service';
import { ToastService } from 'src/app/services/toast.service';
import { DeepCloneUtil } from 'src/app/utils/deep-clone.util';
import { FormatUtil } from 'src/app/utils/format.util';
import { MessageUtil } from 'src/app/utils/message.util';
import Swal from 'sweetalert2';
import { ServiceCustomerService } from '../../../../services/service-customer.service';
import { ProductType } from '../../../../models/enums';
import { ServiceCustomerReadjustmentDTO } from '../../../../models/dtos/service-customer-readjustmentDTO';
import { servicesReadjustmentRegisterDTO } from '../../../../models/dtos/services-readjustment-registerDTO';

@Component({
  selector: 'app-list-services',
  templateUrl: './list-services.component.html',
  styleUrls: ['./list-services.component.scss'],
})
export class ListServicesComponent implements OnInit {
  @Output() exportList = new EventEmitter<any[]>();

  filteredServicesInUse: any[] = [];

  service;

  enableReadjustmentPercentages: boolean;
  isValidPercentages: boolean;
  readjustmentTypes: { label: string; value: string }[] = [];
  adjustmentForm: UntypedFormGroup;

  serviceBoatReadjustment: ServiceBoatReadjustmentDTO[];
  serviceCustomerReadjustment: ServiceCustomerReadjustmentDTO[];

  constructor(
    private productService: ProductService,
    private serviceBoatService: ServiceBoatService,
    private serviceCustomerService: ServiceCustomerService,
    private spinner: NgxSpinnerService,
    private toasty: ToastService,
    private messageUtil: MessageUtil
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    this.startForm();
    this.getTypes();
    this.getPeriodicalServiceInUse();
    this.spinner.hide();
  }

  startForm() {
    this.adjustmentForm = new UntypedFormGroup({
      service: new UntypedFormControl(
        null,
        Validators.compose([Validators.required])
      ),
      percentage: new UntypedFormControl(
        null,
        Validators.compose([Validators.required])
      ),
      readjustmentType: new UntypedFormControl(
        null,
        Validators.compose([Validators.required])
      ),
    });
  }

  getTypes() {
    this.readjustmentTypes = Object.keys(ReadjustmentType).map((item) => ({
      label: item,
      value: item,
    }));
  }

  getPeriodicalServiceInUse(): void {
    this.productService
      .getPeriodicalServiceNotPriceFreeInUse()
      .subscribe((data) => {
        this.filteredServicesInUse = data.map((serviceInUse) => ({
          label: serviceInUse.name,
          value: serviceInUse,
        }));
      });
  }

  viewReadjustment() {
    if (this.service.pricingType === 'SimplePrice') {
      this.service.price.readjustmentValue =
        this.service.price.value +
        FormatUtil.roundTwoDecimal(
          this.service.price.value *
            (this.adjustmentForm.get('percentage').value / 100)
        );
    } else {
      this.service.price.priceRanges.filter((price) => {
        const addition = FormatUtil.roundTwoDecimal(
          price.value * (this.adjustmentForm.get('percentage').value / 100)
        );
        price.readjustmentValue = FormatUtil.roundTwoDecimal(
          price.value + addition
        );
      });
    }

    this.enableReadjustmentPercentages = true;
  }

  calcPorcentage(price): number {
    if (!price.readjustmentValue) {
      return;
    }
    return (price.readjustmentValue - price.value) / price.value;
  }

  changeService() {
    this.service = DeepCloneUtil.clone(
      this.adjustmentForm.get('service').value
    );
    this.service.price.priceRanges = this.sortPriceRanges();
    this.enableReadjustmentPercentages = false;
  }

  changeIsValidPercentages(event: boolean) {
    this.isValidPercentages = event;
  }

  sortPriceRanges(): any[] {
    return this.service.price.priceRanges.sort((a, b) => {
      if (a.startLengthRange > b.startLengthRange) {
        return 1;
      } else if (a.startLengthRange < b.startLengthRange) {
        return -1;
      }
      return 0;
    });
  }

  enableReadjustmentPriceRanges(): boolean {
    return (
      this.service?.pricingType !== 'SimplePrice' &&
      this.service?.price?.priceRanges
    );
  }

  enableReadjustmentPriceSimple(): boolean {
    return (
      this.service?.pricingType === 'SimplePrice' && this.service?.price?.value
    );
  }

  cleanAdjustments(newPercentage?) {
    this.serviceBoatReadjustment = null;
    this.serviceCustomerReadjustment = null;
    if (!!newPercentage && newPercentage > 0) {
      this.adjustmentForm
        .get('percentage')
        .setValue(FormatUtil.roundTwoDecimal(newPercentage));
    }
  }

  applyReadjustmentInServicesList() {
    const price = this.getPriceReadjustment();
    const percentageRange = this.getPercentageRange();
    const simulatePriceReadjustment: ServiceReadjustmentSimulationDTO = {
      serviceId: this.service.id,
      pricingType: this.service.pricingType,
      price,
      percentageRange,
      percentage: this.adjustmentForm.get('percentage').value,
      readjustmentType: this.adjustmentForm.get('readjustmentType').value,
    };
    if (this.service.productType === ProductType.PeriodicalServiceCustomer) {
      this.serviceCustomerService
        .getSimulationServicesReadjustmentByServiceCustomer(
          simulatePriceReadjustment
        )
        .subscribe({
          next: (data) => {
            this.serviceBoatReadjustment = [];
            this.serviceCustomerReadjustment = data;
            this.exportList.emit(this.serviceCustomerReadjustment);
          },
          error: (error) => {
            const exception = error.error.data.exception;
            this.messageUtil.generateMessage(
              exception.type,
              exception.title,
              exception.message
            );
          },
        });
    } else {
      this.serviceBoatService
        .getSimulationServicesReadjustment(simulatePriceReadjustment)
        .subscribe({
          next: (data) => {
            this.serviceCustomerReadjustment = [];
            this.serviceBoatReadjustment = data;
            this.exportList.emit(this.serviceBoatReadjustment);
          },
          error: (error) => {
            const exception = error.error.data.exception;
            this.messageUtil.generateMessage(
              exception.type,
              exception.title,
              exception.message
            );
          },
        });
    }
  }

  exportListMethod(exportList: any[]) {
    this.exportList.emit(exportList);
  }

  enableApplyReadjustmentInServicesList() {
    return (
      this.isValidPercentages &&
      (this.service?.price?.readjustmentValue ||
        (this.service?.price?.priceRanges &&
          this.service.price.priceRanges.length > 0 &&
          this.service.price.priceRanges[0].readjustmentValue))
    );
  }

  private getPriceReadjustment() {
    let price = DeepCloneUtil.clone(this.service.price);
    if (this.service?.pricingType === 'SimplePrice') {
      price.value = price.readjustmentValue;
    } else {
      price.priceRanges.forEach((priceRange) => {
        priceRange.value = priceRange.readjustmentValue;
      });
    }
    return price;
  }

  private getPercentageRange() {
    if (this.service?.pricingType !== 'SimplePrice') {
      return this.service.price.priceRanges.map((priceRange) => ({
        startLengthRange: priceRange.startLengthRange,
        endLengthRange: priceRange.endLengthRange,
        percentage: this.calcPorcentage(priceRange) * 100,
      }));
    }
    return;
  }

  applyReadjustment(
    servicesReadjustmentReady:
      | ServiceBoatReadjustmentDTO[]
      | ServiceCustomerReadjustmentDTO[]
  ) {
    Swal.fire({
      title: 'Registrar reajuste',
      text:
        'Desejas realizar o reajuste de ' +
        servicesReadjustmentReady.length +
        ' mensalidade' +
        (servicesReadjustmentReady.length > 1 ? 's' : '') +
        '?',
      icon: 'warning',
      reverseButtons: true,
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    }).then((response) => {
      if (response.value) {
        this.saveReadjustment(servicesReadjustmentReady);
      }
    });
  }

  cleanAfterSave() {
    this.service = undefined;
    this.serviceBoatReadjustment = undefined;
    this.serviceCustomerReadjustment = undefined;
    this.startForm();
    this.getPeriodicalServiceInUse();
  }

  private saveReadjustment(servicesReadjustmentReady: any) {
    const readjustmentProductRegisterDTO: ReadjustmentProductRegisterDTO = {
      percentage: this.adjustmentForm.get('percentage').value,
      readjustmentType: this.adjustmentForm.get('readjustmentType').value,
      productId: this.service.id,
    };
    const price = this.getPriceReadjustment();
    const firstServiceReadjustment = servicesReadjustmentReady[0];
    if (firstServiceReadjustment.serviceCustomerId !== undefined) {
      this.saveReadjustByServiceCustomer(
        readjustmentProductRegisterDTO,
        servicesReadjustmentReady as ServiceCustomerReadjustmentDTO[],
        price
      );
    } else {
      this.saveServiceBoatReadjustment(
        readjustmentProductRegisterDTO,
        servicesReadjustmentReady as ServiceBoatReadjustmentDTO[],
        price
      );
    }
  }

  private saveServiceBoatReadjustment(
    readjustmentProductRegisterDTO: ReadjustmentProductRegisterDTO,
    servicesReadjustmentReady: ServiceBoatReadjustmentDTO[],
    price
  ) {
    const serviceBoatReadjustment: servicesReadjustmentRegisterDTO = {
      readjustmentProductRegisterDTO,
      serviceBoatReadjustmentDTOs: servicesReadjustmentReady,
      serviceCustomerReadjustmentDTOs: null,
      price,
    };

    this.spinner.show();
    this.serviceBoatService
      .registerServicesReadjustment(serviceBoatReadjustment)
      .subscribe({
        next: () => {
          this.cleanAfterSave();
          this.toasty.success('Reajuste realizado com sucesso');
          this.spinner.hide();
        },
        error: (error) => {
          const exception = error.error.data.exception;
          this.messageUtil.generateMessage(
            exception.type,
            exception.title,
            exception.message
          );
          this.spinner.hide();
        },
      });
  }

  private saveReadjustByServiceCustomer(
    readjustmentProductRegisterDTO: ReadjustmentProductRegisterDTO,
    servicesReadjustmentReady: ServiceCustomerReadjustmentDTO[],
    price: any
  ) {
    const serviceCustomerReadjustment: servicesReadjustmentRegisterDTO = {
      readjustmentProductRegisterDTO,
      serviceCustomerReadjustmentDTOs: servicesReadjustmentReady,
      serviceBoatReadjustmentDTOs: null,
      price,
    };

    this.spinner.show();
    this.serviceCustomerService
      .registerServicesReadjustment(serviceCustomerReadjustment)
      .subscribe({
        next: () => {
          this.cleanAfterSave();
          this.toasty.success('Reajuste realizado com sucesso');
          this.spinner.hide();
        },
        error: (error) => {
          const exception = error.error.data.exception;
          this.messageUtil.generateMessage(
            exception.type,
            exception.title,
            exception.message
          );
          this.spinner.hide();
        },
      });
  }
}
