import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { DeviceLogUse } from '../../models/dtos/access-controller/deviceLogUse';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccessReportService {

  env: string = environment.apiHost;
  constructor(private http: HttpClient) {}

  accessReport(filter:any): Observable<DeviceLogUse[]> {

      return this.http
      .post(this.env + '/api-log/secure/device-log-use/find-by-report', filter)
      .pipe(map((response: any) => response as DeviceLogUse[]));

  }

  findLastDeviceUse(): Observable<DeviceLogUse[]> {

    return this.http
    .get(this.env + '/api-log/secure/device-log-use/find-position')
    .pipe(map((response: any) => response as DeviceLogUse[]));

}

}
