<div class="dialog-form">
    <form (keydown.enter)="$event.preventDefault()" (ngSubmit)="save(slingConfigForm)" #slingConfigForm="ngForm"
        novalidate *ngIf="slingConfig">
        <p-accordion>
            <p-accordionTab *ngIf="isSlingMarina" header="{{'Abastecimento' | translate}}">
                <div class="form-group">
                    <div class="form-control" tooltipPosition="bottom" pTooltip="Habilita o pedido de abastecimento">
                        <label for="hasFuelSupply">{{ 'HAS-FUEL-SUPPLY' | translate }}</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.hasFuelSupply" name="hasFuelSupply"
                            (click)="slingConfig.hasFractionalSupply = false"></p-inputSwitch>
                    </div>
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Se não habilitado, permite apenas tanque cheio">
                        <label for="hasFractionalSupply">{{ 'Tem abastecimento por fração' | translate }}</label>
                        <p-inputSwitch [disabled]="!slingConfig.hasFuelSupply"
                            [(ngModel)]="slingConfig.hasFractionalSupply" name="hasFractionalSupply"
                            (onChange)="this.fuelTankFractions = []; this.slingConfig.fuelTankFractions = '';">
                        </p-inputSwitch>
                    </div>
                    <div class="form-control radio-buttons" tooltipPosition="bottom"
                        pTooltip="Frações disponíveis para pedido" *ngIf="slingConfig.hasFractionalSupply">
                        <label for="groupFuelTankFractions">{{ 'Opções de fração' | translate }}</label>
                        <div class="form-group">
                            <p-checkbox name="groupFuelTankFractions" value="Quarter" label="1/4"
                                [(ngModel)]="fuelTankFractions"></p-checkbox>
                            <p-checkbox name="groupFuelTankFractions" value="Half" label="1/2"
                                [(ngModel)]="fuelTankFractions"></p-checkbox>
                            <p-checkbox name="groupFuelTankFractions" value="Third" label="3/4"
                                [(ngModel)]="fuelTankFractions"></p-checkbox>
                            <p-checkbox name="groupFuelTankFractions" value="Full" label="Tanque Cheio"
                                [(ngModel)]="fuelTankFractions"></p-checkbox>
                        </div>
                    </div>
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Se habilitado, permite o abastecimento por medida (litros)">
                        <label for="measuredFuel">Abastecimento por medida</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.measuredFuel" name="measuredFuel"></p-inputSwitch>
                    </div>
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Permite que o cliente forneça seu próprio combustível">
                        <label for="ownFuelSupply">Abastecimento próprio</label>
                        <p-inputSwitch [disabled]="!slingConfig.hasFuelSupply" [(ngModel)]="slingConfig.ownFuelSupply"
                            name="ownFuelSupply">
                        </p-inputSwitch>
                    </div>
                </div>
            </p-accordionTab>
            <p-accordionTab header="{{'App' | translate}}">
                <div class="form-group">
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Exibe o histórico de giro e lavação dos barcos no app Cliente">
                        <label for="engineWashHistory">{{ 'ENGINE-WASH-HISTORY' | translate }}</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.engineWashHistory" name="engineWashHistory">
                        </p-inputSwitch>
                    </div>
                    <div class="form-control" tooltipPosition="bottom" pTooltip="Exibir faturas antigas no app Cliente">
                        <label for="showHistoryInvoiceApp">{{ 'Exibir Histórico de fatura' | translate }}</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.showHistoryInvoiceApp" name="showHistoryInvoiceApp">
                        </p-inputSwitch>
                    </div>
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Divulga propaganda do app Cliente no rodapé do painel">
                        <label for="advertiseApp">{{ 'Divulga APP' | translate }}</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.advertiseApp" name="advertiseApp">
                        </p-inputSwitch>
                    </div>
                    <div *ngIf="isSlingMarina; then isSlingMarina_App; else isMovements"></div>
                    <ng-template #isSlingMarina_App>
                        <div class="form-control"
                            [title]="slingConfig.hasConsumables ? 'Permite que o cliente solicite produtos consumíveis pelo App' : 'Bloquado pois a marina não possui consumíveis'">
                            <label for="consumablesApp">{{ 'Solicitar consumíveis' | translate }}</label>
                            <p-inputSwitch
                                [disabled]="!slingConfig.hasConsumables || !canEnableConsumablesFinancialMultipleCompanies()"
                                [(ngModel)]="slingConfig.consumablesApp" name="consumablesApp">
                            </p-inputSwitch>
                        </div>
                        <div class="form-control" tooltipPosition="bottom"
                            pTooltip="Cliente poderá solicitar entrar na fila de subida"
                            *ngIf="slingConfig.queueControl">
                            <label>Solicitar subida</label>
                            <br>
                            <p-inputSwitch [(ngModel)]="slingConfig.requestPendingUpApp" name="requestPendingUpApp"
                                (click)="slingConfig.queuePositionControl = false;">
                            </p-inputSwitch>
                        </div>
                        <div class="form-control" tooltipPosition="bottom"
                            pTooltip="Informar ao cliente a posição da embarcação na fila de subida ao requisitar"
                            *ngIf="slingConfig.queueControl && slingConfig.requestPendingUpApp">
                            <label>Exibir posição na fila</label>
                            <br>
                            <p-inputSwitch [(ngModel)]="slingConfig.queuePositionControl" name="queuePositionControl">
                            </p-inputSwitch>
                        </div>
                    </ng-template>
                    <ng-template #isMovements>
                        <div class="form-control" tooltipPosition="bottom"
                            pTooltip="Exija do cliente ou marinheiro uma distância mínima para o preparo da embarcação">
                            <label>Exigir range de preparo</label>
                            <br>
                            <p-inputSwitch [(ngModel)]="slingConfig.preparationControl" name="queuePositionControl">
                            </p-inputSwitch>
                        </div>
                    </ng-template>
                </div>
                <div class="form-group">
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Ao selecionar uma data, o histórico de giro de motor e lavação só exibirá resultados à partir da data definida.">
                        <label>Data Início de Giro de Motor e Lavação</label>
                        <p-calendar dateFormat="dd/mm/yy" name="historyBeginDate"
                            [(ngModel)]="slingConfig.historyBeginDate" [showIcon]="true" i18n appendTo="body"
                            [monthNavigator]="true" [yearNavigator]="true" [yearRange]="'2017:2055'" rPCalendarMask>
                        </p-calendar>
                    </div>
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Ao selecionar uma data, o histórico das faturas só exibirá resultados à partir da data definida.">
                        <label>Data Início das Faturas</label>
                        <p-calendar dateFormat="dd/mm/yy" name="invoiceBeginDate" appendTo="body"
                            [(ngModel)]="slingConfig.invoiceBeginDate" [showIcon]="true" i18n [monthNavigator]="true"
                            [yearNavigator]="true" [yearRange]="'2017:2055'" rPCalendarMask>
                        </p-calendar>
                    </div>
                    <div class="form-control"
                        [ngClass]="{'danger': ( (slingConfig.amountSkipAvaiablePeriods === null || slingConfig.amountSkipAvaiablePeriods === undefined) || !(slingConfig.amountSkipAvaiablePeriods >= 0))}"
                        tooltipPosition="bottom"
                        pTooltip="Atraso de tempo para o próximo período disponível para lingada (em minutos)">
                        <label>Delay de tempo nos horários (min)</label>
                        <input pInputText type="number" name="amountSkipAvaiablePeriods"
                            [(ngModel)]="slingConfig.amountSkipAvaiablePeriods" min="0" required />
                    </div>
                </div>
                <div class="form-group input-destination" tooltipPosition="bottom"
                    pTooltip="Destinos frequentes disponíveis para o plano de navegação">
                    <div class="form-control">
                        <label for="frequentDestination">Destinos frequentes no App</label>
                        <input pInputText type="text" placeholder="Digite o destino" #frequentDestination
                            name="frequentDestination">
                    </div>
                    <button pButton type="button" icon="pi pi-plus"
                        (click)="addFrequentDestination(frequentDestination)"
                        [disabled]="frequentDestination.value == ''"></button>
                </div>
                <div class="right-button form-group">
                    <span class="badge badge-pill badge-primary"
                        *ngFor="let frequentDestination of slingConfig.frequentFestinations">
                        {{ frequentDestination.name }}
                        <i class="pi pi-times" (click)="removeFrequentDestination(frequentDestination)">
                        </i>
                    </span>
                </div>

                <div class="d-flex flex-row col-12">
                    <div class="col-6">
                        <app-range-map [radiusOfGyration]="slingConfig.radiusOfGyration" [tipo]="'SLINGUP'"
                            [mapNumber]="'1'" (newRadiusEvent)="slingConfig.radiusOfGyration=$event">
                        </app-range-map>
                    </div>
                    <div *ngIf="slingConfig.preparationControl" class="col-6">
                        <app-range-map [radiusOfGyration]="slingConfig.radiusOfPrepGyration" [tipo]="'PREPARATION'"
                            [mapNumber]="'2'" (newRadiusEvent)="slingConfig.radiusOfPrepGyration=$event">
                        </app-range-map>
                    </div>

                </div>
            </p-accordionTab>
            <p-accordionTab header="Financeiro">
                <div class="form-group finances">
                    <div class="form-control">
                        <label *ngIf="marinaCompanies && marinaCompanies.length > 0" for="paymentMethodId">
                            Forma de pagamento padrão ({{currentMarina.companyName}})
                        </label>
                        <label *ngIf="!marinaCompanies || marinaCompanies.length <= 0" for="paymentMethodId">
                            Forma de pagamento padrão
                        </label>
                        <p-dropdown placeholder="Selecione..." [filter]="true" [options]="paymentMethodsDropdown"
                            appendTo="body" [(ngModel)]="slingConfig.paymentMethodId" required name="paymentMethodId"
                            (onChange)="selectedPaymentMethods($event.value)">
                        </p-dropdown>
                    </div>
                    <div class="form-control">
                        <label *ngIf="marinaCompanies && marinaCompanies.length > 0" for="accountId">
                            Conta padrão ({{currentMarina.companyName}})
                        </label>
                        <label *ngIf="!marinaCompanies || marinaCompanies.length <= 0">Conta padrão</label>
                        <p-dropdown placeholder="Selecione..." [filter]="true" [options]="accountDropdown"
                            appendTo="body" [(ngModel)]="slingConfig.accountId" required name="accountId">
                        </p-dropdown>
                    </div>
                </div>
                <div class="form-group finances" *ngFor="let company of marinaCompanies; let i = index">
                    <div class="form-control" [ngClass]="{'danger': !(marinaCompanies[i].paymentMethodId)}"
                        [title]="'Forma de pagamento padrão da empresa ' + company.companyFederalName">
                        <label [for]="'companyPaymentMethodId' + i">
                            Forma de pagamento padrão ({{company.companyFederalName}})
                        </label>
                        <p-dropdown placeholder="Selecione..." [filter]="true"
                            [options]="company.paymentMethodsDropdown" [(ngModel)]="marinaCompanies[i].paymentMethodId"
                            appendTo="body" [name]="'companyPaymentMethodId' + i"
                            (onChange)="selectedCompanyPaymentMethods($event.value)">
                        </p-dropdown>
                    </div>
                    <div class="form-control" [ngClass]="{'danger': !(marinaCompanies[i].accountId)}"
                        [title]="'Conta padrão para pagamento da empresa ' + company.companyFederalName">
                        <label [for]="'companyAccountId' + i">Conta padrão ({{company.companyFederalName}})</label>
                        <p-dropdown *ngIf="company.accountDropdown?.length > 0" placeholder="Selecione..."
                            appendTo="body" [filter]="true" [options]="company.accountDropdown"
                            [(ngModel)]="company.accountId" [name]="'companyAccountId' + i">
                        </p-dropdown>
                    </div>
                </div>
                <div class="form-group finances">
                    <div class="form-control" [ngClass]="{'danger': !slingConfig.idPlanoContaInterest}"
                        tooltipPosition="bottom" pTooltip="Plano de conta padrão para Juros/Multa de boletos">
                        <label for="idPlanoContaInterest">Plano de conta para Juros/Multa</label>
                        <p-dropdown placeholder="Selecione..." [filter]="true" [options]="accountPlansDropdown"
                            appendTo="body" [(ngModel)]="slingConfig.idPlanoContaInterest" [required]="true" min="1"
                            name="idPlanoContaInterest">
                        </p-dropdown>
                    </div>

                    <div class="form-control" [ngClass]="{'danger': !slingConfig.competencyJuros}"
                        tooltipPosition="bottom" pTooltip="Data de competência padrão para Juros/Multa de boletos">
                        <label for="competencyJuros">Data de competência para Juros/Multa</label>
                        <p-dropdown placeholder="Selecione..." [options]="competencyJurosDropdown" optionLabel="label"
                            optionValue="value" appendTo="body" [(ngModel)]="slingConfig.competencyJuros"
                            [required]="true" min="0" name="competencyJuros">
                        </p-dropdown>
                    </div>

                    <div class="form-control" [ngClass]="{'danger': !slingConfig.idPlanoContaDiscount}"
                        tooltipPosition="bottom" pTooltip="Plano de conta padrão para Desconto de boletos">
                        <label for="idPlanoContaDiscount">Plano de conta para Desconto</label>
                        <p-dropdown placeholder="Selecione..." [filter]="true" [options]="accountPlansDropdown"
                            appendTo="body" [(ngModel)]="slingConfig.idPlanoContaDiscount" [required]="true" min="1"
                            name="idPlanoContaDiscount">
                        </p-dropdown>
                    </div>
                </div>
                <div class="form-group finances">
                    <div class="form-control" [ngClass]="{'danger': slingConfig.cityTax >100 ? true :false}"
                        tooltipPosition="bottom" pTooltip="Alíquota municipal ISS (%)">
                        <label for="cityTax">Alíquota municipal ISS (%) ({{currentMarina.companyName}})</label>
                        <p-inputNumber mode="decimal" name="cityTax" [(ngModel)]="slingConfig.cityTax"
                            [minFractionDigits]="2" [maxFractionDigits]="2" suffix="%" min="0.00" max="100.00">
                        </p-inputNumber>
                    </div>
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Para prefeituras que exigem o campo Informações complementares, habilitar para informar o valor do mesmo">
                        <label for="additionalInfo">Informações Complementares</label>
                        <div class="flex justify-content-between mt-2">
                            <p-inputSwitch class="ml-5" name="additionalInfo"
                                [(ngModel)]="slingConfig.additionalInfo"></p-inputSwitch>
                            <p-inputNumber class="mr-5" *ngIf="slingConfig.additionalInfo" mode="decimal"
                                name="additionalInfoValue" [(ngModel)]="slingConfig.additionalInfoValue"
                                [minFractionDigits]="2" [maxFractionDigits]="2" suffix="%" [min]="0.00"
                                [max]="100.00"></p-inputNumber>
                        </div>
                    </div>
                    <div class="form-control" tooltipPosition="bottom" pTooltip="Possibilita gerar recibos de faturas">
                        <label for="generateInvoiceReceipt">Habilitar recibo de serviço</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.generateInvoiceReceipt" name="generateInvoiceReceipt">
                        </p-inputSwitch>
                    </div>
                </div>
                <div class="form-group fincances">
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Natureza da Operação. Verifique com o município a necessidade de utilizar.">
                        <label for="operationNature">Natureza da Operação</label>
                        <input pInputText type="text" name="operationNature"
                            [(ngModel)]="slingConfig.operationNature" />
                    </div>
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Quando a fatura esta paga adiciona o valor dos juros a nota fiscal de serviço">
                        <label for="generateInvoiceReceipt">Habilitar adição de juros a Nota</label>
                        <p-inputSwitch class="ml-5" name="interestInTaxnote"
                            [(ngModel)]="slingConfig.interestInTaxnote"></p-inputSwitch>
                    </div>
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Data de competência padrão para Desconto">
                        <label for="competencyDiscount">Data de competência para Desconto</label>
                        <p-dropdown placeholder="Selecione..." [options]="competencyDiscountDropdown"
                            optionLabel="label" optionValue="value" appendTo="body"
                            [(ngModel)]="slingConfig.competencyDiscount" min="0" name="competencyDiscount">
                        </p-dropdown>
                    </div>
                </div>
                <div class="form-group finances" *ngFor="let company of marinaCompanies; let i = index">
                    <div class="form-control" [ngClass]="{'danger': marinaCompanies[i].cityTax >100 ? true :false}"
                        [title]="'Alíquota municipal ISS da empresa ' + company.companyFederalName">
                        <label [for]="'company.cityTax' + i">
                            Alíquota municipal ISS (%) ({{company.companyFederalName}})
                        </label>
                        <p-inputNumber mode="decimal" [name]="'company.cityTax' + i"
                            [(ngModel)]="marinaCompanies[i].cityTax" [minFractionDigits]="2" [maxFractionDigits]="2"
                            suffix="%" min="0.00" max="100.00">
                        </p-inputNumber>
                    </div>
                </div>
                <br>
                <div class="form-group input-user-totem">
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Usuários com permissão de acesso a tela de débitos no totem. Usuários que não tiverem acesso a tela de débitos verão a tela de Abastecimento.">
                        <label for="selectedUserConfig">Usuários com permissão para débitos no totem</label>
                        <p-dropdown placeholder="Selecione..." [options]="usersFilter" name="selectedUserConfig"
                            appendTo="body" [(ngModel)]="selectUser">
                        </p-dropdown>
                    </div>
                    <button pButton type="button" icon="pi pi-plus" (click)="addselectUser()"
                        [disabled]="!selectUser"></button>
                    <div class="right-button form-group">
                        <span class="badge badge-pill badge-primary" *ngFor="let user of slingConfig.users">
                            {{ user.firstName }}<i class="pi pi-times" (click)="removeUser(user)"></i>
                        </span>
                    </div>
                </div>
                <br>
                <div class="form-group">
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Obriga a definição da competência ao gerar uma fatura">
                        <label for="dynamicCompetenceDate">Habilitar competência dinâmica</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.dynamicCompetenceDate" name="dynamicCompetenceDate">
                        </p-inputSwitch>
                    </div>
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Habilita o botão para efetuar desconto diretamente na fatura.">
                        <label for="discountDirect">Habilitar desconto direto na fatura</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.discountDirect" name="discountDirect">
                        </p-inputSwitch>
                    </div>
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Permite que o sistema financeiro e faturamento realizem a antecipação de títulos">
                        <label for="anticipation">Habilitar Antecipação de títulos</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.anticipation" name="anticipation"
                            (onChange)="clearAnticipationFields()">
                        </p-inputSwitch>
                    </div>
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Adiciona o desconto antes do vencimento ao valor da nota fiscal.">
                        <label for="discountInInvoiceDoc">Habilitar desconto antes do vencimento a nota</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.discountInInvoiceDoc" name="discountInInvoiceDoc">
                        </p-inputSwitch>
                    </div>
                </div>

                <ng-container *ngIf="slingConfig.anticipation">
                    <div class="form-group finances">
                        <div class="form-control" tooltipPosition="bottom"
                            pTooltip="Plano de contas onde será lançado o juros da antecipação de títulos">
                            <label for="anticipationTaxAccountPlan">Plano de contas dos Juros da Antecipação</label>
                            <p-dropdown placeholder="Selecione..." [options]="accountPlansDropdown" appendTo="body"
                                [ngClass]="{'danger': !slingConfig.anticipationTaxAccountPlan}"
                                name="anticipationTaxAccountPlan" [(ngModel)]="slingConfig.anticipationTaxAccountPlan"
                                required filter="true">
                            </p-dropdown>
                        </div>
                        <div class="form-control" tooltipPosition="bottom"
                            pTooltip="Plano de contas onde será lançado o IOF da antecipação de títulos">
                            <label for="anticipationIpiAccountPlan">Plano de contas do IOF da Antecipação</label>
                            <p-dropdown placeholder="Selecione..." [options]="accountPlansDropdown" appendTo="body"
                                [ngClass]="{'danger': !slingConfig.anticipationIpiAccountPlan }"
                                name="anticipationIpiAccountPlan" [(ngModel)]="slingConfig.anticipationIpiAccountPlan"
                                required filter="true">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="form-group finances">
                        <div class="form-control" tooltipPosition="bottom"
                            pTooltip="Plano de contas onde será lançado o crédito da antecipação de títulos">
                            <label for="anticipationCreditAccountPlan">Plano de contas do Crédito da Antecipação</label>
                            <p-dropdown placeholder="Selecione..." [options]="accountPlansDropdown" appendTo="body"
                                [ngClass]="{'danger': !slingConfig.anticipationCreditAccountPlan}"
                                name="anticipationCreditAccountPlan"
                                [(ngModel)]="slingConfig.anticipationCreditAccountPlan" required filter="true">
                            </p-dropdown>
                        </div>
                        <div class="form-control" tooltipPosition="bottom"
                            pTooltip="Plano de contas onde será lançada a programação negativa das faturas antecipadas">
                            <label for="anticipationDebitAccountPlan">Plano de contas dos Débitos da Antecipação</label>
                            <p-dropdown placeholder="Selecione..." [options]="accountPlansDropdown" appendTo="body"
                                [ngClass]="{'danger': !slingConfig.anticipationDebitAccountPlan}"
                                name="anticipationDebitAccountPlan"
                                [(ngModel)]="slingConfig.anticipationDebitAccountPlan" required filter="true">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="form-group finances">
                        <div class="form-control" tooltipPosition="bottom"
                            pTooltip="Plano de contas onde será lançado a recompra da antecipação de títulos">
                            <label for="anticipationRepurchaseAccountPlan">
                                Plano de contas da Recompra da Antecipação
                            </label>
                            <p-dropdown placeholder="Selecione..." [options]="accountPlansDropdown" appendTo="body"
                                [ngClass]="{'danger': !slingConfig.anticipationRepurchaseAccountPlan}"
                                name="anticipationRepurchaseAccountPlan" required filter="true"
                                [(ngModel)]="slingConfig.anticipationRepurchaseAccountPlan">
                            </p-dropdown>
                        </div>
                    </div>
                </ng-container>
                <div class="form-group finances">
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Competência padrão para geração de notas fiscais">
                        <label for="competency">
                            Competência padrão para geração de notas fiscais
                        </label>
                        <p-dropdown placeholder="Selecione..." [options]="competencyDropdown" appendTo="body"
                            name="competency" required filter="true" [(ngModel)]="slingConfig.competency">
                        </p-dropdown>
                    </div>
                </div>
                <app-financial-config [slingConfig]="slingConfig" [userHierarchy]="userHierarchy">
                </app-financial-config>
                <br>
                <p-fieldset *ngIf="productNF">
                    <p-header class="article-title">
                        <div class="article-title">{{'NFE' | translate}}</div>
                    </p-header>
                    <div class="form-group finances">
                        <div class="form-control" tooltipPosition="bottom"
                            pTooltip="Define a situação tributária para o cálculo do cofins">
                            <label for="cst">
                                Situação Tributária do Cofins
                            </label>
                            <p-dropdown placeholder="Selecione..." [options]="cstDropdown" appendTo="body" name="cst"
                                filter="true" [(ngModel)]="slingConfig.cst">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="form-group finances">
                        <div class="form-control" tooltipPosition="bottom"
                            pTooltip="Alícota padrão para o cálculo do cofins">
                            <label for="Cofins">
                                Situação Tributária do Cofins
                            </label>
                            <p-inputNumber mode="decimal" [min]="0" [max]="100" [step]="0.01" [showButtons]="true"
                                [decimalSeparator]="','" [thousandSeparator]="'.'" [suffix]="'%'"
                                [ngModelOptions]="{standalone: true}" [minFractionDigits]="2" [maxFractionDigits]="2"
                                [(ngModel)]="slingConfig.cofins" name="cofins">
                            </p-inputNumber>
                        </div>
                        <div class="form-control" tooltipPosition="bottom"
                            pTooltip="Alícota padrão para o cálculo do Pis">
                            <label for="Pis">
                                Situação Tributária do Pis
                            </label>
                            <p-inputNumber mode="decimal" [min]="0" [max]="100" [step]="0.01" [showButtons]="true"
                                [decimalSeparator]="','" [thousandSeparator]="'.'" [suffix]="'%'"
                                [ngModelOptions]="{standalone: true}" [minFractionDigits]="2" [maxFractionDigits]="2"
                                [(ngModel)]="slingConfig.pis" name="Pis">
                            </p-inputNumber>
                        </div>
                    </div>

                </p-fieldset>
            </p-accordionTab>
            <p-accordionTab header="Giro de Motor">
                <div class="form-group">
                    <div class="form-control" tooltipPosition="bottom" pTooltip="Marina Realiza Giro de motor?">
                        <label for="marineEngineTurnRun">Giro Realizado pelo Marina</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.marineEngineTurnRun" name="marineEngineTurnRun">
                        </p-inputSwitch>
                    </div>
                    <div class="form-control"
                        [ngClass]="{'danger': !slingConfig.timeBetweenEngineRotation || !(slingConfig.timeBetweenEngineRotation >= 0)}"
                        tooltipPosition="bottom" pTooltip="Tempo em dias entre agendamentos de giro de motor"
                        *ngIf="slingConfig.marineEngineTurnRun">
                        <label for="timeBetweenEngineRotation">Tempo entre giro de motor (dias)</label>
                        <input pInputText type="number" name="timeBetweenEngineRotation"
                            [(ngModel)]="slingConfig.timeBetweenEngineRotation" min="1" required />
                    </div>
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Agenda automaticamente o próximo giro de motor"
                        *ngIf="slingConfig.marineEngineTurnRun">
                        <label for="autoScheduleUpdateEngineTurn">Atualização de agendamento automático</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.autoScheduleUpdateEngineTurn"
                            name="autoScheduleUpdateEngineTurn">
                        </p-inputSwitch>
                    </div>
                    <div class="form-control"
                        [ngClass]="{'danger': !slingConfig.engineTurnRunOption || slingConfig.engineTurnRunOption === ''}"
                        *ngIf="slingConfig.autoScheduleUpdateEngineTurn && slingConfig.marineEngineTurnRun"
                        tooltipPosition="bottom" pTooltip="Ação executada pela atualização automática">
                        <label for="engineTurnRunOption">Tipo de execução</label>
                        <p-dropdown placeholder="Selecione..." [filter]="true" [options]="optionWashAndTurnEngine"
                            appendTo="body" [(ngModel)]="slingConfig.engineTurnRunOption" name="engineTurnRunOption">
                        </p-dropdown>
                    </div>

                </div>
                <div class="form-group">
                    <div class="form-control"
                        [ngClass]="{'danger': slingConfig.engineTurnScheduleDays < 0 || slingConfig.engineTurnScheduleDays === ''}"
                        tooltipPosition="bottom"
                        pTooltip="Utilize está opção se a marina não gira o motor do barco no mesmo dia em que foi retirado da água. (0 equivale ao mesmo dia)"
                        *ngIf="slingConfig.engineTurnRunOption === 'AutomaticScheduling' && slingConfig.marineEngineTurnRun">
                        <label for="engineTurnScheduleDays">Dias para agendamento ao subir</label>
                        <input pInputText type="number" name="engineTurnScheduleDays"
                            [(ngModel)]="slingConfig.engineTurnScheduleDays" min="0" required />
                    </div>
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Habilita giro de motor para barcos em manutenção"
                        *ngIf="slingConfig.marineEngineTurnRun">
                        <label for="hasEngineTurnBoatMaintenance">Giro de motor para barco em manutenção</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.hasEngineTurnBoatMaintenance"
                            name="hasEngineTurnBoatMaintenance">
                        </p-inputSwitch>
                    </div>
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Giro de motor realizada por marinheiro (não aparecerá no painel)">
                        <label for="engineTurnSailor">Realizada por marinheiro</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.engineTurnSailor" name="engineTurnSailor">
                        </p-inputSwitch>
                    </div>

                </div>
            </p-accordionTab>
            <p-accordionTab *ngIf="isSlingMarina" header="Datas Especiais">
                <div style="overflow: scroll;">
                    <app-operation-close-day [closeDays]="slingConfig.operationCloseDays"
                        (deleteEvent)="deleteCloseDayEvent($event)"></app-operation-close-day>
                    <br>
                    <app-operation-special-day [specialDays]="slingConfig.operationSpecialDays"
                        (deleteEvent)="deleteSpecialDayEvent($event)"></app-operation-special-day>
                </div>
            </p-accordionTab>
            <p-accordionTab *ngIf="isSlingMarina" header="Horários de funcionamento">
                <div class="form-group">
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Início do período de utilização da configuração">
                        <label for="operationStart">Início do período</label>
                        <p-calendar dateFormat="dd/mm/yy" name="operationStart"
                            [ngClass]="{'danger': (!slingConfig.operationStart)}" appendTo="body"
                            [(ngModel)]="slingConfig.operationStart" [showIcon]="true" i18n [monthNavigator]="true"
                            [yearNavigator]="true" [yearRange]="'2000:2055'" rPCalendarMask>
                        </p-calendar>
                    </div>
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Fim do período de utilização da configuração">
                        <label for="operationEnd">Fim do período</label>
                        <p-calendar dateFormat="dd/mm/yy" name="operationEnd" appendTo="body"
                            [ngClass]="{'danger': (!slingConfig.operationEnd || slingConfig.operationEnd === '')}"
                            [(ngModel)]="slingConfig.operationEnd" [showIcon]="true" i18n [monthNavigator]="true"
                            [yearNavigator]="true" [yearRange]="'2000:2055'" rPCalendarMask>
                        </p-calendar>
                    </div>
                </div>
                <br>
                <div class="table">
                    <p-table [value]="slingConfig.slingTimes" autoLayout="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th rowspan="2">
                                    Dia Semana
                                </th>
                                <th colspan="2" class="text-center">Horário Marina</th>
                                <th colspan="5" class="text-center">Rampa</th>
                                <th rowspan="2">Tempo Lingada</th>
                            </tr>
                            <tr>
                                <th>Abertura</th>
                                <th>Fechamento</th>
                                <th>Início</th>
                                <th>Intervalo Início</th>
                                <th>Intervalo Fim</th>
                                <th>Fim</th>
                                <th>Retorno</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-time>
                            <tr>
                                <td>
                                    <p-checkbox [value]="true" [binary]="true"
                                        [name]="'inOperation' + slingConfig.slingTimes.indexOf(time)"
                                        [(ngModel)]="time.inOperation">
                                    </p-checkbox>
                                    {{ time.dayWeek | translate }}
                                </td>
                                <td>
                                    <input pInputText type="time" tooltipPosition="bottom"
                                        [ngClass]="{'danger': ((!time.operationStart || time.operationStart === '') && (time.inOperation))}"
                                        pTooltip="Horário de abertura da marina"
                                        [name]="'operationStart' + slingConfig.slingTimes.indexOf(time)"
                                        [(ngModel)]="time.operationStart" [disabled]="!time.inOperation" />
                                </td>
                                <td>
                                    <input pInputText type="time" tooltipPosition="bottom"
                                        pTooltip="Horário de fechamento da marina" [ngClass]="{'danger': ((!time.operationEnd || time.operationEnd === '') &&
                                    (time.inOperation))}"
                                        [name]="'operationEnd' + slingConfig.slingTimes.indexOf(time)"
                                        [(ngModel)]="time.operationEnd" [disabled]="!time.inOperation" />
                                </td>
                                <td>
                                    <input pInputText type="time" tooltipPosition="bottom"
                                        [ngClass]="{'danger': ((!time.slingDownStart || time.slingDownStart === '') && (time.inOperation))}"
                                        pTooltip="Horário de início dos serviços da rampa"
                                        [name]="'slingDownStart' + slingConfig.slingTimes.indexOf(time)"
                                        [(ngModel)]="time.slingDownStart" [disabled]="!time.inOperation" />
                                </td>
                                <td>
                                    <input pInputText type="time" tooltipPosition="bottom"
                                        pTooltip="Horário de início do intervalo dos serviços da rampa"
                                        [name]="'intervalStart' + slingConfig.slingTimes.indexOf(time)"
                                        [(ngModel)]="time.intervalStart" [disabled]="!time.inOperation" />
                                </td>
                                <td>
                                    <input pInputText type="time" tooltipPosition="bottom"
                                        pTooltip="Horário de fim do intervalo dos serviços da rampa"
                                        [name]="'intervalEnd' + slingConfig.slingTimes.indexOf(time)"
                                        [(ngModel)]="time.intervalEnd" [disabled]="!time.inOperation" />
                                </td>
                                <td>
                                    <input pInputText type="time" tooltipPosition="bottom"
                                        [ngClass]="{'danger': ((!time.slingDownEnd || time.slingDownEnd === '') && (time.inOperation))}"
                                        pTooltip="Horário de fechamento dos serviços da rampa"
                                        [name]="'slingDownEnd' + slingConfig.slingTimes.indexOf(time)"
                                        [(ngModel)]="time.slingDownEnd" [disabled]="!time.inOperation" />
                                </td>
                                <td>
                                    <input pInputText type="time" tooltipPosition="bottom"
                                        [ngClass]="{'danger': ((!time.slingReturnEnd || time.slingReturnEnd === '') && (time.inOperation))}"
                                        pTooltip="Horário máximo de retorno para os barcos"
                                        [name]="'slingReturnEnd' + slingConfig.slingTimes.indexOf(time)"
                                        [(ngModel)]="time.slingReturnEnd" [disabled]="!time.inOperation" />
                                </td>
                                <td>
                                    <input pInputText type="number" tooltipPosition="bottom"
                                        [ngClass]="{'danger': ((!time.executionTime || time.executionTime === '') && (time.inOperation))}"
                                        pTooltip="Tempo entre horários de lingadas"
                                        [name]="'executionTime' + slingConfig.slingTimes.indexOf(time)"
                                        [(ngModel)]="time.executionTime" [disabled]="!time.inOperation" />
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-accordionTab>
            <p-accordionTab header="Lavação">
                <div class="form-group">
                    <div class="form-control" tooltipPosition="bottom" pTooltip="Lavação realizada pela marina.">
                        <label for="marineBoatWash">Marina realiza a Lavação</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.marineBoatWash" name="marineBoatWash">
                        </p-inputSwitch>
                    </div>
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Tempo em dias entre agendamentos de lavação" *ngIf="slingConfig.marineBoatWash">
                        <label for="timeBetweenWash">Tempo entre lavações (dias)</label>
                        <input pInputText type="number" name="timeBetweenWash" [(ngModel)]="slingConfig.timeBetweenWash"
                            [ngClass]="{'danger': !slingConfig.timeBetweenWash || !(slingConfig.timeBetweenWash >= 0)}"
                            min="1" required />
                    </div>
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Agenda automaticamente a próxima lavação" *ngIf="slingConfig.marineBoatWash">
                        <label for="autoScheduleUpdateWash">Atualização de agendamento automático</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.autoScheduleUpdateWash" name="autoScheduleUpdateWash">
                        </p-inputSwitch>
                    </div>
                    <div class="form-control" *ngIf="slingConfig.autoScheduleUpdateWash && slingConfig.marineBoatWash"
                        tooltipPosition="bottom" pTooltip="Ação executada pela atualização automática">
                        <label for="washRunOption">Tipo de execução</label>
                        <p-dropdown placeholder="Selecione..." [filter]="true" [options]="optionWashAndTurnEngine"
                            appendTo="body"
                            [ngClass]="{'danger': !slingConfig.washRunOption || slingConfig.washRunOption === ''}"
                            [(ngModel)]="slingConfig.washRunOption" name="washRunOption">
                        </p-dropdown>
                    </div>

                </div>
                <div class="form-group">
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Utilize está opção se a marina não lava o barco no mesmo dia em que foi retirado da água. (0 equivale ao mesmo dia)"
                        *ngIf="slingConfig.washRunOption === 'AutomaticScheduling' && slingConfig.marineBoatWash ">
                        <label for="boatWashScheduleDays">Dias para agendamento ao subir</label>
                        <input pInputText type="number" name="boatWashScheduleDays"
                            [ngClass]="{'danger': slingConfig.boatWashScheduleDays < 0 || slingConfig.boatWashScheduleDays === ''}"
                            [(ngModel)]="slingConfig.boatWashScheduleDays" min="0" required />
                    </div>
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Habilita lavação para barcos em manutenção" *ngIf="slingConfig.marineBoatWash">
                        <label for="hasWashBoatMaintenance">Lavação para barco em manutenção</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.hasWashBoatMaintenance" name="hasWashBoatMaintenance">
                        </p-inputSwitch>
                    </div>
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Lavação realizada por marinheiro (não aparecerá no painel)">
                        <label for="boatWashSailor">Realizada por marinheiro</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.boatWashSailor" name="boatWashSailor">
                        </p-inputSwitch>
                    </div>
                </div>
            </p-accordionTab>
            <p-accordionTab header="{{ (isSlingMarina? 'SLING' : 'MOVEMENTS') | translate}}">
                <div class="form-group">
                    <div *ngIf="isSlingMarina; then isSlingMarina_sling"></div>
                    <ng-template #isSlingMarina_sling>
                        <div class="form-control" tooltipPosition="bottom"
                            [pTooltip]="'Habilita o pedido de produtos consumíveis pelo Totem e Web' + tooltipMessageBlockedConsumablesByFinancialMultipleCompanies()">
                            <label for="hasConsumables">{{ 'HAS-CONSUMABLES' | translate }}</label>
                            <p-inputSwitch [(ngModel)]="slingConfig.hasConsumables" name="hasConsumables"
                                (click)="slingConfig.consumablesApp = false"
                                [disabled]="!canEnableConsumablesFinancialMultipleCompanies()">
                            </p-inputSwitch>
                        </div>
                        <div class="form-control" tooltipPosition="bottom" pTooltip="Habilita o pedido de acessórios">
                            <label for="hasAccessories">{{ 'HAS-ACCESSORIES' | translate }}</label>
                            <p-inputSwitch [(ngModel)]="slingConfig.hasAccessories" name="hasAccessories">
                            </p-inputSwitch>
                        </div>
                        <div class="form-control" tooltipPosition="bottom"
                            pTooltip="Ao preparar a lingada, o barco já desce para água">
                            <label for="slingDownOnPreparation">Descer barco na preparação</label>
                            <p-inputSwitch [(ngModel)]="slingConfig.slingDownOnPreparation"
                                name="slingDownOnPreparation">
                            </p-inputSwitch>
                        </div>
                    </ng-template>
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Imprime comanda de abastecimento de terceiros">
                        <label for="printFuelSupply">Imprimir no abastacimento</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.printFuelSupply" name="printFuelSupply"></p-inputSwitch>
                    </div>
                    <div *ngIf="isSlingMarina" class="form-control" tooltipPosition="bottom"
                        pTooltip="O controle da fila de subida é feito pela marina">
                        <label for="queueControl">Tem controle de fila</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.queueControl" name="queueControl"
                            (click)="slingConfig.manageDeck = false; slingConfig.finishOnWater = false;slingConfig.requestPendingUpApp = false">
                        </p-inputSwitch>
                    </div>
                </div>
                <div *ngIf="isSlingMarina" class="form-group">
                    <div class="form-control" *ngIf="slingConfig.queueControl" tooltipPosition="bottom"
                        pTooltip="Permite atracar o barco no deck">
                        <label for="manageDeck">Tem controle de deck</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.manageDeck" name="manageDeck"
                            (click)="slingConfig.finishOnWater = false;"></p-inputSwitch>
                    </div>
                    <div class="form-control" *ngIf="slingConfig.manageDeck" tooltipPosition="bottom"
                        pTooltip="Permitir que uma lingada seja finalizada com o barco na água">
                        <label for="finishOnWater">Finalizar na água</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.finishOnWater" name="finishOnWater">
                        </p-inputSwitch>
                    </div>
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Permitir que uma vaga seja utilizada por mais de um barco">
                        <label for="sharedVacancy">Vaga compartilhada</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.sharedVacancy" name="sharedVacancy">
                        </p-inputSwitch>
                    </div>
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Permite que o cliente inclua navegação pelo totem">
                        <label for="includeTotemNavigation">Cliente inclui navegação totem</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.includeTotemNavigation" name="includeTotemNavigation">
                        </p-inputSwitch>
                    </div>
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Habilita a opção 'À confirmar' nas lingadas">
                        <label for="toConfirm">Lingada à confirmar</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.toConfirm" name="toConfirm">
                        </p-inputSwitch>
                    </div>
                    <div class="form-control" tooltipPosition="bottom"
                    pTooltip="Habilita a opção de editar lingadas que estão em preparo.">
                    <label for="editSlingInPreparation">Editar Lingada em Preparo</label>
                    <p-inputSwitch [(ngModel)]="slingConfig.editSlingInPreparation" name="editSlingInPreparation">
                    </p-inputSwitch>
                </div>
                </div>
                <div class="form-group">
                    <div tooltipPosition="bottom" class="form-control" style="max-width: 100%!important;"
                        pTooltip="Mensagem adicional impressa no plano de navegação">
                        <label for="messageNavigationPlan">Mensagem adicional no plano de navegação</label>
                        <textarea [rows]="2" pInputTextarea autoResize="autoResize" name="messageNavigationPlan"
                            [(ngModel)]="slingConfig.messageAdditionalNavigationPlan"></textarea>
                        <span class="message-inputtextarea">Caracteres utilizados:
                            {{slingConfig.messageAdditionalNavigationPlan ?
                            slingConfig.messageAdditionalNavigationPlan.length : 0 }}</span>
                    </div>
                </div>
                <div *ngIf="isSlingMarina" class="form-group">
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Limite máximo de agendamentos de lingadas para uma embarção">
                        <label for="numberSling">Limite de agendamentos</label>
                        <input pInputText type="number" name="numberSling" [(ngModel)]="slingConfig.numberSling"
                            min="1" />
                    </div>
                </div>
                <div class="form-group input-general-sms">
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Números para envio de SMS com notificações gerais">
                        <label for="generalSMS">SMS gerais para o responsável da marina</label>
                        <p-inputMask type="text" pInputText placeholder="Digite o número" [(ngModel)]="generalSMS"
                            name="generalSMS" mask="(99) 99999-9999"></p-inputMask>
                    </div>
                    <button pButton type="button" icon="pi pi-plus" class="ui-button-warn" (click)="addGeneralSMS()"
                        [disabled]="isDisabledGeneralSMS()"></button>
                </div>
                <div class="right-button form-group">
                    <span class="badge badge-pill badge-primary" *ngFor="let number of slingConfig.smsGeneralNotices">
                        {{ number }}<i class="pi pi-times" aria-hidden="true" (click)="removeGeneralSMS(number)"></i>
                    </span>
                </div>
                <br>
            </p-accordionTab>
            <p-accordionTab header="Mensalidades">
                <div class="form-group">
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Se habilitado, o cliente realiza o pagamento antes do serviço">
                        <label for="earlyPayment">Mensalidade antecipada</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.earlyPayment" name="earlyPayment">
                        </p-inputSwitch>
                    </div>
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Se habilitado, realiza a cobrança parcial do serviço (proporcional ao tempo de serviço)">
                        <label for="chargePartialService">Cobrar serviço parcial</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.chargePartialService" name="chargePartialService">
                        </p-inputSwitch>
                    </div>
                    <!-- <div class="form-control" tooltipPosition="bottom" pTooltip="Ativa a utilização de contratos">
                        <label for="hasContracts">Contratos</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.hasContracts" name="hasContracts">
                        </p-inputSwitch>
                    </div> -->
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Ativa a utilização de serviços periódico com preço livre">
                        <label for="periodicalServicePriceFree">Preço livre</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.periodicalServicePriceFree"
                            name="periodicalServicePriceFree">
                        </p-inputSwitch>
                    </div>
                </div>
                <div class="form-group">
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Dias disponíveis para vencimento da mensalidade">
                        <label for="daysDueForm">Dias de vencimento *</label>
                        <p-multiSelect placeholder="Selecione..." [options]="daysDueList" [(ngModel)]="daysDueForm"
                            [ngClass]="{'danger': !daysDueForm || daysDueForm?.length === 0 || daysDueForm === null || daysDueForm === undefined }"
                            name="daysDueForm" maxSelectedLabels="31" (onChange)="sortDaysDue()" appendTo="body">
                        </p-multiSelect>
                    </div>
                    <div class="form-control" tooltipPosition="bottom" style="margin-left: 3em;"
                        pTooltip="Se habilitado, utiliza índice para reajuste de contrato.">
                        <label for="useIndex">Índice de reajuste</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.useIndex" name="useIndex">
                        </p-inputSwitch>
                    </div>

                    <div class="form-control" tooltipPosition="bottom" pTooltip="Descrição do índice utilizado"
                        *ngIf="slingConfig.useIndex">
                        <label for="indexUsed">Informe o índice</label>
                        <input pInputText type="text" placeholder="Digite o índice" [(ngModel)]="slingConfig.indexUsed"
                            name="indexUsed">
                    </div>
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Permite definar qual será a forma de bloqueio de clientes inadimplentes">
                        <label for="typeBlockCustomer">Tipo de Bloqueio de Pendencias </label>
                        <p-dropdown placeholder="Selecione..." [(ngModel)]="slingConfig.typeBlockCustomer"
                            name="typeBlockCustomer" appendTo="body" [options]="optionsTypeBlockCustomer"></p-dropdown>
                    </div>
                </div>
                <div class="form-group">
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Se habilitado, bloqueia automaticamente os inadimplentes">
                        <label for="automaticBlock">Bloqueio automático de inadimplente</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.automaticBlock" name="automaticBlock">
                        </p-inputSwitch>
                    </div>

                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Bloquear após a quantidade de dias informados" *ngIf="slingConfig.automaticBlock">
                        <label for="daysToBlock">Bloquear após (dias)</label>
                        <input pInputText type="text" placeholder="Digite o número de dias"
                            [(ngModel)]="slingConfig.daysToBlock" name="daysToBlock">
                    </div>

                </div>
            </p-accordionTab>
            <p-accordionTab header="Painéis">
                <div class="form-group">
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Exibe a vaga do barco nos painéis, se houver">
                        <label for="panelsVacancyDisplay">Exibir vaga do barco no painel</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.panelsVacancyDisplay" name="panelsVacancyDisplay">
                        </p-inputSwitch>
                    </div>
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Exibe lingadas do dia seguinte no painel de descida">
                        <label for="slingsNextDay">Painel de descida com D+1</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.slingsNextDay" name="slingsNextDay">
                        </p-inputSwitch>
                    </div>
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Exibe (em manutenção) no painel de lavação">
                        <label for="showBoatsOnMaintenance">Exibir (em manutenção) no painel de lavação</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.showBoatsOnMaintenance" name="showBoatsOnMaintenance">
                        </p-inputSwitch>
                    </div>
                </div>
            </p-accordionTab>
            <p-accordionTab header="Impressões">
                <div class="form-group">
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Configuração ao incluir novo plano de navegação na WEB">
                        <label for="printOnWeb">Planos de navegação na WEB</label>
                        <p-dropdown [filter]="true" [options]="optionsPrintQuestionTotem" appendTo="body"
                            [(ngModel)]="slingConfig.printNavigationPlanWeb" name="printNavigationPlanOnWeb">
                        </p-dropdown>
                    </div>
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Configuração de impressão durante a venda avulsa na WEB">
                        <label for="printOnWeb">Recibos e comandas avulsas na WEB</label>
                        <p-dropdown [filter]="true" [options]="optionsPrintQuestionTotem" appendTo="body"
                            [(ngModel)]="slingConfig.printReceiptWeb" name="printReceiptOnWeb">
                        </p-dropdown>
                    </div>
                    <div class="form-control" tooltipPosition="bottom" pTooltip="Configuração ao atender pedidos">
                        <label for="printOnWeb">Comandas no fornecimento na WEB</label>
                        <p-dropdown [filter]="true" [options]="optionsPrintQuestionTotem" appendTo="body"
                            [(ngModel)]="slingConfig.printItemsOnDeliveryWeb" name="printItemsDuringDeliveryOnWeb">
                        </p-dropdown>
                    </div>
                </div>
                <div class="form-group">
                    <div class="form-control" [ngClass]="{'danger': !slingConfig.printNavigationPlan}"
                        tooltipPosition="bottom"
                        pTooltip="Configuração de impressão do plano de navegação ao incluir um novo">
                        <label for="printNavigationPlan">Imprimir plano de movimentação</label>
                        <p-dropdown placeholder="Selecione..." [filter]="true" [options]="optionsPrintQuestionTotem"
                            appendTo="body" [(ngModel)]="slingConfig.printNavigationPlan" name="printNavigationPlan">
                        </p-dropdown>
                    </div>
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Imprime automaticamente recibos e comandas">
                        <label for="printReceipt">Imprimir recibo e comanda</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.printReceipt" name="printReceipt">
                        </p-inputSwitch>
                    </div>
                    <div class="form-control" [ngClass]="{'danger': !slingConfig.printItemsDuringDelivery}"
                        tooltipPosition="bottom" pTooltip="Configuração de impressão dos itens ao fornecer um produto">
                        <label for="printItemsDuringDelivery">Imprimir itens durante fornecimento</label>
                        <p-dropdown placeholder="Selecione..." [filter]="true" [options]="optionsPrintQuestionTotem"
                            appendTo="body" [(ngModel)]="slingConfig.printItemsDuringDelivery"
                            name="printItemsDuringDelivery">
                        </p-dropdown>
                    </div>
                </div>
            </p-accordionTab>
            <p-accordionTab header="Sistema">
                <div class="form-group">
                    <div class="form-control" tooltipPosition="bottom" pTooltip="Qual fuso horário que a marina opera?">
                        <label for="timeZone">Qual fuso horário que a marina opera?</label>
                        <p-dropdown placeholder="Selecione..." [filter]="true" [options]="timeZones" appendTo="body"
                            [(ngModel)]="slingConfig.timezone" name="timeZone">
                        </p-dropdown>
                    </div>
                    <div class="form-control" [ngClass]="{'danger': !slingConfig.numberOfRecordsPerPage}"
                        tooltipPosition="bottom"
                        pTooltip="Quantidade máxima de registros exibidos nas tabelas do sistema (por página)">
                        <label for="numberOfRecordsPerPage">Quantidade de registros por página</label>
                        <p-dropdown placeholder="Selecione..." [filter]="true" [options]="numbersOfRecordsPerPage"
                            appendTo="body" [formControl]="numberOfRecordsPerPage"
                            [ngClass]="{'danger': !slingConfig.numberOfRecordsPerPage || slingConfig.numberOfRecordsPerPage === 0}"
                            [(ngModel)]="slingConfig.numberOfRecordsPerPage" required name="numberOfRecordsPerPage">
                        </p-dropdown>
                    </div>
                    <div *ngIf="currentMarina?.activeFranchise" class="form-control" tooltipPosition="bottom"
                        pTooltip="Definir qual produto será usado para gerar o pedido das movimentação além da franquia.">
                        <label for="franchiseProductId">Produto da franquia excedente:</label>
                        <p-dropdown placeholder="Selecione..." [filter]="true" [options]="optionsfranchiseProduct"
                            appendTo="body" [(ngModel)]="slingConfig.franchiseProductId" name="franchiseProductId">
                        </p-dropdown>
                    </div>
                </div>
                <div class="form-group">
                  <div class="form-control">
                    <label for="latitude">Latitude para Previsão</label>
                    <input pInputText type="text" name="latitude" [(ngModel)]="slingConfig.latitudePrevisao" />
                  </div>
                  <div class="form-control">
                    <label for="longitude">Longitude para Previsão</label>
                    <input pInputText type="text" name="longitude" [(ngModel)]="slingConfig.longitudePrevisao" />
                  </div>
                </div>
                <div *ngIf="isSlingMarina" class="form-group">
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Exibe a aplicação de origem das lingadas no operacional (App Cliente, App Gestor, App Marinheiro, Totem ou Web)">
                        <label for="showSlingOrigin">Exibir origem das lingadas</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.showSlingOrigin" name="showSlingOrigin">
                        </p-inputSwitch>
                    </div>
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Obriga  preencher o plano de navegação ao pedir uma embarcação pelo totem">
                        <label for="navigationPlanRequiredTotem">{{ 'IS-NAVIGATION-PLAN-REQUIRED-TOTEM' | translate
                            }}</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.navigationPlanRequiredTotem"
                            name="navigationPlanRequiredTotem"></p-inputSwitch>
                    </div>
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Permite o pagamento parcial e divisão de conta na venda avulsa na Web">
                        <label for="showAdvancedPayment">Trabalhar com Pagamentos Avançados</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.showAdvancedPayment"
                            name="showAdvancedPayment"></p-inputSwitch>
                    </div>
                </div>
                <div class="form-group">
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Permite a edição das fotos básicas de embarcações no App Cliente">
                        <label for="addPhotoBasicCustomerApp">Edição de fotos básicas no app Cliente</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.addPhotoBasicCustomerApp"
                            name="addPhotoBasicCustomerApp"></p-inputSwitch>
                    </div>
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Permite a edição das fotos básicas de embarcações no App Operador">
                        <label for="addPhotoBasicOperatorApp">Edição de fotos básicas no app Operador</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.addPhotoBasicOperatorApp"
                            name="addPhotoBasicOperatorApp"></p-inputSwitch>
                    </div>
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Permite a edição das fotos básicas de embarcações no Totem">
                        <label for="addPhotoBasicTotemApp">Edição de fotos básicas no Totem</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.addPhotoBasicTotemApp" name="addPhotoBasicTotemApp">
                        </p-inputSwitch>
                    </div>
                </div>
                <div class="form-group">
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="A opção do tipo busca já vem pré-selecionada como 'cliente'">
                        <label for="searchByCustomer">Exibir como padrão a busca por cliente</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.searchByCustomer" name="searchByCustomer">
                        </p-inputSwitch>
                    </div>
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Permite trabalhar com centro de custo nos produtos.">
                        <label for="costCenterInStock">Trabalhar com centro de custos</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.costCenterInStock" name="costCenterInStock">
                        </p-inputSwitch>
                    </div>

                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Enviar faturas para cliente via whatsapp">
                        <label for="sendInvoiceViaWhatsapp">Enviar faturas via Whatsapp</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.sendInvoiceViaWhatsapp" name="sendInvoiceViaWhatsapp">
                        </p-inputSwitch>
                    </div>
                </div>
            </p-accordionTab>
            <p-accordionTab header="Marinheiro">
                <div class="form-group">
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Habilita App Marinheiro e suas configurações">
                        <label for="hasSailor">Habilitar marinheiro</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.hasSailor" name="hasSailor">
                        </p-inputSwitch>
                    </div>
                    <div class="form-control" tooltipPosition="bottom" *ngIf="slingConfig.hasSailor"
                        pTooltip="Exige verificação de posição no App Marinheiro para operação como preparar e pedir para subir embarcação">
                        <label for="hasPositionCheckSailor">Exigir localização</label>
                        <p-inputSwitch [(ngModel)]="slingConfig.hasPositionCheckSailor" name="hasPositionCheckSailor">
                        </p-inputSwitch>
                    </div>
                </div>
            </p-accordionTab>
            <p-accordionTab header="{{'STOCK' | translate}}">
                <div class="form-group">
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Tipo de Movimentação de entrada dos pedidos da lingada/Movimentação">
                        <label for="stockMovementSlingIn">Entrada dos pedidos de Ling/Mov.</label>
                        <p-dropdown placeholder="Selecione..." [filter]="true" [options]="stockMovementInOptions"
                            appendTo="body" [(ngModel)]="slingConfig.stockMovementSlingIn" optionLabel="label"
                            optionValue="value" name="stockMovementSlingIn">
                        </p-dropdown>
                    </div>
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Tipo de Movimentação de saída dos pedidos da lingada/Movimentação">
                        <label for="stockMovementSlingOut">Saída dos pedidos de Ling/Mov.</label>
                        <p-dropdown placeholder="Selecione..." [filter]="true" [options]="stockMovementOutOptions"
                            appendTo="body" [(ngModel)]="slingConfig.stockMovementSlingOut" optionLabel="label"
                            optionValue="value" name="stockMovementSlingOut">
                        </p-dropdown>
                    </div>
                </div>
                <div class="form-group">
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Tipo de Movimentação de entrada da venda avulsa.">
                        <label for="stockMovementDetachedIn">Entrada da venda avulsa.</label>
                        <p-dropdown placeholder="Selecione..." [filter]="true" [options]="stockMovementInOptions"
                            appendTo="body" [(ngModel)]="slingConfig.stockMovementDetachedIn" optionLabel="label"
                            optionValue="value" name="stockMovementDetachedIn">
                        </p-dropdown>
                    </div>
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Tipo de Movimentação de saída da venda avulsa.">
                        <label for="stockMovementDetachedOut">Saída da venda avulsa.</label>
                        <p-dropdown placeholder="Selecione..." [filter]="true" [options]="stockMovementOutOptions"
                            appendTo="body" [(ngModel)]="slingConfig.stockMovementDetachedOut" optionLabel="label"
                            optionValue="value" name="stockMovemenDetachedOut">
                        </p-dropdown>
                    </div>
                </div>
                <div class="form-group">
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Tipo de Movimentação de entrada do acerto de estoque.">
                        <label for="stockMovementAdjustmentIn">Entrada do acerto de estoque.</label>
                        <p-dropdown placeholder="Selecione..." [filter]="true" [options]="stockMovementInOptions"
                            appendTo="body" [(ngModel)]="slingConfig.stockMovementAdjustmentIn" optionLabel="label"
                            optionValue="value" name="stockMovementAdjustmentIn">
                        </p-dropdown>
                    </div>
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Tipo de Movimentação de saída do acerto de estoque.">
                        <label for="stockMovementAdjustmentOut">Saída do acerto de estoque.</label>
                        <p-dropdown placeholder="Selecione..." [filter]="true" [options]="stockMovementOutOptions"
                            appendTo="body" [(ngModel)]="slingConfig.stockMovementAdjustmentOut" optionLabel="label"
                            optionValue="value" name="stockMovemenAdjustmentOut">
                        </p-dropdown>
                    </div>
                </div>
            </p-accordionTab>
            <p-accordionTab *ngIf="useiInvites" header="{{'Convites' | translate}}">
                <div class="form-group">
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Forma que será usada para recarregar os convites dos proprietários">
                        <label for="inviteControlYear">Tipo de Recarga</label>
                        <p-dropdown placeholder="Selecione..." [filter]="true"
                            [options]="[{'label':'Anual','value':true},{'label':'Mensal','value':false}]"
                            appendTo="body" [(ngModel)]="slingConfig.inviteControlYear" optionLabel="label"
                            optionValue="value" name="inviteControlYear">
                        </p-dropdown>
                    </div>
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Número de convites após a recarga convites">
                        <label for="inviteQtd">Número de Convites</label>
                        <input pInputText type="number" name="inviteQtd" [(ngModel)]="slingConfig.inviteQtd" min="0">
                    </div>
                    <div class="form-control" *ngIf="slingConfig.inviteControlYear" tooltipPosition="bottom"
                        pTooltip="Data do ano para recarga dos convites">
                        <label for="dateRecharge">Data de recarga (dd/mm)</label>
                        <input pInputText type="text" name="dateRecharge" [(ngModel)]="slingConfig.dateRecharge"
                            mask="99/99">
                    </div>
                    <div class="form-control" *ngIf="!slingConfig.inviteControlYear" tooltipPosition="bottom"
                        pTooltip="Dia do mês para recarga dos convites">
                        <label for="dateRecharge">Dia de recarga (1 à 28)</label>
                        <input pInputText type="number" name="dateRecharge" [(ngModel)]="slingConfig.dateRecharge"
                            mask="99" min="1" max="28">
                    </div>
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Número máximo de convites que pode ser adquirido no período.">
                        <label for="inviteMaxBuy">Máximo para compra</label>
                        <input pInputText type="number" name="inviteMaxBuy" [(ngModel)]="slingConfig.inviteMaxBuy"
                            mask="99" min="1" max="99">
                    </div>
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Máximo de convites que pode ser usado em um dia">
                        <label for="inviteDailyLimit">Máximo por dia</label>
                        <input pInputText type="number" name="inviteDailyLimit"
                            [(ngModel)]="slingConfig.inviteDailyLimit" mask="99" min="1" max="99">
                    </div>
                    <div class="form-control" tooltipPosition="bottom"
                        pTooltip="Serviço que será utilizado para gerar o pedido">
                        <label for="inviteServiceId">Convite excedente</label>
                        <p-dropdown placeholder="Selecione..." [filter]="true" [options]="optionsfranchiseProduct"
                            appendTo="body" [(ngModel)]="slingConfig.inviteServiceId" name="inviteServiceId">
                        </p-dropdown>
                    </div>
                </div>

            </p-accordionTab>
        </p-accordion>

        <div class="buttons">
            <div class="form-control">
                {{'REQUIRED-FIELDS' | translate}}
            </div>
            <div class="buttons">
                <button class="easymarine danger" type="button" (click)="dialogRef.close()">{{ 'CANCEL' | translate
                    }}</button>
                <button class="easymarine primary" type="submit">{{ 'SAVE' | translate }}</button>
            </div>
        </div>
    </form>
</div>
