import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import Swal from 'sweetalert2';
import { InvoiceBankingBillet } from 'src/app/models';
import { InvoiceMailDTO } from 'src/app/models/dtos/invoiceMail.DTO';
import { Invoice } from 'src/app/models/invoice';
import { InvoiceService } from 'src/app/services/invoice.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { UntypedFormControl, Validators } from '@angular/forms';
import { InvoiceEmailAttachmentType } from 'src/app/models/enums/invoice/invoice-email-attachment-type';


export class InvoiceToEmailDTO extends Invoice {
  invoiceBankingBillets: InvoiceBankingBillet[]
  activeBillet:InvoiceBankingBillet;
  serviceDocument: any;
  invoiceReceipt: any;
}

export class InvoiceEmaiDialogParams {
  isCopy: boolean;
  single: boolean;
  serverOwn: boolean;
  invoices: InvoiceToEmailDTO[];
  hasInvoiceReceipt: boolean;
}

interface InvoicesToSendInfo {
  total: number,
  amountWith: { label: string, value: number }[]
}

@Component({
  selector: 'app-invoice-email-dialog',
  templateUrl: './invoice-email-dialog.component.html',
  styleUrls: ['./invoice-email-dialog.component.scss']
})
export class InvoiceEmailDialogComponent implements OnInit {

  params: InvoiceEmaiDialogParams;

  invoicesInfo: InvoicesToSendInfo


  attachmentOptions: any[];

  loading: boolean = false;
  email: string = '';

  constructor(
    public dialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private invoiceService: InvoiceService,
    private spinner: NgxSpinnerService,
    private messageUtil: MessageUtil
  ) { }

  ngOnInit(): void {
    if (this.config.data) {
      this.params = this.config.data.params as InvoiceEmaiDialogParams;
      if (!this.params.invoices || this.params.invoices.length <= 0) {
        this.close();
      }

      this.invoicesInfo = {
        total: this.params.invoices.length,
        amountWith: [
          { label: 'Faturas com Boleto', value: this.countInvoicesWithBankingBillet(this.params.invoices) },
          { label: 'Faturas com Nota Fiscal', value: this.countInvoicesWithDocuments(this.params.invoices) },
        ]
      }
      this.attachmentOptions = [
        { label: "Boleto", key: InvoiceEmailAttachmentType.Billet , value: true },
        { label: "Nota Fiscal", key: InvoiceEmailAttachmentType.Document , value: true }
      ]
      if (this.params.hasInvoiceReceipt) {
        this.invoicesInfo.amountWith.push({ label: 'Faturas com Recibo', value: this.countInvoicesWithReceipt(this.params.invoices)});
        this.attachmentOptions.push({ label: "Recibo", key: InvoiceEmailAttachmentType.Receipt , value: true });
      }
    } else {
      this.close();
    }
  }

   async clickSend() {
    if (this.params.invoices.length > 0) {
         const invoice = this.params.invoices[0];

         if (invoice.customer.emailCollectionArray) {
             invoice.customer.emailCollectionArray = invoice.customer.emailCollectionArray.map(email => email.trim());
         }
     }
     
     if (this.params.isCopy) {
         this.sendEmailCopy(this.params.invoices[0], this.params.serverOwn);
     } else {
        this.sendEmails(this.params.invoices, this.params.single, this.params.serverOwn);
     }
  }

  close(result: boolean = false) {
    this.dialogRef.close(result);
  }

  countInvoicesWithBankingBillet(invoices: InvoiceToEmailDTO[]): number {
    return invoices.filter((i) =>
    i.invoiceBankingBillets.filter((billet) => billet.active && billet.processed).length>0).length;
  }

  countInvoicesWithDocuments(invoices: InvoiceToEmailDTO[]): number {
    return invoices.filter((i) => i.serviceDocument ).length;
  }

  countInvoicesWithReceipt(invoices: InvoiceToEmailDTO[]): number {
    return invoices.filter((i) => i.invoiceReceipt ).length;
  }

  sendEmails(invoices: InvoiceToEmailDTO[], single: boolean, serverOwn: boolean): void {
    const body: InvoiceMailDTO[] = invoices.map((i) => (
      {
        invoiceId: i.id,
        emails: i.customer.emailCollectionArray,
        single: single ? true : false,
        serverOwn: serverOwn? true : false
      }
    ));
    this.send(body);
  }

  sendEmailCopy(invoice: InvoiceToEmailDTO, serverOwn: boolean) {
    const emails = this.validateAndGetEmails(this.email);
    if (emails) {
      const body: InvoiceMailDTO[] = [
        {
          invoiceId: invoice.id,
          emails,
          single: true,
          serverOwn: serverOwn? true : false
        }
      ];
      this.send(body);
    }
  }

  private async send(body: any): Promise<void> {
    const confirm = await this.confirmInvoiceToSend(this.invoicesInfo);
    if (confirm) {
      this.loading = true;
      this.spinner.show();
      const attachments: InvoiceEmailAttachmentType[] = this.attachmentOptions.filter((opt) => opt.value).map((opt) => opt.key);
      this.invoiceService.sendEmails(body, attachments).subscribe(
        () => {
          this.messageUtil.generateMessage('success', 'SUMMARY.SUCCESS', 'MSG.SENT-EMAILS');
          this.spinner.hide();
          this.loading = false;
          this.close(true);
        },
        (error) => {
          this.spinner.hide();
          this.loading = false;
          const exception = error.error.data.exception;
          this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
          this.close();
        }
      );
    } else {
      this.close();
    }
  }

  async confirmInvoiceToSend(invoicesInfo: InvoicesToSendInfo): Promise<boolean> {
    return Swal.fire(
      {
        title: 'Será realizado o envio de e-mail de <br><br><strong>' +
        invoicesInfo.total + ' fatura(s)</strong><br> Deseja continuar?',
        icon: 'warning',
        showCancelButton: true,
        backdrop: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      }).then((res) => res.isConfirmed );
  }

  validateAndGetEmails(email: string): string[] | null {
    if (email === '') {
      this.messageUtil.generateMessage('warning', 'SUMMARY.WARNING', 'Digite pelo menos um endereço.');
      return null;
    }
    const emails = email.replace(/\s/g, '').split(';');
    const invalidEmail = emails.find((e) => !this.validateEmail(e));
    if (invalidEmail) {
      this.messageUtil.generateMessage('warning', 'SUMMARY.WARNING', 'O endereço ' + invalidEmail + ' está incorreto.');
      return null;
    }
    return emails;
  }

  validateEmail(email: string): boolean {
    return (new UntypedFormControl(email, Validators.email)).valid && email.includes('.') && email.includes('@');
  }
}
