import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AccessPointService } from 'src/app/services/access-controler/access-point.service';
import Swal from 'sweetalert2';

interface Direction {
  name: string;
  code: string;
}

@Component({
  selector: 'app-access-point-form',
  templateUrl: './access-point-form.component.html',
  styleUrls: ['./access-point-form.component.scss']
})
export class AccessPointFormComponent implements OnInit {
  direction: Direction[] | undefined;

  accessPointGroup : FormGroup = new FormGroup({
    id: new FormControl<number | null>(null),
    name: new FormControl<string | null>(null, Validators.required),
    location: new FormControl<string | null>(null),
    ip: new FormControl<string | null>(null),
    username: new FormControl<string | null>(null),
    password: new FormControl<string | null>(null),
    active: new FormControl<boolean | null>(true),
    urlLive: new FormControl<string | null>(null),
    urlValidation: new FormControl<string | null>(null),
    marinaId: new FormControl<number | null>(null),
    readCard: new FormControl<boolean | null>(false),
    printFinger: new FormControl<boolean | null>(false),
    qrCode: new FormControl<boolean | null>(false),
    face: new FormControl<boolean | null>(false),
    direction: new FormControl<string | null>(null),
  });

  directions = [
    { name: 'Entrada', code: 'IN' },
    { name: 'Saída', code: 'OUT' },
    { name: 'Interna', code: 'INT' },
  ];


  constructor(
    private accessPointService: AccessPointService,
    private config: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef,
  ) { }

  ngOnInit() {
    if (this.config.data.accessPoint) {
      this.accessPointGroup.patchValue(this.config.data.accessPoint);
    }
  }

  changeDirection(event: any) {
    this.accessPointGroup.controls.selectedDirection.setValue(event.value);
  }

  returnPage(): void {
    this.dialogRef.close(null);
  }

  save(): void {
    if (!this.accessPointGroup.valid)
      {
        Swal.fire(
          'Erro!',
          'Existem campos Obrigatórios não preenchidos.',
          'error'
        );
        return;
      }
      this.accessPointService.save(this.accessPointGroup.value).subscribe({
        next: () => {
          this.returnPage();
        },
        error: (error) => {
          console.log(error);
        }
      });

  }
}
