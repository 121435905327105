<p-fieldset>

    <p-header class="article-title">
        <span class="article-title">{{ 'Operacional > Lingadas' | translate }}</span>
        <div class="new-header-buttons">
            <button class="fab-button primary " (click)="exportTable()" label="Exportar CSV"
            pTooltip="A exportação será de acordo com os filtros selecionados" tooltipPosition="left">
            <i class="pi pi-file"></i>
        </button>
        <button class="fab-button primary" appFiltersButton pTooltip="Exibir/Ocultar Filtros" tooltipPosition="left">
            <i class="pi pi-filter"></i>
        </button>
    </div>
    </p-header>

    <form class="filters" (submit)="find()" appFilters>
        <div class="filter-row">
            <div class="form-control">
                <label>Data solicitada inicial</label>
                <p-calendar class="field" dateFormat="dd/mm/yy" name="requestTimeStart" [monthNavigator]="true"
                    [yearNavigator]="true" [yearRange]="'2000:2055'" rPCalendarMask appendTo="body"
                    [(ngModel)]="filter.requestTimeStart" [showIcon]="true" i18n>
                </p-calendar>
            </div>
            <div class="form-control">
                <label>Data solicitada final</label>
                <p-calendar class="field" dateFormat="dd/mm/yy" name="requestTimeEnd" [monthNavigator]="true"
                    appendTo="body" [yearNavigator]="true" [yearRange]="'2000:2055'" rPCalendarMask
                    [(ngModel)]="filter.requestTimeEnd" [showIcon]="true" i18n>
                </p-calendar>
            </div>

            <div class="form-control">
                <label>Previsão execução inicial</label>
                <p-calendar class="field" dateFormat="dd/mm/yy" name="executionForecastStart" [monthNavigator]="true"
                    appendTo="body" [yearNavigator]="true" [yearRange]="'2000:2055'" rPCalendarMask
                    [(ngModel)]="filter.executionForecastStart" [showIcon]="true" i18n>
                </p-calendar>
            </div>
            <div class="form-control">
                <label>Previsão execução final</label>
                <p-calendar class="field" dateFormat="dd/mm/yy" name="executionForecastEnd" [monthNavigator]="true"
                    appendTo="body" [yearNavigator]="true" [yearRange]="'2000:2055'" rPCalendarMask
                    [(ngModel)]="filter.executionForecastEnd" [showIcon]="true" i18n>
                </p-calendar>
            </div>

            <div class="form-control">
                <label>Origem</label>
                <p-dropdown class="field" placeholder="Selecione..." appendTo="body" [options]="[{label: 'Todos', value: null},
                                    {label: 'App Cliente', value: 'CustomerApp'},
                                    {label: 'App Gestor', value: 'GestorApp'},
                                    {label: 'App Marinheiro', value: 'MarinheiroApp'},
                                    {label: 'Totem', value: 'TotemApp'},
                                    {label: 'Web', value: 'EasyWeb'}
                                    ]" [(ngModel)]="filter.slingOrigin" name="filter.slingOrigin">
                </p-dropdown>
            </div>
            <div class="form-control">
                <label>Status barco</label>
                <p-multiSelect class="field" defaultLabel="Selecione..." appendTo="body" [options]="[{label: 'Aguardando preparo', value: 'WAITING_PREPARATION'},
                                    {label: 'Atracado', value: 'MOORED'},
                                    {label: 'Preparado', value: 'PREPARED'},
                                    {label: 'Guardado', value: 'STORED'},
                                    {label: 'Navegando', value: 'SAILING'}]" [(ngModel)]="filter.boatStatus"
                    name="boatStatus" selectedItemsLabel="{0} itens selecionados" maxSelectedLabels="1">
                </p-multiSelect>
            </div>
            <div class="form-control">
                <label>Status operação</label>
                <p-multiSelect class="field" defaultLabel="Selecione..." appendTo="body"
                    (onChange)="onChangeMultiSelect($event)" [options]="[{label: 'Cancelado', value: 'CANCELED'},
                                    {label: 'Ok', value: 'OK'},
                                    {label: 'Água', value: 'WATER'},
                                    {label: 'Pendente de descida', value: 'PENDING_DOWN'},
                                    {label: 'Pendente de subida', value: 'PENDING_UP'},
                                    {label: 'Finalizado na água', value: 'FINISHED_ON_WATER'}
                                    ]" maxSelectedLabels="1" [(ngModel)]="filter.slingStatus" name="slingStatus"
                    selectedItemsLabel="{0} itens selecionados">
                </p-multiSelect>
            </div>
            <div class="form-control">
                <label>{{ 'CUSTOMER' | translate }}</label>
                <p-dropdown class="field" placeholder="Selecione..." [filter]="true" [options]="filteredCustomers"
                    appendTo="body" [(ngModel)]="selectedCustomerId" name="selectedCustomerId"
                    (onChange)="selectCustomer()">
                </p-dropdown>
            </div>

            <div class="form-control">
                <label>{{ 'BOAT' | translate }}</label>
                <p-dropdown class="field" placeholder="Selecione..." [filter]="true" [options]="boatsFilter"
                    appendTo="body" [(ngModel)]="selectedBoatId" name="selectedBoatId" (onChange)="selectBoat()">
                </p-dropdown>
            </div>

            <div class="buttons">
                <div class="form-button">
                    <button class="danger easymarine" type="button" (click)="clear()">{{ 'CLEAR' | translate }}</button>
                </div>
                <div class="form-button">
                    <button class="primary easymarine" type="submit">{{ 'FIND' | translate }}</button>
                </div>
            </div>
        </div>

    </form>
    <div class="table">
        <p-table #tt [value]="slingsTable" [paginator]="true" [rows]="numberOfRows" resetPageOnSort="true"
            [pageLinks]="pageLinks" [rowsPerPageOptions]="" [globalFilterFields]="['boat.name']"
            [autoLayout]="true" styleClass="easy-table p-datatable-striped" (onPage)="changeRows($event)">
            <ng-template pTemplate="caption">
                <div class="fill-empty"></div>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input type="text" pInputText size="50" placeholder="Nome da embarcação" [(ngModel)]="globalFilter"
                        (input)="filterGlobal()" style="width:auto">
                </span>
                <span style="font-weight: bold; float: right; margin-top: 5px;">{{ 'TOTAL-REGISTERS' | translate }}:
                    {{getRegistries()}} {{'OF' | translate}} {{tt.totalRecords}}
                </span>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th [pSortableColumn]="'id'">
                        Id
                        <p-sortIcon [field]="'id'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'slingOrigin'">
                        {{ 'ORIGIN' | translate }}
                        <p-sortIcon [field]="'slingOrigin'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'requestTime'">
                        {{ 'REQUEST-DATE' | translate }}
                        <p-sortIcon [field]="'requestTime'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'executionForecast'">
                        {{ 'EXECUTION-FORECAST' | translate }}
                        <p-sortIcon [field]="'executionForecast'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'boat.name'">
                        {{ 'BOAT' | translate }}
                        <p-sortIcon [field]="'boat.name'"></p-sortIcon>
                    </th>
                    <th>
                        {{ 'MSG.HAS-SAILOR' | translate }}
                    </th>
                    <th [pSortableColumn]="'slingStatus'">
                        {{ 'Status' | translate }}
                        <p-sortIcon [field]="'slingStatus'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'boatStatus'" appHideMobile>
                        {{ 'BOAT-STATUS' | translate }}
                        <p-sortIcon [field]="'boatStatus'"></p-sortIcon>
                    </th>
                    <th colspan="2"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-sling>
                <tr>
                    <td>
                        <span class="p-column-title">Id</span>
                        <div class="table-action" (click)="openSlingLogs(sling.id)">
                            {{sling.id}}
                        </div>
                    </td>
                    <td [ngClass]="{'text-center' : window?.innerWidth > 640}">
                        <span class="p-column-title"> {{ 'ORIGIN' | translate }}</span>
                        <img [src]="slingUtil.getImageOriginSling(sling)" [pTooltip]="slingUtil.getOriginName(sling)"
                            class="img-origin" tooltipPosition="bottom">
                    </td>
                    <td>
                        <span class="p-column-title"> {{ 'REQUEST-DATE' | translate }}</span>
                        {{timezone(sling.requestTime) | date: 'dd/MM/yy - HH:mm'}}
                    </td>
                    <td>
                        <span class="p-column-title"> {{ 'EXECUTION-FORECAST' | translate }}</span>
                        {{sling.executionForecast | date: 'dd/MM/yy - HH:mm'}}
                    </td>
                    <td>
                        <span class="p-column-title"> {{ 'BOAT' | translate }}</span>
                        <span class="text-right">
                            {{sling.boat.name}} -
                            {{sling.boat.shipyardModel.shipType.toUpperCase() | translate}} -
                            {{sling.boat.commercialLength}}'
                        </span>
                    </td>
                    <td>
                      <span class="text-right">
                          {{hasBoatSailor(sling)}}
                      </span>
                  </td>
                    <td>
                        <span class="p-column-title"> {{ 'SLING-STATUS' | translate }}</span>
                        {{sling.slingStatus.toUpperCase() | translate}}
                    </td>
                    <td *ngIf="!sling.finishedOnWater" appHideMobile>
                        <span class="p-column-title"> {{ 'BOAT-STATUS' | translate }}</span>
                        {{sling.boatStatus.toUpperCase() | translate}}
                    </td>
                    <td *ngIf="sling.finishedOnWater">
                        Finalizado na água
                    </td>
                    <td [ngClass]="{'text-center' : window?.innerWidth > 640}">
                        <span class="p-column-title"> {{ 'NAVIGATION-PLAN' | translate }}</span>
                        <div [pTooltip]="validateNavigationPlan(sling.navigationPlan) ? 'Plano de navegação completo' : 'Plano de navegação incompleto'"
                            class="table-action">
                            <i [ngClass]="{'success' : validateNavigationPlan(sling.navigationPlan), 'danger': !validateNavigationPlan(sling.navigationPlan)}"
                                class="pi pi-map-marker"></i>
                        </div>
                    </td>
                    <td [ngClass]="{'text-center' : window?.innerWidth > 640, 'actions': window?.innerWidth <= 640}">
                        <div (click)="openSlingFormDialog(sling.id)" tooltipPosition="bottom" class="table-action"
                            pTooltip="{{ 'EDIT' | translate }}" *ngIf="hasEdit(sling)">
                            <i class="pi pi-pencil"></i>
                        </div>
                        <div (click)="openSlingFormDialog(sling.id)" tooltipPosition="bottom" class="table-action"
                            pTooltip="{{ 'VISUALIZE' | translate }}" *ngIf="!slingUtil.hasCancel(sling)">
                            <i class="pi pi-eye"></i>
                        </div>
                        <div (click)="openSlingCancelConfirmDialog(sling)" tooltipPosition="bottom"
                            pTooltip="{{ 'CANCEL' | translate }}" [disabled]="hasCancel(sling)" class="table-action">
                            <i class="danger pi pi-times-circle"></i>
                        </div>
                        <div (click)="openUndoFinishOnWater(sling)" pTooltip="Desfazer" tooltipPosition="bottom"
                            *ngIf="sling.finishedOnWater && sling.slingStatus === 'WATER'" class="table-action">
                            <i class="pi pi-undo"></i>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="11">
                        Nenhuma lingada encontrada
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-fieldset>
