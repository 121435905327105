import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from 'primeng/dynamicdialog';
import { CustomReportExportComponent } from 'src/app/components/extract-custom-report/custom-report-export.component';
import { ExtractPdfGenericComponent } from 'src/app/components/extract-pdf-generic/extract-pdf-generic.component';
import { Boat, ServiceBoat, SlingConfig } from 'src/app/models';
import { BoatFilter } from 'src/app/models/dtos/boat-filter';
import { PrincingType, ShipType } from 'src/app/models/enums';
import { BoatService } from 'src/app/services/boat.service';
import { VacancyService } from 'src/app/services/vacancy.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { WorkSheet } from 'xlsx';
import * as FileSaver from 'file-saver'
import { OperationalConfigService } from 'src/app/services/config/operational-config.service';

@Component({
  selector: 'app-boat-detail-services',
  templateUrl: './boat-detail-services.component.html',
  styleUrls: ['./boat-detail-services.component.scss']
})
export class BoatDetailServicesComponent implements OnInit {

  boats: Boat[];
  slingConfig: SlingConfig;
  selectedBoats: any[];
  option: string;
  boatFilter = new BoatFilter();
  totalRecords = 0;
  totalCommercialLength = 0;
  averageCommercialLength = 0;
  totalVlPe = 0;
  totalValue = 0;
  totalDesc = 0;
  totalDescVenc = 0;
  total = 0;
  groupedByBoats = false;

  rangeDates: Date[];
  rangeDatesStart: Date[];
  rangeDatesEnd: Date[];
  orderObj: any;

  cols = [
    { field: 'name', header: 'Embarcação' },
    { field: 'vacancyCode', header: 'Vaga' },
    { field: 'shipTypeBoat', header: 'Tipo' },
    { field: 'customerName', header: 'Cliente' },
    { field: 'customerGroup', header: 'Grupo' },
    { field: 'customerQuotaCSV', header: 'Rateio' },
    { field: 'serviceName', header: 'Serviço' },
    { field: 'commercialLength', header: 'Tamanho comercial' },
    { field: 'rangeValue', header: 'Valor pés' },
    { field: 'valueService', header: 'Valor' },
    { field: 'dueDateDiscount', header: 'Desc. Venc.' },
    { field: 'discount', header: 'Desconto' },
    { field: 'discountPERC', header: '% Desconto' },
    { field: 'totalValue', header: 'Total' }
  ];

  shipTypeOptions = [
    { label: 'Todos', value: null },
    { label: 'Lancha', value: ShipType.Motorboat },
    { label: 'Veleiro', value: ShipType.Sailboat },
    { label: 'Bote', value: ShipType.InflatableBoat },
    { label: 'Jet ski', value: ShipType.JetSki },
    { label: 'Catamarã', value: ShipType.Catamaran },
    { label: 'Escuna', value: ShipType.Schooner },
    { label: 'Traineira', value: ShipType.Trawler },
    { label: 'Caiaque', value: ShipType.Kayak },
    { label: 'Canoa', value: ShipType.Canoe },
  ];
  vacancies: Array<{ label: string, value: number }> = [];
  isCustomerServiceAvaliable: boolean;

  constructor(
    private boatService: BoatService,
    private spinner: NgxSpinnerService,
    private messageUtil: MessageUtil,
    private dialog: DialogService,
    private route: ActivatedRoute,
    private vacancyService: VacancyService,
    private operationalConfigService: OperationalConfigService
  ) {
  }

  ngOnInit(): void {
    this.option = undefined;
    this.findBoatsService();
    this.receiveNoticiesParams();
    this.loadVacancies();
    this.getIsCustomerService();
  }

  groupByBoats() {
    this.groupedByBoats = !this.groupedByBoats;
  }

  clear(): void {
    this.rangeDates = null;
    this.rangeDatesStart = null;
    this.rangeDatesEnd = null;
    this.boatFilter = new BoatFilter();
    this.boatFilter.serviceInEffect = true;
    this.boatFilter.size = this.slingConfig != null ? this.slingConfig.numberOfRecordsPerPage : 10;
    this.findBoatsService();
  }

  getCustomerNames(boat: Boat): String {
    var customersName;
    if (!boat?.boatCustomers || boat.boatCustomers.length === 0) {
      customersName = '----'
    } else if (boat.boatCustomers.length > 2) {
      customersName = boat.boatCustomers[0].customer.name + ' e outros'
    } else if (boat.boatCustomers.length > 1) {
      customersName = boat.boatCustomers[0].customer.name + ' e outro'
    } else if (boat.boatCustomers.length = 1) {
      customersName = boat.boatCustomers[0].customer.name
    }
    return customersName;
  }

  getSailorNames(boat: Boat): String {
    var sailorsName;
    if (!boat?.boatSailors || boat.boatSailors.length === 0) {
      sailorsName = '----'
    } else if (boat.boatSailors.length > 2) {
      sailorsName = boat.boatSailors[0].sailor.name + ' e outros'
    } else if (boat.boatSailors.length > 1) {
      sailorsName = boat.boatSailors[0].sailor.name + ' e outro'
    } else if (boat.boatSailors.length = 1) {
      sailorsName = boat.boatSailors[0].sailor.name
    }
    return sailorsName;
  }

  async getIsCustomerService() {
    this.isCustomerServiceAvaliable = await this.operationalConfigService.isCustomerServiceAvaliable();
  }

  getServicesNames(boat: Boat): String {
    var servicesName;
    if (!boat.boatServices) {
      servicesName = '----'
    } else if (boat.boatServices.length > 2) {
      servicesName = boat.boatServices[0].service.name + ' e outros'
    } else if (boat.boatServices.length > 1) {
      servicesName = boat.boatServices[0].service.name + ' e outro'
    } else {
      servicesName = boat.boatServices[0].service.name;
    }
    return servicesName;
  }

  getGroupedRangeValueNumber(boat: Boat, type: String): number {
    return parseFloat(this.getGroupedRangeValue(boat, type));
  }

  getGroupedRangeValue(boat: Boat, type: String): string {
    let groupedDiscounts: number = 0;
    let groupedValue: number = 0;
    if (boat.boatServices) {
      boat.boatServices.forEach((boatService, index) => {
        const serviceValue = parseFloat(boatService.value.toString());
        switch (type) {
          case "rangeValue":
            const rangeValue = this.getRangeValue(boat.commercialLength, boatService);
            groupedValue += parseFloat(rangeValue);
            break;
          case "value":
            groupedValue += serviceValue;
            break;
          case "dueDateDiscount":
            groupedValue += boatService.dueDateDiscount;
            break;
          case "discount":
            groupedValue += boatService.discount;
            break;
          case "percentDiscount":
            groupedValue += serviceValue;
            groupedDiscounts += (boatService.discount ?? 0) + (boatService.dueDateDiscount ?? 0);
            if (index == boat.boatServices.length - 1) {
              groupedValue = groupedDiscounts / groupedValue;
            }
            if (isNaN(groupedValue)) {
              groupedValue = 0;
            }
            break;
          case "totalService":
            groupedValue += serviceValue;
            groupedDiscounts += boatService.discount + boatService.dueDateDiscount;
            if (index == boat.boatServices.length - 1) {
              groupedValue = groupedValue - groupedDiscounts;
            }
            break;

          default:
            break;
        }
      });
    }
    return groupedValue.toString();
  }

  receiveNoticiesParams() {
    this.route.queryParamMap
    .subscribe((params) => {
      this.orderObj = { ...params.keys, ...params };
      }
    );
  switch (this.orderObj.params.operation) {
      case '1':
        this.boatFilter.active = true;
        this.rangeDates = [moment(this.orderObj.params.date).toDate(), moment(this.orderObj.params.date).toDate()];
        break;
       case '2':
        this.rangeDatesStart = [moment(this.orderObj.params.date).toDate(), moment(this.orderObj.params.date).toDate()];
        break;
      case '3':
        this.rangeDatesEnd= [moment(this.orderObj.params.date).toDate(), moment(this.orderObj.params.date).toDate()];
        break;
      default:
        break;
      }
  }

  findBoatsService(): void {
    this.spinner.show();
    if (this.rangeDatesStart != undefined) {
      this.changeDateStart();
    }
    if (this.rangeDatesEnd != undefined) {
      this.changeDateEnd();
    }
    this.totalCommercialLength = 0;
    this.averageCommercialLength = 0;
    this.totalVlPe = 0;
    this.totalValue = 0;
    this.totalDesc = 0;
    this.totalDescVenc = 0;
    this.total = 0;
    this.boatFilter.size = 1;
    this.boatService.findByMarinaIdWithPaginationBoat(this.boatFilter).subscribe({
      next: (data) => {
        if (this.option == undefined) {
          this.boats = data;
        } else if (this.option === 'true') {
          this.boats = data.filter((b) => b.active);
        } else {
          this.boats = this.boats = data.filter((b) => !b.active);
        }

        this.boats.forEach((boat) => {
          if (this.boatFilter.fromServiceStart && this.boatFilter.toServiceStart) {
            boat.boatServices = boat.boatServices.filter((bservice) => {
              if (bservice.contractStartDate != null) {
              const contractStartDate = new Date(bservice.contractStartDate).setHours(0,0,0,0);
              const fromServiceStart = new Date(this.boatFilter.fromServiceStart).setHours(0,0,0,0);
              const toServiceStart = new Date(this.boatFilter.toServiceStart).setHours(23,59,59,999);
              return (
                contractStartDate >= fromServiceStart && contractStartDate <= toServiceStart
              );
            }});
          }
          if (this.boatFilter.fromServiceEnd && this.boatFilter.toServiceEnd) {
            boat.boatServices = boat.boatServices.filter((bservice) => {
            if (bservice.contractEndDate != null) {
              const contractEndDate = new Date(bservice.contractEndDate).setHours(0,0,0,0);;
              const fromServiceEnd = new Date(this.boatFilter.fromServiceEnd).setHours(0,0,0,0);
              const toServiceEnd = new Date(this.boatFilter.toServiceEnd).setHours(23,59,59,999);
              return (
                contractEndDate >= fromServiceEnd && contractEndDate <= toServiceEnd
              );
            }
          });
          }
          if (boat.boatServices.length > 0) {
          boat.boatServices.forEach((boatService) => {
            this.totalValue += boatService.value;
            this.totalDesc += boatService.discount;
            this.totalDescVenc += boatService.dueDateDiscount;
            this.total += (boatService.value - boatService.discount - boatService.dueDateDiscount);
          });

          this.totalCommercialLength += boat.commercialLength;
          boat.boatCustomers = boat.boatCustomers.filter((bcustomer) => bcustomer.type === 'Owner');
      }});
        this.boats = this.boats.filter((boat) => boat.boatServices.length > 0);
        this.averageCommercialLength = Number.parseFloat((this.totalCommercialLength / this.boats.length).toFixed(2));
      },
      error: (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      },
      complete: () => this.spinner.hide()});
  }

  changeDateStart() {
    if (this.rangeDatesStart[0] != null) {
      this.boatFilter.fromServiceStart = this.rangeDatesStart[0];
      if (this.rangeDatesStart[1] != null) {
        this.boatFilter.toServiceStart = this.rangeDatesStart[1];
      } else {
        this.boatFilter.toServiceStart = this.rangeDatesStart[0];
      }
    } else {
      this.boatFilter.fromServiceStart = null;
      this.boatFilter.toServiceStart = null;
    }
  }

  changeDateEnd() {
    if (this.rangeDatesEnd[0] != null) {
      this.boatFilter.fromServiceEnd = this.rangeDatesEnd[0];
      if (this.rangeDatesEnd[1] != null) {
        this.boatFilter.toServiceEnd = this.rangeDatesEnd[1];
      } else {
        this.boatFilter.toServiceEnd = this.rangeDatesEnd[0];
      }
    } else {
      this.boatFilter.fromServiceEnd = null;
      this.boatFilter.toServiceEnd = null;
    }
  }

  getRangeValueBoatService(commercialLength, boatServices: ServiceBoat[]): string {
    let ranges = '';
    boatServices.forEach(bs => ranges += ' ' + this.getRangeValue(commercialLength, bs) + ' |');
    return ranges.substring(0, ranges.length - 1);
  }

  getRangeValue(commercialLength, boatService): string {
    if (boatService.service.pricingType === PrincingType.SimplePrice) {
      return boatService.service.price.value;
    } else {
      const range = boatService.service.price.priceRanges
        .find((r) => commercialLength >= r.startLengthRange && commercialLength <= r.endLengthRange);
      if (range) {
        return range.value;
      }
    }
  }

  getPercentDiscountOfTotalService(boatService): number{
    return ((boatService.discount + boatService.dueDateDiscount) / boatService.value);
  }

  getTotalService(boatService): number{
    return boatService.value - ( boatService.discount + boatService.dueDateDiscount);
  }

  extract(): void {
    this.spinner.show();
    this.boatService.extractDetailServices(this.boatFilter).subscribe(
      (data) => {
        this.openExtract(
          'data:application/pdf;base64,'+ data
        );
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      },
      () => this.spinner.hide());
  }

  openExtract(path): void {
    const dialog = this.dialog.open(ExtractPdfGenericComponent, {
      width: '100%',
      height: '100%',
      data: {
        path
      },
      header: 'Lista detalhada de serviços'
    });
  }

  sumBoatServicesValue(boatServices: ServiceBoat[]): number {
    if (!this.boatService) {
      return;
    }
    let sum = 0;
    boatServices.forEach(bs => sum += bs.value);
    return sum;
  }

  sumBoatServicesDueDateDiscount(boatServices: ServiceBoat[]): number {
    if (!this.boatService) {
      return;
    }
    let sum = 0;
    boatServices.forEach(bs => sum += bs.dueDateDiscount ? bs.dueDateDiscount : 0);
    return sum;
  }

  sumBoatServicesDiscount(boatServices: ServiceBoat[]): number {
    if (!this.boatService) {
      return;
    }
    let sum = 0;
    boatServices.forEach(bs => sum += bs.discount);
    return sum;
  }

  async exportTable(): Promise<void> {
    var exportList = await this.findDataExport();
    const data = {table: exportList, type: 'DETAIL_BOATS'};
    this.dialog.open(CustomReportExportComponent, {
      width: '70%',
      height: '90vh',
      dismissableMask: false,
      data,
      header: this.messageUtil.translateKey('EXPORT-DETAIL_BOATS')
    });
  }

  getEarliestStartDate(boatServices: ServiceBoat[]): Date {
    if (!boatServices || boatServices.length === 0) {
      return;
    }
    const dates = boatServices.filter((date) => date.contractStartDate != null).map(service => service.contractStartDate);
    let earliestDate: Date;
    if(dates.length !== 0){
      earliestDate = dates.reduce((minDate, currentDate) => {
        return currentDate < minDate ? currentDate : minDate;
      });
      return new Date(earliestDate);
    }
  }

  getLatestEndDate(boatServices: ServiceBoat[]): Date {
    if (!boatServices || boatServices.length === 0) {
      return;
    }
    const dates = boatServices.filter((date) => date.contractEndDate != null).map(service => service.contractEndDate);
    let latestDate: Date;
    if(dates.length !== 0){
    const latestDate = dates.reduce((maxDate, currentDate) => {
      return currentDate < maxDate ? currentDate : maxDate;
    });
    return new Date(latestDate);
    }
  }

  async findDataExport(): Promise<any> {
    return new Promise(async (resolve) => {
      this.spinner.show();
      const values = await this.boats.map((boat) => this.formatDataExport(boat));
      this.spinner.hide()
      resolve(values);
      });
    };

  formatDataExport(boat: Boat): any{
    var formatedBoat;
    var commonProperties = {
      boatType: this.shipTypeOptions.find((tipo) => tipo.value == boat.shipyardModel.shipType).label,
      boatStatus: boat.active ? 'Ativo' : 'Inativo',
      boatName: boat.name,
      boatSailors: boat.boatSailors.map((sailor) => sailor.sailor.name).join(',\n'),
      vacancy: boat.vacancy?.code ?? '----',
      boatShipyard:boat.shipyardModel.shipyard.name,
      boatModel:boat.shipyardModel.name,
      commercialLength: boat.commercialLength,
      boatCustomers: boat.boatCustomers.map((customer) => customer.customer.name).join(',\n'),
      boatServices: boat.boatServices.map((service) => service.service.name).join(',\n'),
      boatServicesStartDate: boat.boatServices.map((service) => service.contractStartDate ?  moment(service.contractStartDate).format('DD/MM/YYYY'):'').join(',\n'),
      boatServicesEndDate: boat.boatServices.map((service) => service.contractEndDate ?  moment(service.contractEndDate).format('DD/MM/YYYY'):'').join(',\n'),
      boatServicesStatus: boat.boatServices.map((service) => service.active ? 'Ativo' : 'Inativo').join(',\n'),
    }
    if (this.groupedByBoats) {
      formatedBoat = {
        ...commonProperties,
        boatServicesValue: this.getGroupedRangeValueNumber(boat, "value"),
        boatServicesFeetValue: boat.boatServices.map((service) => this.getRangeValue(boat.commercialLength, service)).join(',\n'),
        boatServicesDueDateDiscount: this.getGroupedRangeValueNumber(boat, "dueDateDiscount"),
        boatServicesDiscount: this.getGroupedRangeValueNumber(boat, "discount"),
        boatServicesPercentageDiscount: this.getGroupedRangeValueNumber(boat, "percentDiscount"),
        boatServicesTotalValue: this.getGroupedRangeValueNumber(boat, "totalService"),
      }
    } else {
      formatedBoat = {
        ...commonProperties,
        boatServicesValue: boat.boatServices.map((service) => service.value).join(',\n'),
        boatServicesFeetValue: boat.boatServices.map((service) => this.getRangeValue(boat.commercialLength, service)).join(',\n'),
        boatServicesDueDateDiscount: boat.boatServices.map((service) => service.dueDateDiscount).join(',\n'),
        boatServicesDiscount: boat.boatServices.map((service) => service.discount).join(',\n'),
        boatServicesPercentageDiscount: boat.boatServices.map((service) => this.getPercentDiscountOfTotalService(service)).join(',\n'),
        boatServicesTotalValue: boat.boatServices.map((service) => this.getTotalService(service)).join(',\n'),
      }
    }
    return formatedBoat;
  }

  async exportExcel() {
    import("xlsx").then(xlsx => {
        let merges = [];
        const boats = this.generateTable(merges);
        let worksheet = xlsx.utils.aoa_to_sheet (boats);
        worksheet['!merges'] = merges;
        this.changeHeaderWorksheet(worksheet);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data']};
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "embarcações");
    });
  }

  generateTable(merges: any[]): any[][]{
    let table = [["","","","","","","","","","","","","",""]];
    let row = 1;
    for (const b of this.boats) {
      let len = Math.max(b.boatCustomers.length, b.boatServices.length);
      let lin1 = [... this.getlineInfBoat(b, true), ...this.getlineInfCustomer(b.boatCustomers[0]), ...this.getlineInfService(b,0)];

      table.push(lin1);
      for (let index = 1; index < len; index++) {
        table.push([... this.getlineInfBoat(b), ...this.getlineInfCustomer(b.boatCustomers[index]), ...this.getlineInfService(b,index)]);
      }
      this.generateMerges(merges,b,row,len);
      row += len != 0? len : 1;
    }
    return table;
  }

  generateMerges(merges: any[], boat, row, len){
    if(len>1){
      const end = row+len-1;
      this.mergeRows(0,2,row,end, merges);
      if(len>boat.boatCustomers.length){
        this.mergeRows(3,5,row+(boat.boatCustomers.length-1),end, merges);
      }
      if(len>boat.boatServices.length){
        this.mergeRows(6,13,row+(boat.boatServices.length-1), end, merges);
      }
    }
  }

  mergeRows(columStart, columEnd, rowStart, rowEnd, merges){
    for (let colum = columStart; colum <= columEnd; colum++) {
      merges.push(
        {
          s: {
            c: colum,
            r: rowStart
          },
          e: {
             c: colum,
             r: rowEnd
          }
        }
      )
    }
  }

  getlineInfBoat(boat, first = false): any[] {
    if(first){
      return [boat.name, this.getVacancyCode(boat.vacancy), boat.shipyardModel.shipType];

    }
    return  [undefined,undefined,undefined];
  }

  getVacancyCode(vacancy): string {
    return vacancy ? vacancy.code : '';
  }

  async loadVacancies(): Promise<void> {
    return new Promise(resolve => {
      this.vacancyService.getAll().subscribe(
        async (data) => {
          this.vacancies = data.map((v) => ({ label: v.code, value: v.id }));
          resolve();
        },
        async (error) => {
          this.vacancies = [];
          resolve();
        }
      );
    })
  }

  getlineInfCustomer(boatCustomer): any[]{
    if(boatCustomer){
      return [boatCustomer.customer.name, this.getGroupName(boatCustomer.customer.group), boatCustomer.quota];
    }
    return  [undefined,undefined,undefined];
  }

  getGroupName(group): string{
    return group ? group.name : '';
  }

  getlineInfService(boat: Boat, index): any[]{
    let boatService = boat.boatServices[index];
    if(boatService){
      return [boatService.service.name, boat.commercialLength, this.getRangeValue(boat.commercialLength, boatService),
        boatService.value, boatService.dueDateDiscount, boatService.discount,
        this.getPercentDiscountOfTotalService(boatService), this.getTotalService(boatService)];
    }
    return  [undefined,undefined,undefined,undefined,undefined,undefined,undefined,undefined];
  }

  changeHeaderWorksheet(worksheet: WorkSheet) {
    worksheet.A1.v = this.cols[0].header
    worksheet.B1.v = this.cols[1].header
    worksheet.C1.v = this.cols[2].header
    worksheet.D1.v = this.cols[3].header
    worksheet.E1.v = this.cols[4].header
    worksheet.F1.v = this.cols[5].header
    worksheet.G1.v = this.cols[6].header
    worksheet.H1.v = this.cols[7].header
    worksheet.I1.v = this.cols[8].header
    worksheet.J1.v = this.cols[9].header
    worksheet.K1.v = this.cols[10].header
    worksheet.L1.v = this.cols[11].header
    worksheet.M1.v = this.cols[12].header
    worksheet.N1.v = this.cols[13].header
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
          type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_' + new Date().getTime() + EXCEL_EXTENSION);
  }
}
