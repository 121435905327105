import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ServiceBoatReadjustmentDTO } from 'src/app/models/dtos/service-boat-readjustmentDTO';
import { ServiceCustomerReadjustmentDTO } from '../../../../../models/dtos/service-customer-readjustmentDTO';
@Component({
  selector: 'app-table-service-readjustment',
  templateUrl: './table-service-readjustment.component.html',
  styleUrls: ['./table-service-readjustment.component.scss'],
})
export class TableServiceReadjustmentComponent implements OnInit {
  @Input()
  serviceBoatReadjustment: ServiceBoatReadjustmentDTO[];
  @Input()
  serviceCustomerReadjustment: ServiceCustomerReadjustmentDTO[];

  @Output()
  eventApply: EventEmitter<any[]> = new EventEmitter();

  @Output() exportList = new EventEmitter<any[]>();

  totalValues = 0;
  totalDiscounts = 0;
  totalDueDateDiscounts = 0;
  totalReadjustmentsValues = 0;
  totalReadjustmentsDiscounts = 0;
  totalReadjustmentsDueDateDiscounts = 0;

  isServiceCustomerType = false;
  constructor() {}

  ngOnInit(): void {
    this.calcTotals();
  }
  calcTotals() {
    this.totalValues = 0;
    this.totalDiscounts = 0;
    this.totalDueDateDiscounts = 0;
    this.totalReadjustmentsValues = 0;
    this.totalReadjustmentsDiscounts = 0;
    this.totalReadjustmentsDueDateDiscounts = 0;

    if (
      this.serviceBoatReadjustment &&
      this.serviceBoatReadjustment.length > 0
    ) {
      this.isServiceCustomerType = false;
      this.serviceBoatReadjustment.forEach((service) => {
        this.calculateByService(service);
      });
    }
    if (
      this.serviceCustomerReadjustment !== null &&
      this.serviceCustomerReadjustment.length > 0
    ) {
      this.isServiceCustomerType = true;
      this.serviceCustomerReadjustment.forEach((sc) => {
        this.calculateByService(sc);
      });
    }
  }

  private calculateByService(
    service: ServiceBoatReadjustmentDTO | ServiceCustomerReadjustmentDTO
  ) {
    this.totalValues += service.value;
    this.totalDiscounts += service.discount;
    this.totalDueDateDiscounts += service.dueDateDiscount;
    this.totalReadjustmentsValues += service.readjustmentValue;
    this.totalReadjustmentsDiscounts += service.readjustmentDiscount;
    this.totalReadjustmentsDueDateDiscounts +=
      service.readjustmentDueDateDiscount;
  }

  calcTotalValueTotal(): number {
    const totalValuesTotal = this.totalValues - this.totalDiscounts;
    return totalValuesTotal > 0 ? totalValuesTotal : 0;
  }

  calcTotalDiscountsReadjustments(): void {
    let total: number = 0;
    if (this.serviceBoatReadjustment) {
      total = this.serviceBoatReadjustment
        .map((s) => (s.readjustmentDiscount ? s.readjustmentDiscount : 0))
        .reduce((total, value) => {
          return (total += value);
        }, 0);
    }
    this.totalReadjustmentsDiscounts = total;

    this.exportList.emit(this.serviceBoatReadjustment);
  }

  calcTotalDueDateDiscountsReadjustments(): void {
    let total: number = 0;
    if (this.serviceBoatReadjustment) {
      total = this.serviceBoatReadjustment
        .map((s) =>
          s.readjustmentDueDateDiscount ? s.readjustmentDueDateDiscount : 0
        )
        .reduce((total, value) => {
          return (total += value);
        }, 0);
    }
    if (this.serviceCustomerReadjustment && this.isServiceCustomerType) {
      total = this.serviceCustomerReadjustment
        .map((s) =>
          s.readjustmentDueDateDiscount ? s.readjustmentDueDateDiscount : 0
        )
        .reduce((total, value) => {
          return (total += value);
        }, 0);
    }
    this.totalReadjustmentsDueDateDiscounts = total;

    this.exportList.emit(this.serviceBoatReadjustment);
  }

  calcReadjustementTotalValueTotal(): number {
    const totalValuesTotal =
      this.totalReadjustmentsValues -
      this.totalReadjustmentsDiscounts -
      this.totalReadjustmentsDueDateDiscounts;
    return totalValuesTotal > 0 ? totalValuesTotal : 0;
  }

  differenceTotals(): number {
    return (
      this.totalReadjustmentsValues -
      this.totalValues -
      (this.totalReadjustmentsDiscounts - this.totalDiscounts) -
      (this.totalReadjustmentsDueDateDiscounts - this.totalDueDateDiscounts)
    );
  }

  enableReadjustment() {
    return (
      this.serviceBoatReadjustment?.length > 0 ||
      this.serviceCustomerReadjustment?.length > 0
    );
  }

  applyReadjustment() {
    if (this.isServiceCustomerType) {
      this.eventApply.emit(this.serviceCustomerReadjustment);
    } else {
      this.eventApply.emit(this.serviceBoatReadjustment);
    }
  }

  totalReajustment(
    service: ServiceBoatReadjustmentDTO | ServiceCustomerReadjustmentDTO
  ): number {
    if (service.readjustmentValue) {
      return (
        service.readjustmentValue -
        service.readjustmentDiscount -
        service.readjustmentDueDateDiscount
      );
    }
    return;
  }

  differenceReajustment(
    service: ServiceBoatReadjustmentDTO | ServiceCustomerReadjustmentDTO
  ): number {
    if (service.readjustmentValue) {
      return this.totalReajustment(service) - this.totalOldValue(service);
    }
    return;
  }

  totalOldValue(
    service: ServiceBoatReadjustmentDTO | ServiceCustomerReadjustmentDTO
  ): number {
    return service.value - service.discount - service.dueDateDiscount;
  }

  getStyleValueReadjustment(service) {
    let style = '';
    if (this.totalReajustment(service) < 0) {
      style = 'negative-value';
    } else if (this.totalReajustment(service) < this.totalOldValue(service)) {
      style = 'not-changed-value';
    }
    return style;
  }
}
