<p-fieldset>
    <p-header class="article-title">
        <span class="article-title">{{ 'TITLE-BILLING-INVOICE' | translate }}</span>
        <div class="header-buttons">
            <button class="fab-button primary" (click)="exportTableExcel()" label="Exportar valores"
                pTooltip="Exportar Valores" tooltipPosition="left">
                <i class="pi pi-file"></i>
            </button>
            <button class="fab-button primary" appFiltersButton pTooltip="Exibir/Ocultar Filtros"
                tooltipPosition="left">
                <i class="pi pi-filter"></i>
            </button>
            <button class="danger fab-button" (click)="openModalHelp()">
                <i class="pi pi-question" style="font-size: 21px;"></i>
            </button>

        </div>
    </p-header>

   

    <form class="filters" (submit)="searchInvoices()" appFilters>
        <div class="filter-row">
            <div class="form-control" *ngIf="marinaCompanies?.length > 0">
                <label>{{'COMPANY' | translate}}</label>
                <p-dropdown [filter]="true" [options]="filteredCompanies" name="selectedCompany" appendTo="body"
                    (onChange)="changeCompany($event)" [title]="'COMPANY' | translate" [(ngModel)]="selectedCompany">
                </p-dropdown>
            </div>
            <div class="form-control">
                <label>{{'CUSTOMER' | translate}}</label>
                <p-dropdown [filter]="true" [options]="filteredCustomers" [(ngModel)]="selectedCustomerId" appendTo="body" 
                    name="selectedCustomerId">
                </p-dropdown>
            </div>
            <div class="form-control">
                <label>{{'GROUPS' | translate}}</label>
                <p-multiSelect [options]="groups" [(ngModel)]="filter.groupsList" appendTo="body"
                    selectedItemsLabel="{0} itens selecionados" name="groupsList">
                </p-multiSelect>
            </div>
            <div class="form-control">
                <label>{{'MIN-INVOICE-NUMBER' | translate}}</label>
                <input pInputText type="number" [(ngModel)]="filter.invoiceNumberStart" name="invoiceNumberStart">
            </div>
            <div class="form-control">
                <label>{{'MAX-INVOICE-NUMBER' | translate}}</label>
                <input pInputText type="number" [(ngModel)]="filter.invoiceNumberEnd" name="invoiceNumberEnd">
            </div>
            <div class="form-control">
                <label>{{'NF-NUMBER' | translate}}</label>
                <input pInputText type="number" [(ngModel)]="filter.nfeNumber" name="nfeNumber">
            </div>
            <div class="form-control">
                <label>{{'STATUS' | translate}}</label>
                <p-multiSelect [options]="statusList" [style]="{height: '30px'}" [(ngModel)]="filter.status"
                    appendTo="body" name="status" selectedItemsLabel="{0} itens selecionados">
                </p-multiSelect>
            </div>
            <div class="form-control">
                <label>{{'EMISSION-FROM' | translate}}</label>
                <p-calendar dateFormat="dd/mm/yy" name="startEmission" [(ngModel)]="filter.startEmission" appendTo="body" 
                    [showIcon]="true" i18n [monthNavigator]="true" [yearNavigator]="true" [yearRange]="'2000:2055'" rPCalendarMask>
                </p-calendar>
            </div>
            <div class="form-control">
                <label>{{'EMISSION-TO' | translate}}</label>
                <p-calendar 
                   dateFormat="dd/mm/yy" name="endEmission" [monthNavigator]="true" [yearNavigator]="true" appendTo="body" 
                   [yearRange]="'2000:2055'" rPCalendarMask [(ngModel)]="filter.endEmission" [showIcon]="true" i18n>
                </p-calendar>
            </div>
            <div class="form-control">
                <label>{{'DUE-FROM' | translate}}</label>
                <p-calendar dateFormat="dd/mm/yy" name="startDueDate" [monthNavigator]="true" [yearNavigator]="true"
                    appendTo="body" [yearRange]="'2000:2055'" rPCalendarMask [(ngModel)]="filter.startDueDate"
                    [showIcon]="true" i18n>
                </p-calendar>
            </div>
            <div class="form-control">
                <label>{{'DUE-TO' | translate}}</label>
                <p-calendar dateFormat="dd/mm/yy" name="endDueDate" [monthNavigator]="true" [yearNavigator]="true"
                    appendTo="body" [yearRange]="'2000:2055'" rPCalendarMask [(ngModel)]="filter.endDueDate"
                    [showIcon]="true" i18n>
                </p-calendar>
            </div>


            <div class="form-control">
                <label>{{'COMPETENCE-FROM' | translate}}</label>
                <p-calendar dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true" 
                appendTo="body" [yearRange]="'2000:2055'" rPCalendarMask [showIcon]="true" i18n 
                name="startCompetenceDate"
                    [(ngModel)]="filter.startCompetenceDate"
                    >
                </p-calendar>
            </div>
            <div class="form-control">
                <label>{{'COMPETENCE-TO' | translate}}</label>
                <p-calendar dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true" 
                appendTo="body" [yearRange]="'2000:2055'" rPCalendarMask [showIcon]="true" i18n 
                name="endCompetenceDate"
                    [(ngModel)]="filter.endCompetenceDate"
                    >
                </p-calendar>
            </div>


            <div class="form-control">
                <label>{{'PAYMENT-FROM' | translate}}</label>
                <p-calendar dateFormat="dd/mm/yy" name="startPayment" [monthNavigator]="true" [yearNavigator]="true"
                    appendTo="body" [yearRange]="'2000:2055'" rPCalendarMask [(ngModel)]="filter.startPayment"
                    [showIcon]="true" i18n>
                </p-calendar>
            </div>
            <div class="form-control">
                <label>{{'PAYMENT-TO' | translate}}</label>
                <p-calendar dateFormat="dd/mm/yy" name="endPayment" [monthNavigator]="true" [yearNavigator]="true"
                    appendTo="body" [yearRange]="'2000:2055'" rPCalendarMask [(ngModel)]="filter.endPayment"
                    [showIcon]="true" i18n>
                </p-calendar>
            </div>
            <div class="form-control">
                <label>{{'ORIGIN' | translate}}</label>
                <p-multiSelect appendTo="body" 
                optionDisabled="inactive" 
                [options]="originOptions"
                    [(ngModel)]="filter.origin" name="filter.origin" selectedItemsLabel="{0} itens selecionados">
                </p-multiSelect>
            </div>
            <div class="form-control">
                <label>{{'BILLET' | translate}}</label>
                <p-dropdown [options]="yesNoOptions" [(ngModel)]="filter.withBillets" name="filter.withBillets"
                    appendTo="body">
                </p-dropdown>
            </div>
            <div class="form-control" *ngIf="hasInvoiceReceipt()">
                <label>Recibo</label>
                <p-dropdown [options]="yesNoOptions" [(ngModel)]="filter.withReceipts" name="filter.withReceipts"
                    appendTo="body">
                </p-dropdown>
            </div>
            <div class="form-control">
                <label>{{'RECEIPT' | translate}}</label>
                <p-dropdown [options]="yesNoOptions" [(ngModel)]="filter.withDocumentsNF" name="filter.withDocumentsNF"
                    appendTo="body">
                </p-dropdown>
            </div>
            <div class="form-control">
                <label>{{'EMAIL' | translate}}</label>
                <p-dropdown [options]="yesNoOptions" [(ngModel)]="filter.withSendEmail" name="filter.withSendEmail"
                    appendTo="body">
                </p-dropdown>
            </div>
            <div class="form-control">
                <label>{{'TAX-PRODUCT' | translate}}</label>
                <p-dropdown [options]="yesNoOptions" [(ngModel)]="filter.withProductNf" name="filter.withProductNf"
                    appendTo="body">
                </p-dropdown>
            </div>
        </div>
        <div class="filter-row">
            <div class="buttons">
                <div class="form-button">
                    <button (click)="clear()" class="danger easymarine" type="button">{{ 'CLEAR' | translate }}</button>
                </div>
                <div class="form-button">
                    <button class="primary easymarine" type="submit">{{ 'FIND' | translate }}</button>
                </div>
            </div>
        </div>
    </form>

    <div class="container">
        <!-- Tabela -->
        <p-table #invoiceTable *ngIf="invoicesTable" [columns]="cols" [value]="invoicesTable" 
            resetPageOnSort="true" [paginator]="true" [rows]="paginationFilter.size" 
            [rowsPerPageOptions]="[100, 200, 500]" styleClass="easy-table" autoLayout="true"
            [globalFilterFields]="['nfeNumber', 'invoiceNumber', 'customer.group.name','customer.name']"
            [(selection)]="selectedInvoices" dataKey="id" [lazy]="true" (onLazyLoad)="onChangePage($event)"
            [totalRecords]="totalRecords">

            <!-- Botões legenda topo -->
            <ng-template pTemplate="caption">
                <div class="table-header">
                    <div class="text-left button-actions">
                        <!-- <button class="easymarine btn-csv-xls" type="button" (click)="exportTableExcel()"
                        [pTooltip]="'Exporta todos os registros'" tooltipPosition="bottom">
                            Exportar Excel</button> -->

                        <button class="primary easymarine" type="button" (click)="checkAndGenerateBillets()"
                            *ngIf="!marinaCompanies || marinaCompanies?.length === 0 || selectedCompany?.id">Gerar Boleto</button>

                            <button class="primary easymarine" type="button" (click)="checkAndGenerateBillets(true)"
                            *ngIf="(loggedMarina.id===230 || loggedMarina.id===2)">Gerar Boleto Agrupados</button>

                        <button class="primary easymarine" type="button" 
                        (click)="checkAndGenerateDocuments()"
                            *ngIf="!marinaCompanies || marinaCompanies?.length === 0 || selectedCompany?.id">Gerar NF</button>

                        <button class="primary easymarine" type="button" 
                        (click)="generateInvoiceReceipt()" *ngIf="hasInvoiceReceipt()">Gerar Recibos</button>

                        <button [disabled]="!isActiveSendEmail()" class="primary easymarine" type="button"
                            (click)="checkAndSendEmails()">Enviar E-mails</button>

                        <span *ngIf="!isActiveSendEmail()" class="text-center">
                            <img src="assets/images/loading.gif" alt="" style="width: 15px; height: 15px;">
                        </span>

                        <button class="primary easymarine" (click)="addEmails()" type="button"
                            [pTooltip]="'Permite enviar a fatura para outros endereços de email'"
                            tooltipPosition="bottom" *ngIf="selectedInvoices?.length <= 1">
                            Enviar Cópia </button>
                        <button class="success easymarine" (click)="checkAndSendEmails(true)" type="button"
                            [pTooltip]="loggedMarina.serverEmailOwn.emailAddress" color="success" [disabled]="!isActiveSendEmail()" 
                            tooltipPosition="bottom" *ngIf="loggedMarina?.hasServerEmailOwn">
                            Enviar E-mail Próprio </button>
                        <span *ngIf="loggedMarina?.hasServerEmailOwn && !isActiveSendEmail()" class="text-center">
                            <img src="assets/images/loading.gif" alt="" style="width: 15px; height: 15px;">
                        </span>
                        <button class="success easymarine" (click)="addEmails(true)" type="button"
                            [pTooltip]="loggedMarina.serverEmailOwn.emailAddress" tooltipPosition="bottom"
                            *ngIf="loggedMarina?.hasServerEmailOwn && this.selectedInvoices?.length <= 1">
                            Enviar Copia Próprio
                        </button>
                        <button class="primary easymarine" (click)="updateInvoiceDates()" type="button"
                            [pTooltip]="'Permite alterar datas de vencimento e de competência'" 
                            tooltipPosition="bottom" *ngIf="selectedInvoices?.length >= 1">
                            Alterar datas </button>
                        <button class="primary easymarine" (click)="changeDiscount()" type="button"
                            [pTooltip]="'Permite dar desconto na fatura selecionada'" 
                            tooltipPosition="bottom" *ngIf="selectedInvoices?.length == 1 && slingConfig.discountDirect">
                            Desconto </button>
                        <button class="primary easymarine" (click)="parcelInvoice()" type="button"
                            [pTooltip]="'Permite parcelar a fatura em vários boletos.'" tooltipPosition="bottom"
                            *ngIf="!marinaCompanies || marinaCompanies?.length === 0 || selectedCompany?.id">
                            Parcelar fatura </button>
                        <p-fileUpload chooseLabel="Importar DANFE" mode="basic" name="danfe" customUpload="true" #upload
                            id="upload" [auto]="true" chooseIcon="" (uploadHandler)="onBasicUpload($event.files)"
                            multiple="true"></p-fileUpload>
                        <button class="primary easymarine" (click)="uploadNf()" type="button"
                            [pTooltip]="'Importa uma nota fiscal e a relaciona a fatura.'" 
                            tooltipPosition="bottom" *ngIf="selectedInvoices?.length == 1  && validImportNf()===true">
                            Importar NF </button>
                    </div>
                    <!-- && validImportNf===true -->
                    <span style="font-weight: bold">{{ 'TOTAL-REGISTERS' | translate }}:
                        {{invoicesTable.length}} {{'OF' | translate}}
                        {{invoiceTable.totalRecords}}</span>
                </div>
                <div class="table-header center">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input type="text" pInputText size="50" placeholder="Filtro por NF, Fat, Cliente e Grupo"
                            [(ngModel)]="globalFilter" (input)="filterGlobal()" style="width:auto">
                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th></th>
                    <th colspan="4" class="text-right">
                        <span *ngIf="getSelecteds() > 0">
                            {{getSelecteds()}} fatura(s) selecionada(s)
                        </span>
                    </th>
                    <th></th>
                    <th *ngIf="hasInvoiceReceipt()"></th>
                    <th></th>
                    <th *ngIf="nfpEmmiter"></th>
                    <th></th>
                    <th></th>
                    <th *ngIf="marinaCompanies?.length > 0"></th>
                    <th></th>
                    <th></th>
                    <th *ngIf="hasInvoiceByWhatsapp()"></th>
                    <th></th>
                    <th class="text-right">{{ totalBilling | number:'1.2-2' }}</th>
                    <th class="text-right">{{ totalDiscount | number:'1.2-2' }}</th>
                    <th class="text-right">{{ totalInterest | number:'1.2-2' }}</th>
                    <th class="text-right">{{ totalPending | number:'1.2-2' }}</th>
                    <th class="text-right">{{ totalPaid | number:'1.2-2' }}</th>
                    <th></th>
                </tr>
                <tr>
                    <th class="text-center">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th></th>
                    <th class="text-center">Boleto</th>
                    <th class="text-center" *ngIf="hasInvoiceReceipt()">Recibo</th>
                    <th class="text-center" [pSortableColumn]="'nfeNumber'">
                        NF
                        <p-sortIcon [field]="'nfeNumber'"></p-sortIcon>
                    </th>
                    <th *ngIf="nfpEmmiter" class="text-center" [pSortableColumn]="'nfeNumberProduct'">
                        NFP
                        <p-sortIcon [field]="'nfeNumberProduct'"></p-sortIcon>
                    </th>
                    <th class="text-center">Email</th>
                    <th class="text-center" [pSortableColumn]="'invoiceNumber'">
                        Fat.
                        <p-sortIcon [field]="'invoiceNumber'"></p-sortIcon>
                    </th>
                    <th class="text-center" *ngIf="marinaCompanies?.length > 0">Empresa</th>
                    <th class="text-center" pTooltip="Origem">Ori.</th>
                    <th [pSortableColumn]="'customer.name'">
                        Cliente
                        <p-sortIcon [field]="'customer.name'"></p-sortIcon>
                    </th>
                    <th *ngIf="hasInvoiceByWhatsapp()">Whatsapp</th>
                    <th class="text-center" [pSortableColumn]="'customer.group.name'">
                        Grupo
                        <p-sortIcon [field]="'customer.group.name'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'issueDate'" pTooltip="Data de emissão">
                        Data
                        <p-sortIcon [field]="'issueDate'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'dueDate'">
                        Venc.
                        <p-sortIcon [field]="'dueDate'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'competenceDate'">
                        Comp.
                        <p-sortIcon [field]="'competenceDate'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'value'">
                        Total
                        <p-sortIcon [field]="'value'"></p-sortIcon>
                    </th>
                    <th class="text-center">
                        Desc.
                    </th>
                    <th class="text-center">
                        Juros
                    </th>
                    <th class="text-center">
                        Pend.
                    </th>
                    <th class="text-center">
                        Pago
                    </th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-invoice>
                <tr [ngClass]="{'canceled': invoice.status === 'CANCELED'}">
                    <td class="text-center">
                        <p-tableCheckbox [value]="invoice" [ngClass]="{'error' : wrongInvoice(invoice)}"
                            *ngIf="invoice.orders.length !== 0 && invoice.status !== 'CANCELED'" (click)="removeMessageWrongInvoice(invoice)"
                            [pTooltip]="wrongInvoice(invoice) ? getTooltipError(invoice) : ''" tooltipPosition="bottom">
                        </p-tableCheckbox>
                    </td>
                    <td class="text-center">
                        <span class="icons"
                            [pTooltip]="invoice.paymentStatus === 'InDay' ? 'À vencer' : invoice.paymentStatus === 'Paid' ? 'Pago' : 'Fatura vencida'"
                            tooltipPosition="bottom"
                            (click)="invoice.pendingValue !== 0 && invoice.orders.length !== 0 && openInvoiceDetailDialog(invoice)"
                            *ngIf="invoice.orders.length !== 0 && invoice.status !== 'CANCELED'">
                            <fa-icon *ngIf="invoice.paymentStatus === 'InDay'" [icon]="['far', 'calendar-alt']"
                                class="blue-icon">
                            </fa-icon>
                            <fa-icon *ngIf="invoice.paymentStatus === 'Paid'" [icon]="['far', 'calendar-check']"
                                class="green-icon"></fa-icon>
                            <fa-icon *ngIf="invoice.paymentStatus === 'Late'" [icon]="['far', 'calendar-times']"
                                class="red-icon">
                            </fa-icon>
                        </span>
                    </td>

                    <td class="text-center easy-bank">
                        <div
                            *ngIf="getAllActiveBillets(invoice).length > 1 || (getAllActiveBillets(invoice).length === 0 && invoice?.invoiceBankingBillets?.length > 1)">
                            <span  class="container-N" style="cursor:pointer; position: relative;"
                                *ngIf="!checkIfHaveNixBillet(invoice); else nixManyBillets" 
                                (click)="openBilletsModal(invoice)">
                                   <app-barcode-icon *ngIf="invoice.status !== 'PAID'" [styleBillet]="'registered'"></app-barcode-icon>
                                   <app-barcode-icon *ngIf="invoice.status === 'PAID'" [styleBillet]="'paid'"></app-barcode-icon>
                                <div  class="container-N num-billets" *ngIf="getAllActiveBillets(invoice).length === 0">
                                    <app-barcode-icon *ngIf="invoice.status !== 'PAID'" [styleBillet]="'canceled'"></app-barcode-icon>
                                    <div>
                                        <sup 
                                            *ngIf="invoice.invoiceBankingBillets && invoice.invoiceBankingBillets.length > 1">
                                            {{getNumBilletsFromInvoice(invoice)}}
                                        </sup>
                                    </div>
                                </div>

                            </span>

                            <ng-template #nixManyBillets>
                                <div class="nix-bank" (click)="openBilletsModal(invoice)"
                                    style="cursor:pointer;">
                                    <div *ngIf="getAllActiveBillets(invoice).length === 1">
                                        <app-barcode-icon [styleBillet]="getNixBankIcon(invoice)"></app-barcode-icon>
                                    </div>
                                    <div  *ngIf="getAllActiveBillets(invoice).length !== 1" class="container-N">
                                        <app-barcode-icon [styleBillet]="getNixBankIcon(invoice)" [isNix]=true ></app-barcode-icon>
                                       <span class="font-color-black">
                                            {{getNumBilletsFromInvoice(invoice)}}B
                                        </span>
                                    </div>
                                </div>
                            </ng-template>
                        </div>

                        <span style="cursor:pointer;" 
                          *ngIf="getAllActiveBillets(invoice).length === 1"
                            (click)="openBilletsModal(invoice)">
                            <div class="container-N" *ngIf="!checkIfHaveNixBillet(invoice); else nixSingleBillets">
                                <ng-container [ngSwitch]="getColorBillertActive(getAllActiveBillets(invoice)[0])" >
                                    <ng-container *ngSwitchCase="1">
                                        <app-barcode-icon [styleBillet]="'paid'" ></app-barcode-icon>
                                    </ng-container>
                                   <ng-container *ngSwitchCase="2" >
                                        <app-barcode-icon [styleBillet]="'refused'" ></app-barcode-icon>
                                   </ng-container>
                                   <ng-container class="num-billets" *ngSwitchCase="3">
                                        <app-barcode-icon  [styleBillet]="'pending'"></app-barcode-icon>
                                   </ng-container>
                                   <ng-container *ngSwitchCase="4">
                                        <app-barcode-icon [styleBillet]="'waiting'" ></app-barcode-icon>
                                   </ng-container>
                                   <ng-container *ngSwitchDefault>
                                        <app-barcode-icon [styleBillet]="'registered'" ></app-barcode-icon>
                                   </ng-container>
                                </ng-container>
                                    <sup class="num-billets" style="position: absolute;"
                                        *ngIf="invoice.invoiceBankingBillets && invoice.invoiceBankingBillets.length > 1">
                                        {{getNumBilletsFromInvoice(invoice)}}
                                    </sup>
                            </div>
                            <ng-template #nixSingleBillets>
                                <div class="nix-bank registeredN" class="container-N">
                                    <div  class="container-N " *ngIf="invoice.invoiceBankingBillets.length > 1">
                                        <app-barcode-icon tooltipPosition="right" [styleBillet]="'registeredN'" [isNix]=true  ></app-barcode-icon>
                                        <div>
                                            <sup 
                                                *ngIf="invoice.invoiceBankingBillets && invoice.invoiceBankingBillets.length > 1"
                                                class="font-color-black2">
                                                {{getNumBilletsFromInvoice(invoice)}}
                                            </sup>
                                        </div>
                                    </div>
                                    <app-barcode-icon *ngIf="invoice.invoiceBankingBillets.length === 1" [styleBillet]="getNixBankIcon(invoice)" [isNix]=true  ></app-barcode-icon>
                                    <div *ngIf="getAllActiveBillets(invoice).length === 0">
                                        <app-barcode-icon [styleBillet]="'registered'" [isNix]=true  ></app-barcode-icon>
                                        <span
                                            *ngIf="invoice.invoiceBankingBillets && invoice.invoiceBankingBillets.length > 1"
                                            class="font-color-black2">
                                            {{getNumBilletsFromInvoice(invoice)}}B
                                        </span>
                                    </div>
                                </div>
                            </ng-template>
                        </span>

                        <span style="cursor:pointer;"
                            *ngIf="getAllActiveBillets(invoice).length === 0 && invoice?.invoiceBankingBillets?.length === 1"
                             (click)="openBilletsModal(invoice)">
                            <div *ngIf="!checkIfHaveNixBillet(invoice); else nixSingleCanceledBillets">
                                <span style="cursor:pointer; position: relative;">
                                    <app-barcode-icon [styleBillet]="'canceled'"  ></app-barcode-icon>
                                </span>
                            </div>
                            <ng-template #nixSingleCanceledBillets>
                                <div class="nix-bank">
                                    <app-barcode-icon [styleBillet]="'canceled'" [isNix]=true ></app-barcode-icon>
                                </div>
                            </ng-template>
                        </span>

                        <ng-container *ngIf="invoice.value === 0 || (invoice.pendingValue === 0 && (!invoice.invoiceBankingBillets || invoice.invoiceBankingBillets.length === 0)) && !checkBilletsShared(invoice)">
                            <app-barcode-icon [styleBillet]="'empty'"></app-barcode-icon>
                        </ng-container>
                        <div  >
                            <div *ngIf="checkBilletsShared(invoice)">
                                <div *ngIf="invoice.status === 'PAID'">
                                    <app-barcode-icon [styleBillet]="'paid'" [isShared]=true ></app-barcode-icon>
                                </div>
                                <div *ngIf="invoice.status === 'PENDING'">
                                    <app-barcode-icon [styleBillet]="'pending'" [isShared]=true ></app-barcode-icon>
                                </div>
                                <div *ngIf="invoice.status === 'CANCELED'">
                                    <app-barcode-icon [styleBillet]="'canceled'" [isShared]=true ></app-barcode-icon>
                                </div>
                            </div>
                        </div>


                    </td>

                    <td class="text-center" *ngIf="hasInvoiceReceipt()">
                        <a *ngIf="invoice.invoiceReceipt" class="invoice-receipt" 
                            [href]="invoice.invoiceReceipt.url" target="_blank" [pTooltip]="Recibo" 
                            tooltipPosition="bottom" tooltipPosition="top">
                            <app-receipts-icon></app-receipts-icon>
                        </a>
                    </td>
                    <td class="text-center">
                        <a *ngIf="invoice.documentId && invoice.serviceDocument" class="paidOrder"
                            [href]="invoice.serviceDocument" target="_blank" [pTooltip]="getLastStatusFromNF(invoice)"
                            tooltipPosition="bottom" tooltipPosition="top">
                            {{invoice.nfeNumber}}
                        </a>

                        <a *ngIf="invoice.documentId && (!invoice.serviceDocument)" class="paidOrder"
                          [pTooltip]="getLastStatusFromNF(invoice) + ' Sem PDF'"
                          tooltipPosition="bottom" tooltipPosition="top">
                          {{invoice.nfeNumber}}
                        </a>

                        <span class="red-icon"
                            *ngIf="getLastStatusFromNF(invoice) && getLastStatusFromNF(invoice).includes('Negada')"
                            [pTooltip]="getLastStatusFromNF(invoice)" tooltipPosition="top">NEG</span>

                    

                        <a *ngIf="invoice.documentId && (!invoice.serviceDocument || invoice.serviceDocument == null) &&
                            (getLastStatusFromNF(invoice) && !getLastStatusFromNF(invoice).includes('Negada') && !getLastStatusFromNF(invoice).includes('Cancelada')
                            && !getLastStatusFromNF(invoice).includes('Importada'))"
                            style="color: #000" [pTooltip]="getLastStatusFromNF(invoice)" tooltipPosition="top">
                            <i class="pi pi-clock"></i>
                        </a>

                        <i class="pi pi-ban red-icon" style="cursor: unset !important;" *ngIf="invoice.value === 0"></i>
                    </td>
                    <td class="text-center" *ngIf="nfpEmmiter">
                        <a *ngIf="invoice.nfpNumberSerie!=null" class="paidOrder" [href]="invoice.nfpPdfUrl" target="_blank"
                         [pTooltip]="'Nota Fiscal'"  tooltipPosition="top">
                            {{invoice.nfpNumberSerie}}
                        </a>
                    </td>
                    <td class="text-center">
                        <a (click)="openInvoiceEmailDetailsDialog(invoice)" style="cursor: pointer; position: relative;"
                            *ngIf="invoice.emailsLog.length != 0">
                            <i class="pi pi-envelope" [ngClass]="{ 'red-icon': hasLastLogWithError(invoice) }"
                                *ngIf="invoice.emailsLog.length !== 0">
                            </i>
                            <i class="pi pi-circle-on" *ngIf="invoice.emailsLog.length !== 0 && hasNewLog(invoice)"
                                style="color: #2196F3; font-size: 7px; position: absolute;top: 0; right: -3px"></i>
                        </a>
                    </td>

                    <td class="text-center">
                        <span *ngIf="!invoice.legacyInvoiceNumber" class="paidOrder"
                            (click)="extract(invoice.id)">{{invoice.invoiceNumber}}</span>
                        <span *ngIf="invoice.legacyInvoiceNumber" class="paidOrder"
                            (click)="extract(invoice.id)">{{invoice.invoiceNumber}} <br>
                            {{invoice.legacyInvoiceNumber}}</span>
                    </td>

                    <td class="text-center" *ngIf="marinaCompanies?.length > 0">
                        <span *ngIf="invoice.marinaCompany" [pTooltip]="invoice.marinaCompany.companyName"
                            tooltipPosition="top">{{getMarinaCompanyIndex(invoice.marinaCompany)}}</span>
                        <span [pTooltip]="loggedMarina?.companyName" tooltipPosition="bottom"
                            *ngIf="!invoice.marinaCompany">1</span>
                    </td>
                    <td class="text-center" [pTooltip]="getTextToolTipRefDateTuitionOrigin(invoice)">
                        <ng-container [ngSwitch]="getIconOrigin(invoice)" tooltipPosition="bottom">
                            <ng-container *ngSwitchCase="'MULTIPLE'">
                                <fa-icon [icon]="'chart-pie'" style="font-size: 21px;"></fa-icon>
                            </ng-container>
                            <ng-container *ngSwitchCase="'DETACHED'">
                                <fa-icon [icon]="'shopping-cart'" style="font-size: 21px"></fa-icon>
                            </ng-container>
                            <ng-container *ngSwitchCase="'SLING'">
                                <fa-icon [icon]="'ship'" style="font-size: 21px;"></fa-icon>
                            </ng-container>
                            <ng-container *ngSwitchCase="'TUITION'">
                                <fa-icon [icon]="'university'" style="font-size: 21px;"></fa-icon>
                            </ng-container>
                            <div *ngSwitchCase="'FRANCHISE'">
                                <img [src]="'../../../assets/icons/Franquia.svg'" style="font-size: 21px"/>
                            </div>
                            <ng-container *ngSwitchCase="'LEGACY_IMPORT'">
                                <i class="pi pi-save"></i>
                            </ng-container>
                            <ng-container *ngSwitchCase="'WORKSHOP'">
                                <fa-icon [icon]="'tools'" style="font-size: 21px"></fa-icon>
                            </ng-container>

                        </ng-container>
                    </td>

                    <td>
                        <span
                            [pTooltip]="invoice.orders.length !== 0 ? invoice.customer.emailCollectionArray.join('\n') : ''"
                            tooltipPosition="top">
                            {{invoice.customer.name}}
                        </span>
                    </td>
                    <td *ngIf="hasInvoiceByWhatsapp()">
                        <a *ngIf="invoice.customer.phone">
                            <button (click)="sendToWhatsapp(invoice)" class="easymarine success whatsapp-link">
                                <fa-icon size="2x" [icon]="['fab','whatsapp']"></fa-icon>
                            </button>
                        </a>
                    </td>
                    <td>
                        {{invoice.customer.group.name}}
                    </td>

                    <td class="text-center">
                        {{invoice.issueDate | date: 'dd/MM/yy'}}
                    </td>
                    <td class="text-center"
                        [ngClass]="{'pointer': invoice.status !== 'PAID' && invoice.status !== 'CANCELED'}"
                        (click)="canChangeDueDateByStatus(invoice) && showChangeDueDate(invoice)">
                        {{invoice.dueDateToPay | date:'dd/MM/yy'}}
                    </td>


                    <td class="text-center" 
                    [ngClass]="{'pointer': true }" 
                    (click)="showChangeCompetenceDate(invoice)">
                        {{invoice.competenceDate | date:'dd/MM/yy'}}
                    </td>


                    <td class="text-right" [ngStyle]="{'color': invoice.value !== 0 ? 'rgb(83, 83, 244)' : 'black'}">
                        {{invoice.value | number:'1.2-2'}}
                    </td>
                    <td class="text-right" [ngStyle]="{'color': invoice.discount !== 0 ? '#FF6501' : 'black'}">
                        {{invoice.discount | number:'1.2-2'}}
                    </td>
                    <td class="text-right" [ngStyle]="{'color': invoice.interest !== 0 ? 'rgb(38, 199, 38)' : 'black'}">
                        {{invoice.interest | number:'1.2-2'}}
                    </td>
                    <td class="text-right point" [ngClass]="{'pending': invoice.pendingValue !== 0}">
                        {{invoice.pendingValue | number:'1.2-2'}}
                    </td>
                    <td class="text-right"
                        [ngClass]="{'pointer': invoice.totalPaid !== 0 || (invoice.totalPaid === 0 && invoice.invoicePaids.length > 0), 'value-paid': invoice.totalPaid !== 0}"
                        (click)="(invoice.totalPaid !== 0 || (invoice.totalPaid === 0 && invoice.invoicePaids.length > 0)) && openInvoicePaidsDialog(invoice)">
                        <span [pTooltip]="invoice.totalPaid > 0 ? getLastPayment(invoice.invoicePaids) : ''"
                            tooltipPosition="top">{{invoice.totalPaid | number:'1.2-2'}}</span>
                    </td>
                    <td class="text-right">
                        <button type="button" title="Cancelar fatura" class="icon-button"
                            *ngIf="invoice.status === 'PENDING' || (invoice.status === 'PAID' && invoice.value === 0)"
                            (click)="checkAndCancel(invoice)" style="color: red;">
                            <i class="pi pi-times"></i>
                        </button>
                        <button class="icon-button" disabled type="button"
                            title="Faturas pagas não podem ser canceladas"
                            *ngIf="invoice.status === 'PAID' && invoice.value !== 0" style="color: gray;">
                            <i class="pi pi-times"></i>
                        </button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-fieldset>

<p-dialog [(visible)]="changeDueDate" [modal]="true" [blockScroll]="true" header="Alterar data" 
    styleClass="primeDialog" *ngIf="changeDueDate">

    <div class="dialog-form">
        <h3 class="form-title">Selecione a nova data de vencimento</h3>
        <div class="form-control">
            <p-calendar #due [inline]="true" dateFormat="dd/mm/yy" name="dueDate" (onSelect)="selectDueDate(due)"
                [showIcon]="true" i18n [monthNavigator]="true" [yearNavigator]="true" appendTo="body"
                [yearRange]="'2000:2055'" [minDate]="today" [readonlyInput]="true">
            </p-calendar>
        </div>
    </div>
</p-dialog>

<p-dialog [(visible)]="changeCompetenceDate" [modal]="true" [blockScroll]="true" header="Alterar data"
    styleClass="primeDialog" *ngIf="changeCompetenceDate">

    <div class="dialog-form">
        <h3 class="form-title">Selecione a nova data de competência</h3>
        <div class="form-control">
            <p-calendar #competence [inline]="true" dateFormat="dd/mm/yy" name="competenceDate" (onSelect)="selectCompetenceDate(competence)" 
                [showIcon]="true" i18n [monthNavigator]="true" [yearNavigator]="true" appendTo="body" 
                [yearRange]="'2000:2055'" [readonlyInput]="true">
            </p-calendar>
        </div>
    </div>
</p-dialog>
