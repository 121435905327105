import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BoatReport, BoatTuitionReport } from 'src/app/models/boat-report.model';
import { BoatService } from 'src/app/services/boat.service';
import { CustomerService } from 'src/app/services/customer.service';
import { SelectLabelValue } from 'src/app/models/select-label-value';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageUtil } from 'src/app/utils/message.util';
import { CustomReportExportComponent } from 'src/app/components/extract-custom-report/custom-report-export.component';

@Component({
  selector: 'app-boat-report',
  templateUrl: './boat-report.component.html',
  styleUrls: ['./boat-report.component.scss']
})
export class BoatReportComponent implements OnInit {
  boatReport: BoatReport = null;
  startYear: Date = new Date(new Date().getFullYear() - 20, 0, 1);
  endYear: Date = new Date();
  year = new Date();

  customers: SelectLabelValue[] = [];
  boats: SelectLabelValue[] = [];

  selectedCustomers: number[] = [];
  selectedBoats: number[] = [];
  /* defina o valor de ID da marina aqui */;

  activeTuitionSum: number[] = Array(12).fill(0);
  inactiveTuitionSum: number[] = Array(12).fill(0);

  constructor(
    public dialog: DialogService,
    private messageUtil: MessageUtil,
    private boatService: BoatService,
    private customerService: CustomerService,
    private slingConfigService: SlingConfigService,
    private spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.initializeData().then(() => this.getReport());
  }

  private async initializeData(): Promise<void> {
    await Promise.all([
      this.getStartOperationDate(),
      this.getCustomers(),
      this.getBoats()
    ]);
  }

  async getStartOperationDate(): Promise<void> {
    try {
      const slingConfig = await this.slingConfigService.getSlingConfigToday().toPromise();
      this.startYear = new Date(slingConfig.operationStart);
      this.cdr.detectChanges();
    } catch (error) {
      console.error("Erro ao obter a data de início da operação:", error);
    }
  }

  getCustomers(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.customerService.getCustomerFilterList().subscribe((data) => {
        this.customers = [{ label: 'Todos', value: null }].concat(
          data.map((c) => ({ label: c.name, value: c.id }))
        );
        resolve();
      });
    });
  }

  getBoats(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.boatService.getAllByActiveForSelect().subscribe((data) => {
        this.boats = [{ label: 'Todos', value: null }].concat(
          data.map((c) => ({ label: c.label, value: c.value }))
        );
        resolve();
      });
    });
  }

  getReport(): void {
    this.spinner.show();
    const body = {
      customers: this.selectedCustomers,
      boats: this.selectedBoats,
    };

    this.boatService.getBoatReport(
      this.startYear.getFullYear(),
      this.endYear.getFullYear(),
      body.customers,
      body.boats
    ).subscribe((data: BoatReport) => {
      this.boatReport = data;
      this.calculateTuitionSums();
      this.spinner.hide();
    });
  }

  private calculateTuitionSums(): void {
    this.resetTuitionSums();

    this.boatReport.activeBoatTuitionsReports.forEach((t) => {
      t.tuitions = this.checkBoatTuitionReferences(t);
      t.tuitions.forEach((tuition, index) => {
        this.activeTuitionSum[index] += parseFloat(tuition.value.toFixed(2));
      });
    });

    this.boatReport.inactiveBoatTuitionsReports.forEach((t) => {
      t.tuitions = this.checkBoatTuitionReferences(t);
      t.tuitions.forEach((tuition, index) => {
        this.inactiveTuitionSum[index] += parseFloat(tuition.value.toFixed(2));
      });
    });
  }

  private resetTuitionSums(): void {
    this.activeTuitionSum.fill(0);
    this.inactiveTuitionSum.fill(0);
  }

  private checkBoatTuitionReferences(tuitions: BoatTuitionReport) {
    tuitions.logDate = tuitions.logDate || null;
    if (tuitions?.tuitions.length < 12) {
      for (let i = 0; i < 12; i++) {
        const reference = `${this.year.getFullYear()}-${(i + 1).toString().padStart(2, '0')}-01`;
        if (!tuitions.tuitions.find((t) => t.referenceDate === reference)) {
          tuitions.tuitions.push({
            boatId: tuitions.boatId,
            referenceDate: reference,
            value: 0,
          });
        }
      }
    }
    return tuitions.tuitions.sort(
      (a, b) => new Date(a.referenceDate).getTime() - new Date(b.referenceDate).getTime()
    );
  }

  async exportTable(): Promise<void> {

    let exportList =[{titulo:'Ativos'}]
    const exportSubTotal = {
      titulo:"",
      date:"",
      jan:this.activeTuitionSum[0] ,
      fev:this.activeTuitionSum[1] ,
      mar:this.activeTuitionSum[2] ,
      abr:this.activeTuitionSum[3] ,
      mai:this.activeTuitionSum[4] ,
      jun:this.activeTuitionSum[5] ,
      jul:this.activeTuitionSum[6] ,
      ago:this.activeTuitionSum[7] ,
      set:this.activeTuitionSum[8] ,
      out:this.activeTuitionSum[9] ,
      nov:this.activeTuitionSum[10] ,
      dez:this.activeTuitionSum[11]

    }
    exportList.push(exportSubTotal)
    this.boatReport?.activeBoatTuitionsReports.forEach((t) => {
      const item = {
        titulo: t.boatName,
        date: t.logDate,
        jan: t.tuitions[0].value==null?0:t.tuitions[0].value,
        fev: t.tuitions[1].value==null?0:t.tuitions[1].value,
        mar: t.tuitions[2].value==null?0:t.tuitions[2].value,
        abr: t.tuitions[3].value==null?0:t.tuitions[3].value,
        mai: t.tuitions[4].value==null?0:t.tuitions[4].value,
        jun: t.tuitions[5].value==null?0:t.tuitions[5].value,
        jul: t.tuitions[6].value==null?0:t.tuitions[6].value,
        ago: t.tuitions[7].value==null?0:t.tuitions[7].value,
        set: t.tuitions[8].value==null?0:t.tuitions[8].value,
        out: t.tuitions[9].value==null?0:t.tuitions[9].value,
        nov: t.tuitions[10].value==null?0:t.tuitions[10].value,
        dez: t.tuitions[11].value==null?0:t.tuitions[11].value
      };
      exportList.push(item);
    }
    );
    exportList.push({titulo:''});
    exportList.push({titulo:'Inativos'});
    const exportSubTotal2 = {
      titulo:"",
      date:"",
      jan:this.inactiveTuitionSum[0]==null?0:this.inactiveTuitionSum[0] ,
      fev:this.inactiveTuitionSum[1]==null?0:this.inactiveTuitionSum[1] ,
      mar:this.inactiveTuitionSum[2]==null?0:this.inactiveTuitionSum[2] ,
      abr:this.inactiveTuitionSum[3]==null?0:this.inactiveTuitionSum[3] ,
      mai:this.inactiveTuitionSum[4]==null?0:this.inactiveTuitionSum[4] ,
      jun:this.inactiveTuitionSum[5]==null?0:this.inactiveTuitionSum[5] ,
      jul:this.inactiveTuitionSum[6]==null?0:this.inactiveTuitionSum[6] ,
      ago:this.inactiveTuitionSum[7]==null?0:this.inactiveTuitionSum[7] ,
      set:this.inactiveTuitionSum[8]==null?0:this.inactiveTuitionSum[8] ,
      out:this.inactiveTuitionSum[9]==null?0:this.inactiveTuitionSum[9] ,
      nov:this.inactiveTuitionSum[10]==null?0:this.inactiveTuitionSum[10] ,
      dez:this.inactiveTuitionSum[11]==null?0:this.inactiveTuitionSum[11]
    }
    exportList.push(exportSubTotal2)
    this.boatReport?.inactiveBoatTuitionsReports.forEach((t) => {
      const item = {
        titulo: t.boatName,
        date: t.logDate,
        jan: t.tuitions[0].value,
        fev: t.tuitions[1].value,
        mar: t.tuitions[2].value,
        abr: t.tuitions[3].value,
        mai: t.tuitions[4].value,
        jun: t.tuitions[5].value,
        jul: t.tuitions[6].value,
        ago: t.tuitions[7].value,
        set: t.tuitions[8].value,
        out: t.tuitions[9].value,
        nov: t.tuitions[10].value,
        dez: t.tuitions[11].value
      };
      exportList.push(item);
    }
    );

    exportList.push({titulo:''});
    exportList.push({titulo:'Resumo'});
    const exportSubTotal3 = {
      titulo:"Qtd de Embarcações Faturadas",
      date:"",
      jan:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[0].tuitionBoats,
      fev:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[1].tuitionBoats,
      mar:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[2].tuitionBoats,
      abr:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[3].tuitionBoats,
      mai:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[4].tuitionBoats,
      jun:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[5].tuitionBoats,
      jul:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[6].tuitionBoats,
      ago:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[7].tuitionBoats,
      set:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[8].tuitionBoats,
      out:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[9].tuitionBoats,
      nov:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[10].tuitionBoats,
      dez:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[11].tuitionBoats
    }
    exportList.push(exportSubTotal3)
    const exportSubTotal4 = {
      titulo:"Qtd de Entradas",
      date:"",
      jan:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[0].qtdIn,
      fev:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[1].qtdIn,
      mar:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[2].qtdIn,
      abr:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[3].qtdIn,
      mai:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[4].qtdIn,
      jun:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[5].qtdIn,
      jul:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[6].qtdIn,
      ago:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[7].qtdIn,
      set:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[8].qtdIn,
      out:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[9].qtdIn,
      nov:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[10].qtdIn,
      dez:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[11].qtdIn
    }
    exportList.push(exportSubTotal4)

    const exportSubTotal5 = {
      titulo:"Qtd de Saídas",
      date:"",
      jan:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[0].qtdOut,
      fev:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[1].qtdOut,
      mar:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[2].qtdOut,
      abr:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[3].qtdOut,
      mai:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[4].qtdOut,
      jun:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[5].qtdOut,
      jul:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[6].qtdOut,
      ago:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[7].qtdOut,
      set:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[8].qtdOut,
      out:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[9].qtdOut,
      nov:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[10].qtdOut,
      dez:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[11].qtdOut
    }
    exportList.push(exportSubTotal5)

    const exportSubTotal6 = {
      titulo:"Variações",
      date:"",
      jan:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[0].qtdIn - this.boatReport.boatInactivationReports[0].qtdOut,
      fev:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[1].qtdIn - this.boatReport.boatInactivationReports[1].qtdOut,
      mar:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[2].qtdIn - this.boatReport.boatInactivationReports[2].qtdOut,
      abr:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[3].qtdIn - this.boatReport.boatInactivationReports[3].qtdOut,
      mai:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[4].qtdIn - this.boatReport.boatInactivationReports[4].qtdOut,
      jun:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[5].qtdIn - this.boatReport.boatInactivationReports[5].qtdOut,
      jul:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[6].qtdIn - this.boatReport.boatInactivationReports[6].qtdOut,
      ago:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[7].qtdIn - this.boatReport.boatInactivationReports[7].qtdOut,
      set:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[8].qtdIn - this.boatReport.boatInactivationReports[8].qtdOut,
      out:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[9].qtdIn - this.boatReport.boatInactivationReports[9].qtdOut,
      nov:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[10].qtdIn - this.boatReport.boatInactivationReports[10].qtdOut,
      dez:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[11].qtdIn - this.boatReport.boatInactivationReports[11].qtdOut

    }
    exportList.push(exportSubTotal6)

    const exportSubTotal7 = {
      titulo:"Qtd de Emb sem serviços ativos",
      date:"",
      jan:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[0].notTuitionBoats,
      fev:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[1].notTuitionBoats,
      mar:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[2].notTuitionBoats,
      abr:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[3].notTuitionBoats,
      mai:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[4].notTuitionBoats,
      jun:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[5].notTuitionBoats,
      jul:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[6].notTuitionBoats,
      ago:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[7].notTuitionBoats,
      set:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[8].notTuitionBoats,
      out:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[9].notTuitionBoats,
      nov:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[10].notTuitionBoats,
      dez:this.boatReport?.boatInactivationReports == null? 0 : this.boatReport.boatInactivationReports[11].notTuitionBoats
    }
    exportList.push(exportSubTotal7)

    this.spinner.hide();



    const dataExport = {table: exportList, type: 'REPORT_BOAT'};
    this.dialog.open(CustomReportExportComponent, {
      width: '70%',
      height: '90vh',
      dismissableMask: false,
      data: dataExport,
      header: 'Exportar Relatório de Embarcações',
    });
}
}
