import {Component, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {OrderItem, Product, Sling} from 'src/app/models';
import {ProductCategoryType} from 'src/app/models/enums';
import {FuelTankFraction} from 'src/app/models/fuel-tank-fraction';
import {SlingService} from 'src/app/services/sling.service';
import {ImageUtil} from 'src/app/utils/image.util';
import {MessageUtil} from 'src/app/utils/message.util';
import {OrderUtil} from 'src/app/utils/order.util';
import {ProductService} from '../../../services/product.service';

@Component({
  selector: 'app-sling-fuel-check',
  templateUrl: './sling-fuel-check.component.html',
  styleUrls: ['./sling-fuel-check.component.scss']
})
export class SlingFuelCheckComponent implements OnInit {

  sling: Sling;

  fuelOrder: OrderItem;

  orderUtil: OrderUtil;

  referenceTime: any;

  fuelQuantity: number;
  fuelQuantityString = '00';

  hourInMilliseconds: number = 1000 * 3600 * 1;

  colorTwoTimeEngine = 'color-red';
  fullTankCheck: boolean;
  fuels: Product[];
  filteredFuels: any[];
  addFuel: Product = null;

  constructor(
    public dialog: DynamicDialogRef,
    public imageUtil: ImageUtil,
    private messageUtil: MessageUtil,
    private slingService: SlingService,
    private config: DynamicDialogConfig,
    private productService: ProductService,
  ) {
    this.orderUtil = new OrderUtil();
  }

  async ngOnInit(): Promise<void> {
    this.sling = await this.findSlingById(this.config.data.slingId);
    this.referenceTime = this.config.data.referenceTime;
    this.fullTankCheck = this.config.data.fullTankCheck;
    if (this.fullTankCheck) {
      this.loadFuels();
      this.createFuelOrder();
    } else {
      this.fuelOrder = this.config.data.fuelOrder;
    }
  }

  getLabelFraction(fuelTankFraction: FuelTankFraction): string {
    return this.orderUtil.getFuelFractionOptions().find((f) => f.value === fuelTankFraction).label;
  }

  confirmFuelCheck(): void {
    console.log(this)
    if ((this.addFuel == undefined || this.addFuel == null) && this.fullTankCheck) {
      this.messageUtil.generateMessage('error', 'SUMMARY.ERROR', 'Informar o combustível');
      return;
    } 
    if (this.fuelQuantity === undefined || this.fuelQuantity === null) {
      this.messageUtil.generateMessage('error', 'SUMMARY.ERROR', 'Informar a quantidade');
    } else {
      if (this.fullTankCheck) {
        this.createOrderItem();
      } else {
        if (this.fuelOrder.fuelTankFraction && Number(this.fuelQuantity) > 0) {
          this.dialog.close(this.fuelQuantity);
          return;
        }
        if (Number(this.fuelQuantity) === Number(this.fuelOrder.qtyRequested)) {
          this.dialog.close(this.fuelQuantity);
        } else if (Number(this.fuelQuantity) !== Number(this.fuelOrder.qtyRequested)) {
          this.openDialogDifferentQuantities(this.fuelOrder.qtyRequested, this.fuelQuantity);
        }
      }
    }
  }

  confirmationDialog(): void {
    this.dialog.close(this.fuelQuantity);
  }

  openDialogDifferentQuantities(qtyRequested, qtySupplied): void {
    if (qtySupplied === undefined) {
      qtySupplied = 0;
    }
    if (qtySupplied < qtyRequested) {
      this.messageUtil.confirmMessage(this, 'CONFIRMATION', 'SLING-LESS-FUEL-PROMPT-OK', '');
    } else {
      this.messageUtil.confirmMessage(this, 'CONFIRMATION', 'SLING-MORE-FUEL-PROMPT-OK', '');
    }
  }

  isNumber(value: any): boolean {
    return typeof value === 'number';
  }

  insertNumberValue(value: string): void {
    this.fuelQuantityString = this.fuelQuantityString.concat(value);
    this.fuelQuantity = Number([this.fuelQuantityString.slice(0, -2), '.', this.fuelQuantityString.slice(-2)].join(''));
  }

  deleteNumberValue(): void {
    if (this.fuelQuantityString !== '00') {
      this.fuelQuantityString = this.fuelQuantityString.substring(0, this.fuelQuantityString.length - 1);
      this.fuelQuantity = Number([this.fuelQuantityString.slice(0, -2), '.', this.fuelQuantityString.slice(-2)].join(''));
    } else {
      this.fuelQuantity = 0;
    }
  }

  async findSlingById(id: number): Promise<Sling> {
    return new Promise<Sling>(
      async (resolve) => {
        this.slingService.getById(id).subscribe(
          async (sling: Sling) => {
            resolve(sling);
          },
          async err => {
            resolve(null);
          }
        );
      }
    );
  }

  getSuffix(): string {
    let text = ' ';
    if (this.fuelOrder.product?.measurement !== null && this.fuelOrder.product?.measurement !== undefined) {
      text = ' ' + this.messageUtil.translateKey(this.fuelOrder.product?.measurement?.toUpperCase());
    }
    return text;
  }

  loadFuels(): void {
    this.productService.getByProductCategoryType(ProductCategoryType.Fuel).subscribe(
      (data) => {
        this.fuels = data.filter((p) => p.active && p.fuel.id === this.sling.boat.fuel.id);
        this.filteredFuels = this.fuels.map((f) => ({label: f.name, value: f}));
        if (this.filteredFuels.length === 1) {
          this.addFuel = this.filteredFuels[0].value;
        }
      },
      () => {
        this.fuels = null;
      },
    );
  }

  createFuelOrder(): void {
    this.fuelOrder = new OrderItem();
    this.fuelOrder.fuelMeasurementType = 'FuelFraction';
    this.fuelOrder.fuelTankFraction = FuelTankFraction.Full;
  }

  createOrderItem(): void {
    this.fuelOrder.product = this.addFuel;
    this.fuelOrder.qtySupplied = this.fuelQuantity;
    this.fuelOrder.qtyRequested = 0;
    this.dialog.close(this.fuelOrder);
    return;
  }
}
