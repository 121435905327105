import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccessPoint } from 'src/app/models/dtos/access-controller/accessPoint';
import { DialogService } from 'primeng/dynamicdialog';
import { AccessPointService } from 'src/app/services/access-controler/access-point.service';
import Swal from 'sweetalert2';
import { AccessPointFormComponent } from './access-point-form/access-point-form.component';

@Component({
  selector: 'app-access-point',
  templateUrl: './access-point.component.html',
  styleUrls: ['./access-point.component.scss']
})
export class AccessPointComponent implements OnInit {
  numberOfRows = 30;
  accessPointList: AccessPoint[] = [];

  constructor(
    private accessPointService: AccessPointService,
    private router: Router,
    private dialog: DialogService,

  ) { }

  ngOnInit() {
    this.loadAccessPointList();
  }

  getDirectionName(direction: string): string {
    const directionMap: { [key: string]: string } = {
      'IN': 'Entrada',
      'OUT': 'Saída',
      'INT': 'Interno'
    };
  
    return directionMap[direction] || '';
  }

  openAccessPointForm(ap?: AccessPoint): void {
    this.dialog
    .open(AccessPointFormComponent, {
      width: '1024px',
      height: '768px',
      header: 'Ponto de Acesso',
      data: {
        accessPoint: ap
      },
    })
    .onClose.subscribe(() => {
      this.loadAccessPointList();
    });

  }

  openAccessPointDeleteConfirmDialog(id: number): void {
    Swal.fire({
      title: 'Deseja realmente excluir?',
      text: 'Esta ação não poderá ser desfeita!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, excluir!',
      cancelButtonText: 'Não, cancelar!',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.accessPointService.delete(id).subscribe({
          next: () => {
            this.loadAccessPointList();
            Swal.fire(
              'Excluído!',
              'O registro foi excluído com sucesso.',
              'success'
            );
          },
          error: (error) => {
            console.log(error);
            Swal.fire(
              'Erro!',
              'Ocorreu um erro ao excluir o registro.',
              'error'
            );
          }
        });
      }
    });

  }

  loadAccessPointList(): void {
    this.accessPointService.getAllByMarina().subscribe({next: (data) => {
      this.accessPointList = data;
    },error: (error) => {
      console.log(error);
    }});

  }
}
