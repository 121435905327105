import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { CustomReportExportComponent } from 'src/app/components/extract-custom-report/custom-report-export.component';
import { SlingFormComponent } from 'src/app/components/sling/sling-form/sling-form.component';
import { SlingLogDialogComponent } from 'src/app/components/sling/sling-log-dialog/sling-log-dialog.component';
import { NavigationPlan, Sling, SlingConfig } from 'src/app/models';
import { BoatFilterListWithCustomerDTO } from 'src/app/models/dtos/boat-filter-list-with-customerDTO';
import { BoatStatus } from 'src/app/models/enums';
import { BoatService } from 'src/app/services/boat.service';
import { CustomerService } from 'src/app/services/customer.service';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { SlingService } from 'src/app/services/sling.service';
import { WebSocketService } from 'src/app/services/web-socket.service';
import { FormatUtil } from 'src/app/utils/format.util';
import { MessageUtil } from 'src/app/utils/message.util';
import { SlingUtil } from 'src/app/utils/sling.util';
import { TimezoneUtil } from 'src/app/utils/timezone.util';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-sling',
  templateUrl: './sling.component.html',
  styleUrls: ['./sling.component.scss']
})
export class SlingComponent implements OnInit {


  filter: any = {
    slingOrigin: null,
    boatStatus: null,
    slingStatus: ['WATER', 'PENDING_DOWN', 'PENDING_UP'],
    finishedOnWater: null
  };
  slings: Sling[] = [];
  slingsTable: Sling[] = [];
  filteredCustomers: any;
  selectedCustomerId: number;
  selectedBoatId: number;
  boats: BoatFilterListWithCustomerDTO[] = [];
  boatsFilter: any[] = new Array();
  numberOfRows = 10;
  slingConfig: SlingConfig;
  globalFilter = '';
  isASlingEdition = false;
  pageLinks = 3;
  index = 0;
  rowsPerPageOptions = [10, 20, 30, 50, 100];

  slingSubscription: Subscription;

  currentDateTime: number;

  pt = {
    firstDayOfWeek: 0,
    dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
      'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar'
  };

  constructor(
    private timezoneUtil:TimezoneUtil,
    private messageUtil: MessageUtil,
    private slingService: SlingService,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private customerService: CustomerService,
    private boatService: BoatService,
    private slingConfigService: SlingConfigService,
    private dialog: DialogService,
    public slingUtil: SlingUtil,
    private webSocketService: WebSocketService
  ) {
  }

  timezone(data: any):any{
    return this.timezoneUtil.formatTimezone(data);
  }

  async ngOnInit(): Promise<void> {
    this.slingConfig = await this.loadSlingConfig();
    let rows = (this.slingConfig != null) ? this.slingConfig.numberOfRecordsPerPage : 10;
    if (window.innerWidth < 770) {
      rows = 1;
      this.pageLinks = 1;
      this.rowsPerPageOptions = [];
    }
    this.numberOfRows = rows;
    this.loadCustomers();
    this.loadBoat();
    this.find();
    this.currentDateTime = new Date().getTime();
    this.slingSubscription = this.webSocketService.getMessageSling().subscribe(async (message) => {
      await this.find();
    });
  }

  ngOnDestroy(): void {
    this.webSocketService.unsubscribeSling(this.slingSubscription);
  }

  async loadSlingConfig(): Promise<SlingConfig> {
    return new Promise<SlingConfig>(
      async (resolve) => {
        this.slingConfigService.getSlingConfig().subscribe(
          async (data) => {
            resolve(data[0]);
          }
        );
      }
    );
  }

  exportTable() {
    const exportData = this.slingsTable.map((sling) => {
      return {
        id: sling.id.toString(),
        slingOrigin: sling.slingOrigin,
        requestTime: sling.requestTime ? this.timezoneUtil.formatTimezone(sling.requestTime) : null,
        executionForecast: sling.executionForecast ? this.timezoneUtil.formatTimezone(sling.executionForecast) : null,
        boatName: `${sling.boat.name} - ${sling.boat.shipyardModel.shipType.toUpperCase()} - ${sling.boat.commercialLength}`,
        slingStatus: sling.slingStatus,
        boatStatus: sling.boatStatus,
        hasSailor: this.hasBoatSailor(sling),
      }
    });

    const data = { table: exportData, type: "SLING" };
    this.dialog.open(CustomReportExportComponent, {
      width: '70%',
      height: '90vh',
      dismissableMask: false,
      data,
      header: "Exportar Lingadas",
    });
  }

  filterGlobal(): void {
    this.slingsTable = this.slings.filter(
      (s) =>
      (FormatUtil.getNotAccents(s.boat.name).toUpperCase()
        .includes(FormatUtil.getNotAccents(this.globalFilter).toUpperCase()) ||
        s.boat.name.toUpperCase().includes(this.globalFilter.toUpperCase())
      )
    );
  }

  openSlingLogs(slingId: number): void {
    this.dialog.open(SlingLogDialogComponent, {
      width: '85%',
      header: this.messageUtil.translateKey('LOGS'),
      data: { slingId }
    });
  }

  async openSlingFormDialog(id: number): Promise<void> {
    const data: any = {
      slingConfig: this.slingConfig
    };
    let sling: Sling = null;
    if (id) {
      sling = await this.getSlingById(id);
      data.isASlingEdition = true;
      data.sling = sling;
    }
    this.dialog.open(SlingFormComponent, {
      width: '70%',
      height: '80vh',
      data,
      header: 'Lingada'
    });
  }



  openSlingCancelConfirmDialog(sling: Sling): void {

    let titleMessage = null;
    let message = null;
    let types = null;

    types = 'warning';
    this.translateService.get('CANCEL-CONFIRMATION').subscribe((result: string) => { titleMessage = result; });
    this.translateService.get('SLING-CANCEL-PROMPT').subscribe((result: string) => { message = result; });

    Swal.fire({
      title: titleMessage,
      text: message + ' ' + sling.boat.name + '?',
      icon: types,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.slingService.cancelSling(sling.id).subscribe(
          () => {
            this.messageUtil.generateMessage('success', 'SUMMARY.SUCCESS', 'MSG.CANCEL-SUCCESS');
          },
          (error) => {
            const exception = error.error.data.exception;
            this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
          });
      }
    });
  }

  validateNavigationPlan(navigationPlan: NavigationPlan): boolean {
    if (navigationPlan.id && navigationPlan.crewQty &&
      navigationPlan.destination && navigationPlan.onBoardPhone) {
      return true;
    }
    return false;
  }

  selectCustomer(): void {
    this.filterBoat();
  }

  selectBoat(): void {
    this.filter.boat = null;
    if (this.selectedBoatId != null) {
      this.filter.boat = this.boats.find((b) => b.id === this.selectedBoatId);
    }
  }

  filterBoat(): void {
    this.filter.boat = null;
    this.selectedBoatId = null;
    let filteredBoat;
    if (this.selectedCustomerId) {
      filteredBoat = this.boats.filter(
        (boat) => boat.customerIds.some((customerId) => customerId === this.selectedCustomerId)
      ).sort(
        (b1, b2) => {
          if (b1.name < b2.name) {
            return -1;
          }
          if (b1.name > b2.name) {
            return 1;
          }
          return 0;
        }
      );
    } else {
      filteredBoat = this.boats.sort(
        (b1, b2) => {
          if (b1.name < b2.name) {
            return -1;
          }
          if (b1.name > b2.name) {
            return 1;
          }
          return 0;
        }
      );
    }
    this.boatsFilter = filteredBoat.map((b) => ({ label: b.name, value: b.id }));
    this.boatsFilter.unshift({ label: 'Todos', value: null });
  }

  loadCustomers(): void {
    this.customerService.getCustomerFilterList().subscribe(
      (data) => {
        this.filteredCustomers = data.map((c) => ({ label: c.name, value: c.id }));
        this.filteredCustomers.unshift({ label: 'Todos', value: null });
      }
    );
  }

  loadBoat(): void {
    this.boatService.getBoatFilterListWithCustomer().subscribe(
      (data) => {
        this.boats = data.filter(boat => boat.active);
        this.boatsFilter = this.boats.map((b) => ({ label: b.name, value: b.id }));
        this.boatsFilter.unshift({ label: 'Todos', value: null });
      }
    );
  }

  clear(): void {
    this.clearCustomer();
    this.filter = { slingOrigin: null, boatStatus: null, slingStatus: null };
    this.slings = [];
    this.slingsTable = [];
  }

  clearCustomer(): void {
    this.selectedCustomerId = null;
    this.filterBoat();
  }

  find(): void {
    this.spinner.show();
    this.filter.idBoat = this.filter.boat ? this.filter.boat.id : null;
    this.filter.idCustomer = this.selectedCustomerId;
    const filter = JSON.parse(JSON.stringify(this.filter));
    if (filter.slingStatus && filter.slingStatus.includes('FINISHED_ON_WATER')) {
      filter.slingStatus = ['WATER'];
      filter.finishedOnWater = true;
    }
    this.slingService.findSlingByFilter(filter).subscribe(
      (p) => {
        this.slings = p.sort((b1, b2) => b2.id - b1.id);
        this.slingsTable = [...this.slings];
        this.spinner.hide();
        this.filterGlobal();
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      });
  }

  openUndoFinishOnWater(sling: Sling): void {

    let titleMessage = null;
    let message = null;
    let types = null;

    types = 'warning';
    this.translateService.get('UNDO-FINISH-ON-WATER-TITLE').subscribe((result: string) => { titleMessage = result; });
    this.translateService.get('UNDO-FINISH-ON-WATER').subscribe((result: string) => { message = result; });

    Swal.fire({
      title: titleMessage,
      text: message,
      icon: types,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        sling.finishedOnWater = false;
        this.slingService.update(sling).subscribe(
          () => {
            this.messageUtil.generateMessage('success', 'SUMMARY.SUCCESS', 'MSG.CANCEL-SUCCESS');
          },
          (error) => {
            sling.finishedOnWater = true;
            const exception = error.error.data.exception;
            this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
          });
      }
    });
  }

  onChangeMultiSelect(event): void {
    if (event.itemValue === 'FINISHED_ON_WATER') {
      this.filter.slingStatus = ['FINISHED_ON_WATER'];
    } else {
      const idx = this.filter.slingStatus.indexOf('FINISHED_ON_WATER');
      if (idx >= 0) {
        this.filter.slingStatus.splice(idx, 1);
      }
    }
  }

  hasCancel(sling: Sling): boolean {
    return sling && sling.slingStatus !== 'OK' && sling.slingStatus !== 'CANCELED' &&
      (
        sling.boatStatus === BoatStatus.WAITING_PREPARATION &&
        sling.marinaOrder.orderItems.filter((item) => item.qtySupplied === null || item.qtySupplied === undefined).length !== 0
      );
  }

  hasEdit(sling: Sling): boolean {
    return sling && sling.slingStatus === 'PENDING_DOWN'  &&
      (
        this.slingConfig.editSlingInPreparation ===true ||
        sling.boatStatus === BoatStatus.WAITING_PREPARATION &&
        sling.marinaOrder.orderItems.filter((item) => item.qtySupplied === null || item.qtySupplied === undefined).length === 0
      );
  }

  async getSlingById(id): Promise<Sling> {
    return new Promise<Sling>(
      async (resolve) => {
        this.slingService.getById(id).subscribe(async (sling: Sling) => resolve(sling));
      }
    );
  }

  changeRows($event): void {
    this.numberOfRows = $event.rows;
    this.index = $event.first / $event.rows;
  }

  getRegistries(): number {
    const qty = this.slingsTable.length - (this.numberOfRows * this.index);
    return qty < this.numberOfRows ? qty : this.numberOfRows;
  }

  hasBoatSailor(sling: Sling): string {
    return sling.boatSailor ? 'Sim' : 'Não'
  }
}

