<p-fieldset>
  <p-header class="article-title">
    <span class="article-title">{{
      "Estoque > Ajuste de serviço" | translate
    }}</span>

    <button
      class="fab-button primary"
      (click)="exportTable()"
      label="Exportar valores"
      pTooltip="Exportar Valores"
      tooltipPosition="left"
    >
      <i class="pi pi-file"></i>
    </button>
  </p-header>
  <div *ngIf="slingConfig">
    <p-tabView
      [activeIndex]="selectTab"
      (onChange)="onChangeSelectTab($event)"
      *ngIf="slingConfig.periodicalServicePriceFree"
    >
      <p-tabPanel header="Preço livre">
        <app-list-price-free
          *ngIf="selectTab === 0"
          [slingConfig]="slingConfig"
          (exportList)="exportList($event)"
        ></app-list-price-free>
      </p-tabPanel>
      <p-tabPanel header="Serviços">
        <app-list-services
          *ngIf="selectTab === 1"
          (exportList)="exportList($event)"
        ></app-list-services>
      </p-tabPanel>
    </p-tabView>

    <app-list-services
      *ngIf="!slingConfig.periodicalServicePriceFree"
      (exportList)="exportList($event)"
    ></app-list-services>
  </div>
</p-fieldset>
