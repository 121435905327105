<p-fieldset>
  <p-header class="article-title">
    <span class="article-title">RELATÓRIOS > ACESSOS</span>
    <div class="header-buttons">
      <button
        class="fab-button primary"
        (click)="exportTable()"
        label="Exportar valores"
        pTooltip="Exportar Valores"
        tooltipPosition="left"
      >
        <i class="pi pi-file"></i>
      </button>

      <button
        class="fab-button primary"
        appFiltersButton
        pTooltip="Exibir/Ocultar Filtros"
        tooltipPosition="left"
      >
        <i class="pi pi-filter"></i>
      </button>
    </div>
  </p-header>
  <form class="filters" (submit)="find()" appFilters>
    <div class="filter-row mb-0">
      <div class="form-control">
        <label for="shipType">{{ "CUSTOMER" | translate }}</label>
        <p-dropdown
          [options]="this.filteredCustomers"
          [filter]="true"
          [(ngModel)]="filter.colectId"
          name="colectId"
          appendTo="body"
          [disabled]="onlylocal"
        >
        </p-dropdown>
      </div>

      <div class="form-control">
        <label for="starDate">{{ "INITIAL-DATE" | translate }}</label>
        <p-calendar
          [(ngModel)]="filter.startDate"
          name="startDate"
          dateFormat="dd/mm/yy"
          [showIcon]="true"
          autocomplete="off"
          i18n
          [monthNavigator]="true"
          [yearNavigator]="true"
          appendTo="body"
          [yearRange]="'2000:2055'"
          rPCalendarMask
          [disabled]="onlylocal"
          [readonlyInput]="onlylocal"
        >
        </p-calendar>
      </div>
      <div class="form-control">
        <label for="endDate">{{ "FINAL-DATE" | translate }}</label>
        <p-calendar
          [(ngModel)]="filter.endDate"
          name="endDate"
          dateFormat="dd/mm/yy"
          [showIcon]="true"
          autocomplete="off"
          i18n
          [monthNavigator]="true"
          [yearNavigator]="true"
          appendTo="body"
          [yearRange]="'2000:2055'"
          rPCalendarMask
          [disabled]="onlylocal"
          [readonlyInput]="onlylocal"
        >
        </p-calendar>
      </div>

      <div class="form-control">
        <label for="accessGroupId">Grupo de Acesso</label>
        <p-dropdown
          [options]="this.group"
          [filter]="true"
          [(ngModel)]="filter.accessGroupId"
          name="accessGroupIdId"
          appendTo="body"
          [disabled]="onlylocal"
        >
        </p-dropdown>
      </div>

      <div class="form-control">
        <label for="direction">Direção</label>
        <p-dropdown
          [options]="this.direction"
          [filter]="true"
          [(ngModel)]="filter.direction"
          name="direction"
          appendTo="body"
          [disabled]="onlylocal"
        >
        </p-dropdown>
      </div>
    </div>
    <div class="filter-row mt-0">
      <div class="form-control">
        <label for="rep">Atualmente no Recinto</label>
        <p-inputSwitch name="rep" [(ngModel)]="onlylocal"> </p-inputSwitch>
      </div>
      <div class="buttons">
        <div class="form-button">
          <button (click)="clear()" class="easymarine danger" type="button">
            {{ "CLEAR" | translate }}
          </button>
        </div>
        <div class="form-button">
          <button class="easymarine primary" type="submit">
            {{ "FIND" | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
  <div class="table">
    <p-table
      #tt
      [value]="report"
      [paginator]="true"
      [rows]="100"
      [lazy]="true"
      csvSeparator=";"
      [totalRecords]="report.length"
      styleClass="easy-table"
      [alwaysShowPaginator]="false"
      autoLayout="true"
    >
      <ng-template pTemplate="caption">
        <div
          class="text-right"
          style="margin: 1px; align-items: end; width: 100%"
        >
          <span class="total-registers"
            >{{ "TOTAL-REGISTERS" | translate }}: {{ report.length }}
            {{ "OF" | translate }} {{ tt.totalRecords }}
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th [pSortableColumn]="'userName'">
            {{ "CUSTOMER" | translate }}
            <p-sortIcon [field]="'userName'"></p-sortIcon>
          </th>
          <th [pSortableColumn]="'deviceName'">
            Catraca
            <p-sortIcon [field]="'deviceName'"></p-sortIcon>
          </th>
          <th class="text-center" [pSortableColumn]="'colectId'">
            Cod. no Dispositivo
            <p-sortIcon [field]="'colectId'"></p-sortIcon>
          </th>
          <th class="text-center" [pSortableColumn]="'direction'">
            Direção
            <p-sortIcon [field]="'direction'"></p-sortIcon>
          </th>
          <th [pSortableColumn]="'accessGroup'">
            Grupo de Acesso
            <p-sortIcon [field]="'accessGroup'"></p-sortIcon>
          </th>
          <th class="text-center" [pSortableColumn]="'securitiesPropertyNumberId'">
            Título
            <p-sortIcon [field]="'securitiesPropertyNumberId'"></p-sortIcon>

          <th class="text-center" [pSortableColumn]="'timeEvent'">
            {{ "DATE" | translate }}
            <p-sortIcon [field]="'timeEvent'"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-register let-index>
        <tr>
          <td>{{ register.userName }}</td>
          <td>{{ register.deviceName }}</td>
          <td class="text-center">{{ register.colectId }}</td>
          <td class="text-center">{{ register?.direction===null?'':register?.direction==='IN'?'Entrada':register?.direction==='OUT'?'Saída':'Interna' }}</td>
          <td>{{ register?.accessGroup }}</td>
          <td class="text-center">{{ register?.securitiesPropertyNumberId }}</td>
          <td class="text-center">
            {{ register.timeEvent | date : "dd/MM/yyyy HH:mm:ss" }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-fieldset>
